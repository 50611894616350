import axios from "axios";
import {
  NOTIFICATION_LIST_REQUEST,
  NOTIFICATION_LIST_SUCCESS,
  NOTIFICATION_LIST_FAIL,
  PROXY,
  OPEN_NOTIFICATION_FAIL,
  OPEN_NOTIFICATION_SUCCESS,
} from "../contants/allConstants";

export const getAllNotifications = (token, userId) => async (dispatch) => {
  try {
    dispatch({ type: NOTIFICATION_LIST_REQUEST });
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    const { data } = await axios.get(`${PROXY}/notifications`, config);
    dispatch({
      type: NOTIFICATION_LIST_SUCCESS,
      payload: { notifications: data, userId },
    });
  } catch (error) {
    dispatch({
      type: NOTIFICATION_LIST_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const openNotifications = (token) => async (dispatch) => {
  try {
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    const { data } = await axios.post(
      `${PROXY}/notifications/open`,
      {},
      config
    ); //empty body
    dispatch({
      type: OPEN_NOTIFICATION_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: OPEN_NOTIFICATION_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};
