import styled from "styled-components";
import React from "react";
import { COLORS } from "../contants/colors";
import MorePopover from "./MorePopover";
import { useState } from "react";
import { useLanguageContext } from "../languageContext";

const Video = ({
  photo,
  username,
  address,
  time,
  media,
  onDelete,
  onOpenParticipants,
  onOpenDisapprovers,
}) => {
  const { t } = useLanguageContext();

  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    setOpen(true);
  };
  return (
    <Wrapper>
      <MorePopover
        onDelete={onDelete}
        showEdit={false}
        handleClick={handleClick}
        open={open}
        setOpen={setOpen}
        anchorEl={anchorEl}
      />
      <Row>
        <Avatar
          src={photo !== "" ? photo : require("../images/avatar.svg").default}
        />
        <Title>{username}</Title>
      </Row>
      <VideoPreview controls key={media}>
        <source src={media} type="video/mp4" key={media} />
      </VideoPreview>
      <SubRow>
        <p style={{ margin: 0 }}>
          <Icon src={require("../images/pin.svg").default} alt="" />
          {address}
        </p>
        <p style={{ margin: 0 }}>
          <Icon src={require("../images/timer.svg").default} alt="" />
          {time}
        </p>
      </SubRow>
    </Wrapper>
  );
};
const Wrapper = styled.div`
  color: ${COLORS.black56};
  background-color: white;
  border-radius: 10px;
  font-family: "SF-display";
  text-align: left;
  font-size: 0.75rem;
  padding: 8px 18px 12px 18px;
  box-shadow: 0 30px 60px rgba(57, 57, 57, 0.1);
  position: relative;
  border: 1px solid ${COLORS.background};
  cursor: pointer;
`;

const Row = styled.div`
  display: flex;
  padding: 20px 0 8px 0;
  align-items: center;
`;
const SubRow = styled.div`
  display: flex;
  margin-top: 15px;
  align-items: center;
  justify-content: space-between;
  font-weight: 500;
  font-size: 0.75rem;
  color: black;
`;
const Icon = styled.img`
  height: 12px;
  object-fit: cover;
  margin-right: 6px;
  margin-bottom: 4px;
`;
const Avatar = styled.img`
  border-radius: 50%;
  height: 35px;
  min-width: 35px;
  object-fit: cover;
  margin-right: 10px;
`;
const Title = styled.p`
  font-weight: 700;
  font-size: 0.9375rem;
  color: ${COLORS.black27};
  margin: 0;
  padding-bottom: 3px;
`;

const VideoPreview = styled.video`
  height: 170px;
  width: 100%;
  object-fit: cover;
  border-radius: 9px;
`;

export default Video;
