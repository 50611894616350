export const COLORS = {
  primary: "#3073FF",
  blue2: "#3988cd",
  orange: "#f77847",
  darkBlue: "#2356c0",
  lightBlue: "#e0ebfe",
  red: "#E42346",
  gray20: "#DBDBDB",
  gray30: "#A1A1AA",
  gray50: "#B8B8BB",
  gray100: "#D9DDE0",
  gray200: "#EFEFEF",
  gray300: "#EDEDED",
  gray400: "#9BA1A1",
  gray500: "#B7B7B7",
  gray550: "#7f7f89",
  gray700: "#F0F0F0",
  gray800: "#F6F6F6",
  gray900: "#757575",
  gray990: "#f5f5f5",
  black: "hsl(0deg 0% 0%)",
  background: "#F5F5F5",
  success: "#3073FF",
  success02: "#56cc5b",
  shadow: "rgba(120, 127, 137,0.6)",
  error: "#f77847",
  green15: "#e0ebfe",
  green30: "#c6d4f5",
  green300: "#e0ebfe",
  // lightBlue: "#8bb7afde",
  red15: "#fde6dd",
  black56: "#565656",
  black27: "#272735",
};
