import {
  ALERT_LIST_REQUEST,
  ALERT_LIST_SUCCESS,
  ALERT_LIST_FAIL,
  DELETE_ALERT_SUCCESS,
  DELETE_ALERT_FAIL,
  OPEN_ALERT_FAIL,
  OPEN_ALERT_SUCCESS,
  USER_LOGOUT,
} from "../contants/allConstants";

export const alertListReducer = (
  state = { alerts: [], error: false, count: 0, loading: false },
  action
) => {
  switch (action.type) {
    case ALERT_LIST_REQUEST:
      return {
        ...state,
        loading: true,
        alerts: [],
      };
    case ALERT_LIST_SUCCESS: {
      const count = action.payload.alerts.filter(
        (alert) => alert.openedBy.indexOf(action.payload.userId) === -1
      ).length;
      // console.log("=========action.payload.alerts", action.payload.alerts);
      // console.log("=========count", count);

      return {
        ...state,
        loading: false,
        alerts: action.payload.alerts,
        success: true,
        count,
      };
    }
    case ALERT_LIST_FAIL:
      return {
        ...state,
        loading: false,
        success: true,
        alerts: state.alerts,
      };
    case DELETE_ALERT_FAIL:
    case OPEN_ALERT_FAIL: {
      return { ...state, loading: false, error: action.payload };
    }
    case DELETE_ALERT_SUCCESS:
      const new_alerts = state.alerts.filter(
        (a) => a["_id"] !== action.payload
      );
      return {
        ...state,
        loading: false,
        success: true,
        alerts: new_alerts,
      };
    case OPEN_ALERT_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        count: 0,
      };
    case USER_LOGOUT:
      return {
        loading: false,
        error: false,
        count: 0,
        alerts: [],
      };
    default:
      return state;
  }
};
