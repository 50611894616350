import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Header from "../components/Header";
import Layout from "../components/Layout";
import ResponsiveTable from "../components/ResponsiveTable";
import styled from "styled-components";
import Drawer from "../components/Drawer";
import { useState } from "react";
import UserDetails from "../components/UserDetails";
import Pagination from "react-responsive-pagination";
import {
  blockUser,
  deleteSupervisors,
  deleteUser,
  getMobileUsers,
  getSupervisors,
  searchSupervisors,
  searchUsers,
} from "../actions/userActions";
import Search from "../components/Search";
import DeleteButton from "../components/DeleteButton";
import { QUERIES } from "../contants/allConstants";
import { getAllNotifications } from "../actions/notificationActions";
import { getAllTeams } from "../actions/teamActions";
import { COLORS } from "../contants/colors";
import ToastContainer from "react-bootstrap/esm/ToastContainer";
import Collapse from "react-bootstrap/Collapse";
import Toast from "react-bootstrap/Toast";
import { useLanguageContext } from "../languageContext";

const SupervisorsScreen = ({ history }) => {
  const dispatch = useDispatch();
  const { supervisors, countSupr, error } = useSelector(
    (state) => state.mobileUsers
  );
  const { userInfo } = useSelector((state) => state.userLogin);
  const [selected, setSelected] = useState(6);
  const [selectedUser, setSelectedUser] = useState(null);
  const [limit, setLimit] = useState(5);
  const [data, setData] = useState([]);
  const [active, setActive] = useState(1);
  const [next, setNext] = useState(1);
  const [checkedList, setCheckedList] = useState([]);
  const [keyword, setKeyword] = useState("");
  const [openDrawer, setOpenDrawer] = useState(false);
  const [message, setMessage] = useState("");
  const [toastType, setToastType] = useState("");
  const [selectedState, setSelectedState] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [statsUsers, setStatsUsers] = useState([]);
  const { t } = useLanguageContext();

  const pageClick = async (pageNumber) => {
    setCheckedList([]);
    pageNumber = pageNumber <= 0 ? 1 : pageNumber;
    const payload = supervisors.slice(
      (pageNumber - 1) * limit,
      pageNumber * limit
    );
    setData(payload);
    setActive(pageNumber);
    if (pageNumber >= next + 8) setNext(next + 1);
    if (pageNumber !== 1 && pageNumber <= next) setNext(next - 1);
  };
  const loadData = () => {
    dispatch(getSupervisors(userInfo["token"]));
  };

  const onSearchUsers = () => {
    setActive(1);
    dispatch(
      searchSupervisors(
        userInfo["token"],
        keyword,
        selectedState,
        startDate,
        endDate
      )
    );
  };

  const onDeleteSupervisors = () => {
    dispatch(deleteSupervisors(userInfo["token"], checkedList));
    setCheckedList([]);
  };
  const onBlockUser = (userId, blocked) => {
    if (userId !== "") {
      dispatch(
        blockUser(userInfo["token"], userId, blocked, () => {
          loadData();
          setMessage(blocked ? t("successBlocked") : t("successUnblocked"));
          setToastType("success");
        })
      );
    } else {
      setMessage(t("mainError"));
      setToastType("danger");
    }
  };
  useEffect(() => {
    if (error) {
      setMessage(t("mainError"));
      setToastType("danger");
    }
  }, [error]);
  useEffect(() => {
    setTimeout(() => {
      setToastType("");
    }, 8000);
  }, [toastType]);

  useEffect(() => {
    if (
      keyword !== "" ||
      selectedState !== "" ||
      startDate !== "" ||
      endDate !== ""
    ) {
      onSearchUsers();
    }
    if (
      keyword === "" &&
      selectedState === "" &&
      startDate === "" &&
      endDate === ""
    ) {
      loadData();
      setActive(1);
    }
  }, [keyword, selectedState, startDate, endDate]);

  useEffect(() => {
    loadData();
  }, []);

  useEffect(() => {
    const payload = supervisors.slice((active - 1) * limit, active * limit);
    setData(payload);
    if (payload.length === 0 && active > 1) {
      pageClick(active - 1);
    }
    if (
      keyword === "" &&
      selectedState === "" &&
      startDate === "" &&
      endDate === ""
    )
      setStatsUsers(supervisors);
  }, [supervisors]);
  return (
    <>
      <Layout openDrawer={openDrawer}>
        {selectedUser && (
          <UserDetails
            user={selectedUser}
            onClose={() => setSelectedUser(null)}
            isSupervisor={true}
          />
        )}
        <Header setOpenDrawer={() => setOpenDrawer(!openDrawer)} />
        <Drawer
          setOpenDrawer={() => setOpenDrawer(!openDrawer)}
          open={openDrawer}
          history={history}
          selected={selected}
          onChange={(value) => setSelected(value)}
        />
        <Body>
          <Row>
            {/* {checkedList.length > 0 && (
              <DeleteButton onClick={() => onDeleteSupervisors()} />
            )} */}
            <Search
              hint={t("search")}
              showDelete={checkedList.length > 0}
              selectedState={selectedState}
              states={Array.from(new Set(statsUsers.map((user) => user.state)))}
              onDelete={() => onDeleteSupervisors()}
              onSearch={() => {
                if (keyword !== "") {
                  onSearchUsers();
                } else if (
                  selectedState !== "" ||
                  startDate !== "" ||
                  endDate !== ""
                ) {
                  onSearchUsers();
                }
              }}
              onChange={(value) => {
                setKeyword(value);
              }}
              onChangeState={(value) => {
                setSelectedState(value);
              }}
              onChangeEndDate={(value) => {
                if (!isNaN(new Date(value).getDate())) {
                  if (new Date(value) <= new Date(startDate)) {
                    setMessage(t("startGt"));
                    setToastType("danger");
                  } else setEndDate(new Date(value));
                }
              }}
              onChangeStartDate={(value) => {
                if (!isNaN(new Date(value).getDate())) {
                  if (new Date(value) >= new Date(endDate)) {
                    setMessage(t("startLt"));
                    setToastType("danger");
                  } else setStartDate(new Date(value));
                }
              }}
              onClearFilters={() => {
                onSearchUsers("", "", "");
                setSelectedState("");
                setStartDate("");
                setEndDate("");
              }}
            />
          </Row>
          {data.length === 0 ? (
            <Empty>{t("noUsers")}</Empty>
          ) : (
            <ResponsiveTable
              users={data}
              openDetails={(user) => setSelectedUser(user)}
              checkedList={checkedList}
              setCheckedList={(value) => setCheckedList(value)}
              hideCheckbox={false}
              hideStatus={false}
              isSuperisors={true}
              showMoreBtn={true}
              onBlockUser={(userId, blocked) => {
                onBlockUser(userId, blocked);
              }}
            />
          )}

          <Pagination
            current={active}
            total={Math.ceil(Math.abs(countSupr / limit))}
            onPageChange={(page) => {
              pageClick(page);
            }}
          />
          {toastType !== "" && (
            <ToastContainer
              containerPosition="sticky"
              position="top-end"
              style={{
                position: "fixed !important",
                bottom: "40%",
                right: 0,
                zIndex: "2000",
                float: "right",
                textAlign: "left",
              }}
            >
              <Toast
                delay={7000}
                animation
                transition={Collapse}
                bg={toastType}
                style={{
                  borderRadius: "4px",
                  fontFamily: "Din",
                  padding: "0 30px",
                }}
              >
                <Toast.Body className={"text-white"}>{message}</Toast.Body>
              </Toast>
            </ToastContainer>
          )}
        </Body>
      </Layout>
    </>
  );
};

const Body = styled.div`
  padding: 2vw 40px 0 40px;
  position: relative;
  overflow-x: hidden;
  @media (${QUERIES.mobileAndDown}) {
    padding: 30px 25px;
  }
`;
const Row = styled.div`
  display: flex;
  margin-top: 13px;
  justify-content: space-evenly;
`;
const Empty = styled.p`
  color: ${COLORS.gray400};
  font-family: "SF-display";
  font-weight: 400;
  font-size: 0.9rem;
  text-align: center;
  align-self: center;
  margin-top: 20vh;
`;
export default SupervisorsScreen;
