import styled from "styled-components";
import React from "react";
import Spinner from "react-bootstrap/Spinner";

import { COLORS } from "../contants/colors";
import { QUERIES } from "../contants/allConstants";

const GreenButton = ({
  color = COLORS.success,
  title,
  onClick,
  loading,
  size = "big",
  bold = true,
}) => {
  return (
    <Button color={color} onClick={onClick} size={size} bold={bold}>
      {loading ? <Spinner animation="border" variant="light" /> : title}
    </Button>
  );
};
const Button = styled.button`
  color: white;
  border-radius: 4px;
  font-family: "SF-display";
  font-size: ${(p) => (p.size === "small" ? "0.92rem" : "1rem")};
  font-weight: ${(p) => (p.bold ? 700 : 400)};
  background-color: ${(p) => p.color};
  border: none;
  height: ${(p) => (p.size === "small" ? 32 : 36)}px;
  margin-top: 16px;
  width: ${(p) => (p.size === "small" ? 44 : 48)}%;

  &:focus {
    outline-color: transparent;
  }
  @media (${QUERIES.mobileAndDown}) {
    width: 100%;
  }
`;

export default GreenButton;
