import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Header from "../components/Header";
import Layout from "../components/Layout";
import ResponsiveTable from "../components/ResponsiveTable";
import styled from "styled-components";
import Drawer from "../components/Drawer";
import { useState } from "react";
import UserDetails from "../components/UserDetails";
import Pagination from "react-responsive-pagination";
import ToastContainer from "react-bootstrap/esm/ToastContainer";
import Collapse from "react-bootstrap/Collapse";
import Toast from "react-bootstrap/Toast";
import Accordion from "react-bootstrap/Accordion";
import {
  blockUser,
  deleteSupervisors,
  getAvailableSubAdmins,
  getAvailableSuprv,
  getSupervisors,
  searchSugestions,
  searchSupervisors,
} from "../actions/userActions";
import Search from "../components/Search";
import DeleteButton from "../components/DeleteButton";
import { QUERIES } from "../contants/allConstants";
import { COLORS } from "../contants/colors";
import {
  addOwner,
  deleteTeam,
  getAllTeams,
  removeOwner,
  updateTeam,
} from "../actions/teamActions";
import Miniheader from "../components/Miniheader";
import AddButton from "../components/AddButton";
import OwnersSelection from "../components/OwnersSelection";
import Spinner from "react-bootstrap/Spinner";
import GreenButton from "../components/GreenBtn";
import WhiteBtn from "../components/WhiteBtn";
import TextField from "../components/TextField";
import FileField from "../components/FileField";
import Dropdown from "../components/Dropdown";
import { useLanguageContext } from "../languageContext";
import { RoleInstance } from "twilio/lib/rest/conversations/v1/service/role";

const TeamsScreen = ({ history }) => {
  const dispatch = useDispatch();
  const { teams, load_addition, error, success, loading_delete, loading } =
    useSelector((state) => state.team);
  const { userInfo } = useSelector((state) => state.userLogin);
  const { available_suprv, load_availability, available_subadmins } =
    useSelector((state) => state.supervisor);
  const [selected, setSelected] = useState(10);
  const [selectedTeam, setSelectedTeam] = useState(null);
  const [limit, setLimit] = useState(5);
  const [data, setData] = useState([]);
  const [active, setActive] = useState(1);
  const [next, setNext] = useState(1);
  const [checkedList, setCheckedList] = useState([]);
  const [keyword, setKeyword] = useState("");
  const [openDrawer, setOpenDrawer] = useState(false);
  const [showAddList, setShowAddList] = useState("");
  const [role, setRole] = useState("");
  const [message, setMessage] = useState("");
  const [toastType, setToastType] = useState("");
  const [selectedUser, setSelectedUser] = useState(null);
  const [selectedOwner, setSelectedOwner] = useState(null);
  const [selectedState, setSelectedState] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [statsUsers, setStatsUsers] = useState([]);
  const [teamId, setTeamId] = useState("");
  const [teamName, setTeamName] = useState("");
  const [teamType, setTeamType] = useState("");
  const [file, setFile] = useState();
  const [activeKey, setActiveKey] = useState(
    userInfo ? (userInfo.isAdmin ? "0" : "1") : "0"
  );
  const { t } = useLanguageContext();

  const onUpdateTeam = () => {
    if (teamName !== "" && teamType !== "") {
      dispatch(
        updateTeam(userInfo["token"], teamId, teamName, teamType, file, () => {
          setMessage(t("editSuccessTeam"));
          setToastType("success");
        })
      );
    } else {
      setMessage(t("fillout"));
      setToastType("danger");
    }
  };

  const setDefaultValues = () => {
    var team = selectedTeam;
    console.log("======default team", team);
    if (team) {
      setTeamId(team._id);
      setTeamName(team.name);
      setTeamType(team.category);
      setFile(team.image);
    }
  };
  const onCancel = () => {
    setDefaultValues();
  };

  const pageClick = async (pageNumber) => {
    setCheckedList([]);
    pageNumber = pageNumber <= 0 ? 1 : pageNumber;
    const payload = teams.slice((pageNumber - 1) * limit, pageNumber * limit);
    setData(payload);
    setActive(pageNumber);
    if (pageNumber >= next + 8) setNext(next + 1);
    if (pageNumber !== 1 && pageNumber <= next) setNext(next - 1);
  };

  const loadData = () => {
    dispatch(getAllTeams(userInfo["token"]));
  };

  const onSearchSugestions = (isSupervisor, isSubAdmin) => {
    setActive(1);
    dispatch(
      searchSugestions(
        userInfo["token"],
        keyword,
        isSupervisor,
        isSubAdmin,
        selectedState,
        startDate,
        endDate
      )
    );
  };

  const onDeleteTeam = (teamId) => {
    dispatch(
      deleteTeam(userInfo["token"], teamId, () => {
        setSelectedTeam(null);
        setMessage(t("deleteSuccessTeam"));
        setToastType("success");
      })
    );
  };
  const onBlockUser = (userId, blocked) => {
    if (userId !== "") {
      dispatch(
        blockUser(userInfo["token"], userId, blocked, () => {
          loadData();
          const updatedOwners = selectedTeam.owners.map((o) => {
            if (o._id === userId) return { ...o, isBlocked: blocked };
            else return o;
          });
          // console.log("===newTeam", newTeam);
          if (updatedOwners)
            setSelectedTeam({ ...selectedTeam, owners: updatedOwners });

          setMessage(blocked ? t("successBlocked") : t("successUnblocked"));

          setToastType("success");
        })
      );
    } else {
      setMessage(t("mainError"));
      setToastType("danger");
    }
  };

  useEffect(() => {
    setDefaultValues();
  }, [selectedTeam]);

  useEffect(() => {
    loadData();
  }, []);

  useEffect(() => {
    if (error) {
      console.log("======error", error);
      setMessage(t("mainError"));
      setToastType("danger");
    }
  }, [error]);

  useEffect(() => {
    var formattedTeams = [];
    teams.map((team) => formattedTeams.push(...team.data));
    const payload = formattedTeams.slice((active - 1) * limit, active * limit);
    setData(payload);
    if (payload.length === 0 && active > 1) {
      pageClick(active - 1);
    }
    if (userInfo) {
      if (userInfo.isSubAdmin) {
        const subAdminTeamIndex = formattedTeams.findIndex(
          (t) => t._id === userInfo.team._id
        );
        setSelectedTeam(formattedTeams[subAdminTeamIndex]);
        setShowAddList("");
        setRole("");
      } else if (userInfo.isAdmin && selectedTeam != null) {
        const index = formattedTeams.findIndex(
          (t) => t._id === selectedTeam._id
        );
        setSelectedTeam(formattedTeams[index]);
      }
    }
  }, [teams]);

  useEffect(() => {
    console.log("===statsUsers.length", statsUsers.length);
    console.log("===role", role);

    if (statsUsers.length === 0) {
      setStatsUsers(
        role.includes("subAdmin") ? available_subadmins : available_suprv
      );
    }
  }, [available_subadmins, available_suprv, role, statsUsers.length]);

  const onRemoveOwner = (ownerId, teamId) => {
    if (ownerId !== "" && teamId !== "") {
      console.log("=====ownerId", ownerId);
      dispatch(
        removeOwner(userInfo["token"], ownerId, teamId, () => {
          loadData();
          const newOwners = selectedTeam.owners.filter(
            (o) => o._id !== ownerId
          );

          const newTeam = {
            ...selectedTeam,
            owners: newOwners,
          };
          setSelectedTeam(newTeam);
          setMessage(t("removeSuper"));
          setToastType("success");
        })
      );
    } else {
      setMessage(t("mainError"));
      setToastType("danger");
    }
  };

  const onAddOwner = (
    newOwnerId,
    teamId,
    users,
    isSupervisor,
    isSubAdmin,
    isJournalist
  ) => {
    if (newOwnerId !== "" && teamId !== "") {
      dispatch(
        addOwner(
          userInfo["token"],
          newOwnerId,
          teamId,
          isSupervisor,
          isSubAdmin,
          isJournalist,
          () => {
            loadData();
            let owner = users.filter((s) => s._id === newOwnerId);
            if (owner.length > 0) {
              owner[0] = { ...owner[0], isSupervisor, isSubAdmin };
              const newTeam = {
                ...selectedTeam,
                owners: [...selectedTeam.owners, owner[0]],
              };
              setSelectedTeam(newTeam);
            }
            setShowAddList("");
            setRole("");
            setMessage(t("addSuprvSuccess"));
            setToastType("success");
            setSelectedOwner(null);
          }
        )
      );
    } else {
      setMessage(t("mainError"));
      setToastType("danger");
      setShowAddList("");
      setRole("");
      setSelectedOwner(null);
    }
  };

  useEffect(() => {
    setTimeout(() => {
      setToastType("");
    }, 8000);
  }, [toastType]);

  useEffect(() => {
    if (
      keyword !== "" ||
      selectedState !== "" ||
      startDate !== "" ||
      endDate !== ""
    ) {
      onSearchSugestions(
        role.includes("supervisor") || role.includes("journalist"),
        role.includes("subAdmin")
      );
    }
    if (
      keyword === "" &&
      selectedState === "" &&
      startDate === "" &&
      endDate === ""
    ) {
      if (role.includes("subAdmin")) {
        dispatch(getAvailableSubAdmins(userInfo["token"], []));
      } else dispatch(getAvailableSuprv(userInfo["token"]));

      setActive(1);
    }
  }, [keyword, selectedState, startDate, endDate]);

  return (
    <>
      <Layout openDrawer={openDrawer}>
        <Header setOpenDrawer={() => setOpenDrawer(!openDrawer)} />
        <Drawer
          setOpenDrawer={() => setOpenDrawer(!openDrawer)}
          open={openDrawer}
          history={history}
          selected={selected}
          onChange={(value) => setSelected(value)}
        />
        {/* list of users to add from */}
        {showAddList !== "" && selectedTeam && (
          <ContainerList>
            <WrapperList>
              <HeadList>
                <div style={{ textAlign: "center" }}>
                  <IconList
                    src={require("../images/whiteClose.svg").default}
                    alt=""
                    onClick={() => {
                      setShowAddList("");
                      setRole("");
                      setSelectedOwner(null);
                      setStartDate("");
                      setEndDate("");
                      setStatsUsers([]);
                      setSelectedState("");
                    }}
                  />
                  <TitleList>{showAddList}</TitleList>
                </div>
              </HeadList>
              {/* <Search
                color="dark"
                hint={"بحث"}
                onSearch={() => {
                  if (keyword !== "") {
                    onSearchUsers();
                  }
                }}
                onChange={(value) => {
                  setKeyword(value);
                  if (value === "") {
                    loadData();
                    setActive(1);
                  }
                }}
              /> */}
              <div style={{ padding: "15px", position: "relative" }} dir="ltr">
                <Search
                  border={true}
                  hint={t("search")}
                  showDelete={checkedList.length > 0}
                  selectedState={selectedState}
                  states={Array.from(
                    new Set(statsUsers.map((user) => user.state))
                  )}
                  onSearch={() => {
                    if (keyword !== "") {
                      // onSearchSugestions(true, false);
                      onSearchSugestions(
                        role.includes("supervisor") ||
                          role.includes("journalist"),
                        role.includes("subAdmin")
                      );
                    } else if (
                      selectedState !== "" ||
                      startDate !== "" ||
                      endDate !== ""
                    ) {
                      onSearchSugestions(
                        role.includes("supervisor") ||
                          role.includes("journalist"),
                        role.includes("subAdmin")
                      );
                    }
                  }}
                  onChange={(value) => {
                    setKeyword(value);
                  }}
                  onChangeState={(value) => {
                    setSelectedState(value);
                  }}
                  onChangeEndDate={(value) => {
                    if (!isNaN(new Date(value).getDate())) {
                      if (new Date(value) <= new Date(startDate)) {
                        setMessage(t("startGt"));
                        setToastType("danger");
                      } else setEndDate(new Date(value));
                    }
                  }}
                  onChangeStartDate={(value) => {
                    if (!isNaN(new Date(value).getDate())) {
                      if (new Date(value) >= new Date(endDate)) {
                        setMessage(t("startLt"));
                        setToastType("danger");
                      } else setStartDate(new Date(value));
                    }
                  }}
                  onClearFilters={() => {
                    onSearchSugestions(
                      role.includes("supervisor") ||
                        role.includes("journalist"),
                      role.includes("subAdmin")
                    );
                    setSelectedState("");
                    setStartDate("");
                    setEndDate("");
                  }}
                />
              </div>
              {load_availability ? (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Spinner animation="border" variant="primary" size="xl" />
                </div>
              ) : role.includes("supervisor") &&
                available_suprv.length === 0 ? (
                <Empty>{t("noSuprv")}</Empty>
              ) : role.includes("subAdmin") &&
                available_subadmins.length === 0 ? (
                <Empty>{t("noSubAdmin")}</Empty>
              ) : (
                <div style={{ paddingBottom: "20px" }}>
                  <OwnersSelection
                    data={
                      role.includes("supervisor") || role.includes("journalist")
                        ? available_suprv
                        : available_subadmins
                    }
                    title={showAddList}
                    selectedValue={selectedOwner}
                    onClick={(id, event) => {
                      console.log("====id", id);
                      if (typeof id === "string") setSelectedOwner(id);
                    }}
                  />
                  {selectedOwner && (
                    <Row>
                      <GreenButton
                        title={t("add")}
                        onClick={() =>
                          onAddOwner(
                            selectedOwner,
                            selectedTeam["_id"],
                            role.includes("supervisor") ||
                              role.includes("subAdmin")
                              ? available_suprv
                              : available_suprv,
                            role.includes("supervisor"),
                            role.includes("subAdmin"),
                            role.includes("journalist")
                          )
                        }
                        loading={load_addition}
                      />
                    </Row>
                  )}
                </div>
              )}
            </WrapperList>
          </ContainerList>
        )}
        {selectedUser && (
          <UserDetails
            user={selectedUser}
            onClose={() => setSelectedUser(null)}
            isSupervisor={true}
          />
        )}
        {selectedTeam ? (
          <DetailsBody>
            <Miniheader
              large={true}
              title={selectedTeam.key + "# " + selectedTeam.name}
              arrow={userInfo.isAdmin}
              goBack={() => {
                setSelectedTeam(null);
                setMessage("");
                setToastType("");
              }}
            />
            <Wrapper>
              <Accordion activeKey={activeKey}>
                {userInfo.isAdmin && (
                  <Accordion.Item eventKey={"0"}>
                    <Accordion.Header
                      id="accordion-org"
                      onClick={() =>
                        activeKey === "0" ? setActiveKey("") : setActiveKey("0")
                      }
                    >
                      {t("groupDetails")}
                    </Accordion.Header>
                    <Accordion.Body>
                      {/* Team details */}
                      <DetailsWrapper>
                        <Row>
                          <Col>
                            <FileField
                              type={"image/png, image/jpeg, image/jpg"}
                              title={t("groupImage")}
                              setFile={(value) => setFile(value)}
                              file={file}
                              large={true}
                            />
                          </Col>
                          <div style={{ width: "30px" }} />
                          <Col style={{ marginTop: "5px" }}>
                            {/* <Dropdown
                              title={"نوع الجمعية"}
                              items={[
                                "حزب سياسي",
                                "جمعية رياضية",
                                "جمعية مدنية",
                              ]}
                              value={teamType}
                              onChange={(value) => setTeamType(value)}
                              styles={{ padding: "2.5px" }}
                            /> */}
                            <div style={{ marginTop: "20px" }}>
                              <TextField
                                title={t("teamName")}
                                hint={""}
                                value={teamName}
                                onChange={(value) => setTeamName(value)}
                              />
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <GreenButton
                            color={COLORS.primary}
                            title={t("edit")}
                            onClick={() => onUpdateTeam()}
                            loading={loading}
                            bold={false}
                          />
                          <WhiteBtn
                            title={t("cancel")}
                            onClick={() => onCancel()}
                            bold={false}
                          />
                        </Row>
                      </DetailsWrapper>
                    </Accordion.Body>
                  </Accordion.Item>
                )}
                <Accordion.Item eventKey={"1"}>
                  <Accordion.Header
                    id="accordion-org"
                    onClick={() =>
                      activeKey === "1" ? setActiveKey("") : setActiveKey("1")
                    }
                  >
                    {t("groupmembers")}
                  </Accordion.Header>
                  <Accordion.Body>
                    {/* Sub Admins */}
                    {userInfo.isAdmin && (
                      <div>
                        <SimpleRow style={{ marginBottom: "40px" }}>
                          <Title>{t("SubAdmins")}</Title>
                          {selectedTeam["owners"].filter((u) =>
                            u ? u.isSubAdmin : false
                          ).length < 2 && (
                            <AddButton
                              title={t("addSubAdmin")}
                              onClick={() => {
                                setStatsUsers([]);
                                dispatch(
                                  getAvailableSubAdmins(
                                    userInfo["token"],
                                    selectedTeam["owners"].filter((u) =>
                                      u ? u.isSubAdmin : false
                                    )
                                  )
                                );
                                setShowAddList(t("addSubAdmin"));
                                setRole("subAdmin");
                              }}
                            />
                          )}
                        </SimpleRow>
                        <ResponsiveTable
                          users={
                            selectedTeam["owners"]
                              ? selectedTeam["owners"].filter(
                                  (u) => u.isSubAdmin
                                )
                              : []
                          }
                          checkedList={checkedList}
                          setCheckedList={(value) => setCheckedList(value)}
                          hideCheckbox={true}
                          isSuperisors={true}
                          openDetails={(user) => setSelectedUser(user)}
                          showTeamDetails={true}
                          showMoreBtn={true}
                          onRemoveOwner={(ownerId) => {
                            onRemoveOwner(ownerId, selectedTeam["_id"]);
                          }}
                          hideStatus={false}
                          onBlockUser={(userId, blocked) => {
                            onBlockUser(userId, blocked);
                          }}
                        />
                        <Label>
                          {selectedTeam["owners"].filter((u) => u.isSubAdmin)
                            .length === 0 && t("noSubAdmins")}
                        </Label>
                      </div>
                    )}

                    {/* Supervisors */}
                    <SimpleRow>
                      <Title>{t("Supervisors")}</Title>
                      <AddButton
                        title={t("addSuprvis")}
                        onClick={() => {
                          setStatsUsers([]);
                          dispatch(getAvailableSuprv(userInfo["token"]));
                          setShowAddList(t("addSuprvis"));
                          setRole("supervisor");
                        }}
                      />
                    </SimpleRow>
                    <ResponsiveTable
                      users={selectedTeam["owners"].filter(
                        (u) => u.isSupervisor
                      )}
                      checkedList={checkedList}
                      setCheckedList={(value) => setCheckedList(value)}
                      hideCheckbox={true}
                      isSuperisors={true}
                      openDetails={(user) => setSelectedUser(user)}
                      showTeamDetails={true}
                      showMoreBtn={true}
                      onRemoveOwner={(ownerId) => {
                        onRemoveOwner(ownerId, selectedTeam["_id"]);
                      }}
                      hideStatus={false}
                      onBlockUser={(userId, blocked) => {
                        onBlockUser(userId, blocked);
                      }}
                    />
                    <Label>
                      {selectedTeam["owners"].filter((u) => u.isSupervisor)
                        .length === 0 && t("noSubAdmin")}
                    </Label>

                    {/* Journalists */}
                    <SimpleRow>
                      <Title>{t("Journalists")}</Title>
                      <AddButton
                        title={t("addJourn")}
                        onClick={() => {
                          setStatsUsers([]);
                          dispatch(getAvailableSuprv(userInfo["token"]));
                          setShowAddList(t("addJourn"));
                          setRole("journalist");
                        }}
                      />
                    </SimpleRow>
                    <ResponsiveTable
                      users={selectedTeam["owners"].filter(
                        (u) => u.isJournalist
                      )}
                      checkedList={checkedList}
                      setCheckedList={(value) => setCheckedList(value)}
                      hideCheckbox={true}
                      isSuperisors={true}
                      openDetails={(user) => setSelectedUser(user)}
                      showTeamDetails={true}
                      showMoreBtn={true}
                      onRemoveOwner={(ownerId) => {
                        onRemoveOwner(ownerId, selectedTeam["_id"]);
                      }}
                      hideStatus={false}
                      onBlockUser={(userId, blocked) => {
                        onBlockUser(userId, blocked);
                      }}
                    />
                    <Label>
                      {selectedTeam["owners"].filter((u) => u.isJournalist)
                        .length === 0 && t("noJournalists")}
                    </Label>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </Wrapper>
            {/* <Button onClick={() => onDeleteTeam(selectedTeam._id)}>
              {loading_delete ? (
                <Spinner animation="border" variant="light" />
              ) : (
                <div>
                  <span>{"حذف الجمعية"}</span>
                  <Icon src={require("../images/deletew.svg").default} alt="" />
                </div>
              )}
            </Button> */}
            {toastType !== "" && (
              <ToastContainer
                containerPosition="sticky"
                position="top-end"
                style={{
                  position: "fixed !important",
                  bottom: "40%",
                  right: 0,
                  zIndex: "2000",
                  float: "right",
                  textAlign: "left",
                }}
              >
                <Toast
                  delay={7000}
                  animation
                  transition={Collapse}
                  bg={toastType}
                  style={{
                    borderRadius: "4px",
                    fontFamily: "Din",
                    padding: "0 30px",
                  }}
                >
                  <Toast.Body className={"text-white"}>{message}</Toast.Body>
                </Toast>
              </ToastContainer>
            )}
          </DetailsBody>
        ) : (
          <Body>
            {data.length === 0 ? (
              <Empty>{t("noGroup")}</Empty>
            ) : (
              <ResponsiveTable
                users={data}
                onEditTeam={(team) => setSelectedTeam(team)}
                checkedList={checkedList}
                setCheckedList={(value) => setCheckedList(value)}
                hideCheckbox={true}
                hideStatus={true}
                isTeams={true}
                hideCountry={true}
              />
            )}

            <Pagination
              current={active}
              total={Math.ceil(Math.abs(teams.length / limit))}
              onPageChange={(page) => {
                pageClick(page);
              }}
            />
            {toastType !== "" && (
              <ToastContainer
                containerPosition="sticky"
                position="top-end"
                style={{
                  position: "fixed !important",
                  bottom: "40%",
                  right: 0,
                  zIndex: "2000",
                  float: "right",
                  textAlign: "left",
                }}
              >
                <Toast
                  delay={7000}
                  animation
                  transition={Collapse}
                  bg={toastType}
                  style={{
                    borderRadius: "4px",
                    fontFamily: "Din",
                    padding: "0 30px",
                  }}
                >
                  <Toast.Body className={"text-white"}>{message}</Toast.Body>
                </Toast>
              </ToastContainer>
            )}
          </Body>
        )}
      </Layout>
    </>
  );
};

const Body = styled.div`
  padding: 3vw 40px;
  position: relative;
  overflow-x: hidden;
  font-family: "SF-display";
  @media (${QUERIES.mobileAndDown}) {
    padding: 30px 25px;
  }
`;
const Title = styled.span`
  font-weight: 500;
  text-align: left;
  font-family: "SF-display";
  text-align: left;
  color: #414040 !important;
`;
const Row = styled.div`
  display: flex;
  margin-top: 10px;
  justify-content: space-evenly;
`;
const Empty = styled.p`
  color: ${COLORS.gray400};
  font-family: "SF-display";
  font-weight: 400;
  font-size: 0.9rem;
  text-align: center;
  align-self: center;
  margin-top: 10vh;
  margin-bottom: 30px;
`;
const Label = styled.p`
  color: ${COLORS.gray400};
  font-family: "SF-display";
  font-weight: 400;
  font-size: 0.9rem;
  text-align: center;
  align-self: center;
`;
const DetailsBody = styled.div`
  padding: 3vw 4vw;
  position: relative;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const SimpleRow = styled.div`
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
  align-items: end;
`;
const Wrapper = styled.div`
  background-color: white;
  padding: 20px 30px;
  width: 100%;
  font-family: "Din" !important;
`;
const DetailsWrapper = styled.div`
  background-color: white;
  padding: 0;
  width: 100%;
  /* display: flex; */
`;
const ContainerList = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 100%;
`;
const WrapperList = styled.div`
  position: fixed;
  background-color: red;
  top: 24%;
  right: 0;
  left: 0;
  margin: 0 auto;
  z-index: 100;
  background-color: white;
  border-radius: 8px;
  /* max-height: 75vh; */
  /* overflow-y: auto; */
  min-width: 300px;
  width: 80vw;
  max-width: 700px;
  color: ${COLORS.black27};
  font-family: "Din" !important;
  font-weight: 400;
  font-size: 0.8125rem;
  box-shadow: 0 10px 30px rgba(57, 57, 57, 0.2),
    0 50px 10px 100vh rgba(57, 57, 57, 0.15);
`;
const HeadList = styled.div`
  width: 100%;
  height: 38px;
  background-color: ${COLORS.primary};
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
`;
const IconList = styled.img`
  width: 10px;
  height: 10px;
  object-fit: fill;
  cursor: pointer;
  margin: 12px 16px 0 0;
  float: right;
`;
const TitleList = styled.span`
  color: white;
  font-family: "SF-display";
  font-size: 1rem;
  font-weight: 600;
  text-align: center;
  margin: 0;
  padding-top: 6px;
  vertical-align: middle;
  display: inline-block;
`;
const Button = styled.button`
  border-radius: 6px;
  background-color: #de431a;
  border: none;
  height: 42px;
  width: 50%;
  margin-right: 5px;
  font-family: "SF-display";
  margin-top: 70px;
  color: white !important;
  &:focus {
    outline-color: ${COLORS.primary};
  }
  @media (${QUERIES.mobileAndDown}) {
    width: 100%;
  }
`;
const Icon = styled.img`
  width: 15px;
  height: 18px;
  object-fit: fill;
  cursor: pointer;
  margin-right: 10px;
`;
const Col = styled.div`
  flex: 1;
`;
export default TeamsScreen;
