import styled from "styled-components";
import React from "react";
import { COLORS } from "../contants/colors";
import { QUERIES } from "../contants/allConstants";

const DeleteButton = ({ onClick }) => {
  return (
    <Button onClick={onClick}>
      <Icon src={require("../images/delete.svg").default} alt="" />
    </Button>
  );
};
const Button = styled.button`
  border-radius: 6px;
  background-color: white;
  border: none;
  height: 42px;
  width: 52px;
  margin-right: 5px;
  &:focus {
    outline-color: ${COLORS.primary};
  }
  @media (${QUERIES.mobileAndDown}) {
    width: 100%;
  }
`;

const Icon = styled.img`
  width: 15px;
  height: 18px;
  object-fit: fill;
  cursor: pointer;
`;

export default DeleteButton;
