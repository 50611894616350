import React, { useEffect, useState } from "react";
import ReactECharts from "echarts-for-react";
import * as echarts from "echarts";
import styled from "styled-components";
import { QUERIES } from "../contants/allConstants";
import _ from "lodash";
echarts.registerTheme("my_theme", {
  color: "#ffa927",
});
export default function BelongChart({ users }) {
  const option = {
    xAxis: {
      type: "category",
      data: ["مواطن حر"],
    },
    yAxis: {
      type: "value",
    },
    series: [
      {
        data: [0],
        type: "bar",
      },
    ],
  };
  const [options, setOptions] = useState(option);

  useEffect(() => {
    var xAxis = [];
    var data = [];
    const groupBy = (x, f) =>
      x.reduce((a, b, i) => ((a[f(b, i, x)] ||= []).push(b), a), {});
    const groups = groupBy(users, (v) => v.belonging);

    for (const [key, value] of Object.entries(groups)) {
      if (key === "مواطن حر") {
        data.push(value.length);
        xAxis.push(key);
      } else {
        var belongings = [];
        for (var v of value) {
          if (v.defaultBelonging) belongings.push(...v.defaultBelonging);
          if (v.politicBelonging) {
            belongings.push(...v.politicBelonging);
          }
          if (v.sportBelonging) belongings.push(...v.sportBelonging);
        }
        belongings = _.uniqBy(belongings, "name");

        let count = new Map();
        for (var bel of belongings) {
          xAxis.push(bel.name);
          count.set(bel.name, 0);
        }
        for (var user of value) {
          if (user.defaultBelonging)
            for (var belong of user.defaultBelonging) {
              count.set(belong.name, count.get(belong.name) + 1);
            }
          if (user.politicBelonging)
            for (var belong1 of user.politicBelonging) {
              count.set(belong1.name, count.get(belong1.name) + 1);
            }
          if (user.sportBelonging)
            for (var belong2 of user.sportBelonging) {
              count.set(belong2.name, count.get(belong2.name) + 1);
            }
        }

        data.push(...count.values());
      }
    }
    setOptions({
      ...options,
      xAxis: { ...options.xAxis, data: xAxis },
      series: [
        {
          data: data,
          type: "bar",
        },
      ],
    });
  }, [users]);

  return <ReactECharts option={options} theme="my_theme" />;
}
