import React, { useEffect, useRef, useState } from "react";
import HomeScreen from "./screens/HomeScreen";
import LoginScreen from "./screens/LoginScreen";
import {
  BrowserRouter as Router,
  Route,
  Redirect,
  Switch,
} from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import QuestionsScreen from "./screens/QuestionsScreen";
import "react-circular-progressbar/dist/styles.css";
import NewQuestionScreen from "./screens/NewQuestionScreen";
import ProductsScreen from "./screens/ProductsScreen";
import NewsScreen from "./screens/NewsScreen";
import AlertsScreen from "./screens/AlertsScreen";
import ModeratorScreen from "./screens/ModeratorScreen";
import { getAllAlerts } from "./actions/alertActions";
import { SOCKET_URL } from "./contants/allConstants";
import { io } from "socket.io-client";
import { getAllNotifications } from "./actions/notificationActions";
import { getAllTeams } from "./actions/teamActions";
import SupervisorsScreen from "./screens/SupervisorsScreen";
import SupportScreen from "./screens/SupportScreen";
// import AboutTeamScreen from "./screens/AboutTeamScreen";
import NewTeamScreen from "./screens/NewTeamScreen";
import TeamsScreen from "./screens/TeamsScreen";
import ChangePwdScreen from "./screens/ChangePwdScreen";
import "./i18n";
import { LanguageContextProvider } from "./languageContext";
import VideosScreen from "./screens/VideosScreen";
// import { LanguageContextProvider } from "./LanguageContext";

const App = () => {
  const { userInfo } = useSelector((state) => state.userLogin);
  const [openSupport, setSupport] = useState(false);
  const socket = useRef();
  const dispatch = useDispatch();

  useEffect(() => {
    console.log("===App 0 userInfo", userInfo);

    if (window.location.pathname === "/dashboard/support") {
      setSupport(true);
    } else if (userInfo) {
      setSupport(false);
      console.log("===APP 1 userInfo", userInfo["_id"]);
      socket.current = io(SOCKET_URL);
    }
  }, []);
  useEffect(() => {
    if (socket.current) {
      console.log("===========socket.current", socket.current);
      socket.current.on("update-alerts", (msg) => {
        console.log("===========update-alerts userInfo", userInfo);

        dispatch(getAllAlerts(userInfo["token"], userInfo["_id"], "alert"));
      });
      socket.current.on("update-notifications", (msg) => {
        console.log("===========update-notifications", userInfo);

        dispatch(getAllNotifications(userInfo["token"], userInfo["_id"]));
      });
    }
  }, [socket.current]);
  return (
    <LanguageContextProvider>
      {openSupport ? (
        <SupportScreen />
      ) : !userInfo ? (
        <LoginScreen />
      ) : userInfo.isBlocked ? (
        <LoginScreen />
      ) : (
        <main>
          <Router>
            <Switch>
              <Route path="/dashboard/login" component={LoginScreen} />
              {userInfo.isJournalist ? (
                <Route path="/dashboard/" component={NewsScreen} exact />
              ) : (
                <Route path="/dashboard/" component={HomeScreen} exact />
              )}
              <Route path="/dashboard/questions" component={QuestionsScreen} />
              <Route path="/dashboard/alerts" component={AlertsScreen} />
              <Route path="/dashboard/videos" component={VideosScreen} />
              <Route path="/dashboard/password" component={ChangePwdScreen} />
              <Route
                path="/dashboard/supervisors"
                component={SupervisorsScreen}
              />
              <Route
                path="/dashboard/new-question"
                component={NewQuestionScreen}
              />
              <Route path="/dashboard/products" component={ProductsScreen} />
              <Route path="/dashboard/news" component={NewsScreen} />
              <Route path="/dashboard/moderator" component={ModeratorScreen} />
              {/* <Route path="/dashboard/about-team" component={AboutTeamScreen} /> */}
              <Route path="/dashboard/new-team" component={NewTeamScreen} />
              <Route path="/dashboard/teams" component={TeamsScreen} />

              <Route path="/support" component={SupportScreen} />
              <Redirect to="/dashboard/" />
            </Switch>
          </Router>
        </main>
      )}
    </LanguageContextProvider>
  );
};

export default App;
