import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Header from "../components/Header";
import Layout from "../components/Layout";
import styled from "styled-components";
import Drawer from "../components/Drawer";
import { useState } from "react";
import Miniheader from "../components/Miniheader";
import TextArea from "../components/TextArea";
import TextField from "../components/TextField";
import FileField from "../components/FileField";
import { COLORS } from "../contants/colors";
import GreenButton from "../components/GreenBtn";
import WhiteBtn from "../components/WhiteBtn";
import { QUERIES, RADIUS } from "../contants/allConstants";
import News from "../components/News";
import {
  addComment,
  addReply,
  createNews,
  deleteNews,
  deleteReply,
  getAllNews,
  getCommentsDetails,
  updateNews,
} from "../actions/newsActions";
import Toast from "react-bootstrap/Toast";
import ToastContainer from "react-bootstrap/ToastContainer";
import Collapse from "react-bootstrap/Collapse";
import NewsDetails from "../components/NewsDetails";
import { useLanguageContext } from "../languageContext";
import LocationField from "../components/LocationField";
import SmallDropdown from "../components/SmallDropdown";
import MultiDropdown from "../components/MultiDropdown";
import Dropdown from "../components/Dropdown";
import { getAllTeams } from "../actions/teamActions";
import ConfirmationPopup from "../components/ConfirmationPopup";

import moment from "moment";
import fr from "moment/locale/fr";
import it from "moment/locale/it";

const INITIAL_PLACE = { lat: 45.4683666, lng: 9.1795032 };

const NewsScreen = ({ history }) => {
  const [selected, setSelected] = useState(5);
  const dispatch = useDispatch();
  const { news, error, loading } = useSelector((state) => state.news);
  const { userInfo } = useSelector((state) => state.userLogin);
  const [newsId, setId] = useState("");
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [liveUrl, setLiveUrl] = useState("");
  const [file, setFile] = useState("");
  const [message, setMessage] = useState("");
  const [toastType, setToastType] = useState("");
  const [openDrawer, setOpenDrawer] = useState(false);
  const [selectedNews, setSelectedNews] = useState(null);
  const [age, setAge] = useState("");
  const [gender, setGender] = useState("");
  const [finalGender, setFinalGender] = useState("");
  const [minAge, setMinAge] = useState("");
  const [maxAge, setMaxAge] = useState("");
  const [includeLocation, setIncludeLocation] = useState(false);
  const [radius, setRadius] = useState("3");
  const [expired, setExpired] = useState("1");
  const [defaultBelonging, setDefaultBelonging] = useState([]);
  const [sportArray, setSportArray] = useState([]);
  const [defaultArray, setDefaultArray] = useState([]);
  const [politicsArray, setPoliticsArray] = useState([]);
  const [place, setPlace] = useState(INITIAL_PLACE);
  const { teams } = useSelector((state) => state.team);
  const [showConfirmation, setShowConfirm] = useState(false);

  const { t } = useLanguageContext();

  const AGE = [
    { label: t("indeterminate"), minAge: "", maxAge: "" },
    { label: "5-10", minAge: "5", maxAge: "10" },
    { label: "10-20", minAge: "10", maxAge: "20" },
    { label: "20-30", minAge: "20", maxAge: "30" },
    { label: "30-40", minAge: "30", maxAge: "40" },
    { label: t("greater") + "40", minAge: "40", maxAge: "1000" },
  ];
  const GENDER = ["", t("female"), t("male")];

  useEffect(() => {
    dispatch(getAllNews(userInfo["token"]));
  }, []);

  useEffect(() => {
    const lang = JSON.parse(localStorage.getItem("langAnisso")).lang;
    if (lang === "fr") {
      moment.locale("fr", fr);
    } else if (lang === "it") {
      moment.locale("it", it);
    } else moment.locale("en");

    dispatch(
      getAllTeams(
        userInfo["token"],
        userInfo["isSupervisor"] || userInfo["isSubAdmin"]
          ? userInfo["_id"]
          : null
      )
    );
  }, []);
  useEffect(() => {
    if (file.size) {
      if (file.size > 100e6) {
        setFile("");
        setMessage(t("largeFile"));
        setToastType("danger");
      }
    }
  }, [file]);

  useEffect(() => {
    if (error) {
      setMessage(t("mainError"));
      setToastType("danger");
    }
  }, [error]);

  useEffect(() => {
    setTimeout(() => {
      setToastType("");
    }, 8000);
  }, [toastType]);

  const onDeleteNews = (id) => {
    dispatch(
      deleteNews(userInfo["token"], id, () => {
        setTitle("");
        setDescription("");
        setFile("");
        setId("");
        setLiveUrl("");
      })
    );
  };
  const creationConfirmation = () => {
    if (title !== "" && description !== "" && (file !== "" || liveUrl !== "")) {
      setShowConfirm(true);
    } else {
      setMessage(t("fillout"));
      setToastType("danger");
    }
  };
  const onCreateNews = () => {
    setShowConfirm(false);
    dispatch(
      createNews(
        userInfo["token"],
        title,
        description,
        file,
        liveUrl,
        minAge, //
        maxAge, //
        finalGender, //
        "", //work
        [], //sportBelonging
        [], //politicsBelonging
        defaultBelonging, //
        includeLocation ? place.lng : "", //
        includeLocation ? place.lat : "", //
        includeLocation ? radius : "", //
        expired, //
        t("newNews"),
        () => {
          setMessage(t("successAddN"));
          setToastType("success");
          setTitle("");
          setDescription("");
          setFile("");
          setLiveUrl("");
        }
      )
    );
  };

  const onUpdateNews = () => {
    if (title !== "" && description !== "" && (file !== "" || liveUrl !== "")) {
      dispatch(
        updateNews(
          userInfo["token"],
          newsId,
          title,
          description,
          file,
          liveUrl,
          minAge, //
          maxAge, //
          finalGender, //
          "", //work
          [], //sportBelonging
          [], //politicsBelonging
          defaultBelonging, //
          includeLocation ? place.lng : "", //
          includeLocation ? place.lat : "", //
          includeLocation ? radius : "", //
          expired,
          () => {
            setMessage(t("successEditN"));
            setToastType("success");
            setTitle("");
            setDescription("");
            setFile("");
            setId("");
            setLiveUrl("");
          }
        )
      );
    } else {
      setMessage(t("fillout"));
      setToastType("danger");
    }
  };

  const onComment = (comment, callback) => {
    if (comment !== "") {
      dispatch(
        addComment(userInfo["token"], comment, selectedNews["_id"], callback)
      );
    } else {
      setMessage(t("fillout"));
      setToastType("danger");
    }
  };

  const onReply = (reply, commentId) => {
    if (reply !== "") {
      dispatch(
        addReply(userInfo["token"], reply, commentId, selectedNews["_id"])
      );
    }
    // else {
    //   setMessage(t("fillout"));
    //   setToastType("danger");
    // }
  };

  const onDeleteReply = (replyId, commentId) => {
    if (replyId !== "") {
      dispatch(
        deleteReply(
          userInfo["token"],
          replyId,
          commentId,
          selectedNews["_id"],
          () => {}
        )
      );
    }
  };
  const getCommentsWithDetails = (newsId) => {
    dispatch(getCommentsDetails(userInfo["token"], newsId));
  };
  useEffect(() => {
    if (userInfo.isSupervisor) {
      if (userInfo.team.category.includes("مدنية")) {
        setDefaultBelonging([userInfo.team.name]);
      }
    } else if (teams) {
      //ekhtiyari
      if (teams.length > 0) {
        const defaultTeam = teams.filter(({ _id }) => _id.includes("مدنية"));
        if (defaultTeam.length > 0) {
          if (defaultTeam[0].data) {
            const _defaultData = defaultTeam[0].data.map((team) => team.name);
            setDefaultArray(_defaultData);
          }
          console.log("defaultArray==", defaultArray);
          console.log("defaultTeam[0].data==", defaultTeam[0].data);
        }
      }
    }
  }, [teams]);

  return (
    <>
      <Layout>
        <Header setOpenDrawer={() => setOpenDrawer(!openDrawer)} />
        <Drawer
          setOpenDrawer={() => setOpenDrawer(!openDrawer)}
          open={openDrawer}
          history={history}
          selected={selected}
          onChange={(value) => setSelected(value)}
        />
        {selectedNews && (
          <NewsDetails
            news={selectedNews}
            onClose={() => setSelectedNews(null)}
            onSendComment={(comment, callback) => onComment(comment, callback)}
            onSendReply={(reply, commentId) => onReply(reply, commentId)}
            onDeleteReply={(reply, commentId) =>
              onDeleteReply(reply, commentId)
            }
          />
        )}
        <Body>
          {showConfirmation && (
            <ConfirmationPopup
              title={t("confirmN")}
              description={t("sureN")}
              loading={loading}
              onConfirm={() => onCreateNews()}
              onCancel={() => setShowConfirm(false)}
            />
          )}
          <Miniheader title={t("addNews")} />
          <Wrapper>
            <Row>
              <Col>
                <Grid>
                  {news.length === 0 ? (
                    <Empty>{t("noNews")}</Empty>
                  ) : (
                    news.map((item) => (
                      <News
                        id={item["_id"]}
                        media={item["media"]}
                        title={item["title"]}
                        description={item["description"]}
                        live={item["live"] ? item["live"] : ""}
                        creator={item["creator"]}
                        expireAt={item["expireAt"]}
                        onClick={() => {
                          setSelectedNews(item);
                          getCommentsWithDetails(item["_id"]);
                        }}
                        onDelete={(id) => {
                          onDeleteNews(id);
                        }}
                        onUpdate={() => {
                          setId(item["_id"]);
                          setTitle(item["title"]);
                          setDescription(item["description"]);
                          setDefaultBelonging(
                            item["defaultBelonging"].map((e) => e.name)
                          );

                          if (item.hasOwnProperty("minAge") && item["minAge"]) {
                            const selected = AGE.find(
                              (a) => a.minAge === item["minAge"].toString()
                            );
                            setAge(selected.label);
                            setMinAge(selected.minAge);
                            setMaxAge(selected.maxAge);
                          }
                          if (item.hasOwnProperty("gender") && item["gender"]) {
                            setGender(
                              item["gender"]
                                ? t(item["gender"].toLowerCase())
                                : ""
                            );
                            setFinalGender(item["gender"]);
                          }
                          setExpired("1");

                          if (
                            item.hasOwnProperty("location") &&
                            item["location"]
                          ) {
                            if (item["location"]["coordinates"].length === 2) {
                              setPlace({
                                lat: item["location"]["coordinates"][1],
                                lng: item["location"]["coordinates"][0],
                              });
                              setIncludeLocation(true);
                              setRadius(item["radius"].toString());
                            }
                          }
                          if (item.live !== "" && item.live) {
                            setFile("");
                            setLiveUrl(item.live);
                          } else {
                            setLiveUrl("");
                            setFile(item["media"]);
                          }
                        }}
                      />
                    ))
                  )}
                </Grid>
              </Col>
              <Divider />
              <Col>
                <TextField
                  title={t("newsTitle")}
                  hint={""}
                  value={title}
                  onChange={(value) => setTitle(value)}
                />
                <TextArea
                  title={t("newsDetails")}
                  hint={""}
                  value={description}
                  onChange={(value) => setDescription(value)}
                />
                <FileField
                  type={
                    "image/png, image/jpeg, image/jpg, audio/mp3, audio/wav, video/*"
                  }
                  title={t("file")}
                  setFile={(value) => setFile(value)}
                  file={file}
                />
                <TextField
                  title={t("liveUrl")}
                  hint={""}
                  value={liveUrl}
                  onChange={(value) => setLiveUrl(value)}
                />
                <LocationField
                  title={t("distance")}
                  place={place}
                  setPlace={setPlace}
                  isChecked={includeLocation}
                  setCheckbox={() => setIncludeLocation(!includeLocation)}
                  radius={radius}
                >
                  <div style={{ marginRight: "8px" }} />
                  <SmallDropdown
                    value={radius}
                    items={RADIUS}
                    prefix={t("km")}
                    onChange={(value) => setRadius(value)}
                  />
                </LocationField>
                <Row>
                  {(userInfo.isAdmin || userInfo.isSubAdmin) && (
                    <MultiDropdown
                      title={t("country")}
                      items={defaultArray}
                      selectedTeams={defaultBelonging}
                      onChange={(value) => setDefaultBelonging(value)}
                    />
                  )}
                  <div style={{ width: "10px" }} />

                  <Dropdown
                    title={t("age")}
                    items={AGE}
                    value={age}
                    onChange={(value) => {
                      setAge(value);
                      const selected = AGE.find((item) => item.label === value);
                      setMinAge(selected.minAge);
                      setMaxAge(selected.maxAge);
                    }}
                  />
                  <div style={{ width: "10px" }} />
                  <Dropdown
                    title={t("gender")}
                    items={GENDER}
                    value={gender}
                    onChange={(value) => {
                      setGender(value);
                      setFinalGender(
                        value === t("female")
                          ? "female"
                          : value === t("male")
                          ? "male"
                          : ""
                      );
                    }}
                  />
                </Row>

                {/* <Title>
                  {t("autoDelete")}
                  <div style={{ marginRight: "8px" }} />

                  <SmallDropdown
                    value={expired}
                    items={EXPIRATION}
                    onChange={(value) => setExpired(value)}
                  />
                </Title> */}
                <Row>
                  <GreenButton
                    title={t("send")}
                    onClick={() =>
                      newsId !== "" ? onUpdateNews() : creationConfirmation()
                    }
                    loading={loading}
                  />
                  <WhiteBtn
                    disabled={loading}
                    title={t("cancel")}
                    onClick={() => {
                      setTitle("");
                      setDescription("");
                      setFile("");
                      setLiveUrl("");
                      setId("");
                    }}
                  />
                </Row>
              </Col>
            </Row>
          </Wrapper>
          {toastType !== "" && (
            <ToastContainer
              containerPosition="sticky"
              position="top-end"
              style={{
                position: "fixed !important",
                bottom: "40%",
                right: 0,
                zIndex: "2000",
                float: "right",
                textAlign: "left",
              }}
            >
              <Toast
                delay={7000}
                animation
                transition={Collapse}
                bg={toastType}
                style={{
                  borderRadius: "4px",
                  fontFamily: "Din",
                  padding: "0 30px",
                }}
              >
                <Toast.Body className={"text-white"}>{message}</Toast.Body>
              </Toast>
            </ToastContainer>
          )}
        </Body>
      </Layout>
    </>
  );
};

const Body = styled.div`
  padding: 3vw 3vw 0 3vw;
  position: relative;
`;
const Wrapper = styled.div`
  background-color: white;
  padding: 20px 25px;
`;
const Row = styled.div`
  display: flex;
  justify-content: space-between;
  @media (${QUERIES.mobileAndDown}) {
    flex-direction: column-reverse;
  }
`;
const Col = styled.div`
  flex: 1;
`;
const Divider = styled.div`
  width: 1px;
  background-color: ${COLORS.gray700};
  margin: 0 20px;
`;
const Empty = styled.p`
  color: ${COLORS.gray400};
  font-family: "SF-display";
  font-weight: 400;
  font-size: 0.8125rem;
  text-align: center;
  align-self: center;
`;
const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(160px, 1fr));
  grid-gap: 12px;
  height: 75vh;
  overflow: auto;
  padding: 30px 8px;
  scroll-behavior: smooth;
  &::-webkit-scrollbar-thumb {
    background-color: ${COLORS.primary};
    border-radius: 6px;
  }
  &::-webkit-scrollbar {
    width: 8px;
    background: ${COLORS.gray800};
    border-radius: 6px;
  }
`;

const Title = styled.p`
  font-family: "SF-display";
  font-size: 0.875rem;
  font-weight: 700;
  text-align: left;
  margin: 18px 0 0 0;
  padding-bottom: 4px;
  display: flex;
  align-items: center;
`;

export default NewsScreen;
