import styled from "styled-components";
import React from "react";
import { COLORS } from "../contants/colors";
import { QUERIES } from "../contants/allConstants";
import GreenButton from "./GreenBtn";
import WhiteBtn from "./WhiteBtn";
import { useLanguageContext } from "../languageContext";

const ConfirmationPopup = ({
  title,
  description,
  loading,
  onConfirm,
  onCancel,
}) => {
  const { t } = useLanguageContext();

  return (
    <Container>
      <Wrapper>
        <Head>
          <div style={{ textAlign: "center" }}>
            <Icon
              src={require("../images/whiteClose.svg").default}
              alt=""
              onClick={onCancel}
            />
            <Title>{title}</Title>
          </div>
        </Head>
        <Description>
          <Name>{description}</Name>
          <Row>
            <GreenButton
              size={"small"}
              title={t("confirm")}
              onClick={() => onConfirm()}
              loading={loading}
            />
            <WhiteBtn
              size={"small"}
              disabled={loading}
              title={t("cancel")}
              onClick={() => onCancel()}
            />
          </Row>
        </Description>
      </Wrapper>
    </Container>
  );
};
const Container = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 100%;
`;
const Wrapper = styled.div`
  position: sticky;
  top: 40%;
  margin: 0 auto;
  z-index: 20000;
  background-color: white;
  border-radius: 8px;
  max-height: 60vh;
  overflow-y: auto;
  min-width: 200px;
  max-width: max-content;
  color: ${COLORS.black27};
  font-family: "SF-display";
  font-weight: 400;
  font-size: 0.8125rem;
  box-shadow: 0 10px 30px rgba(57, 57, 57, 0.2),
    0 50px 10px 100vh rgba(57, 57, 57, 0.15);
`;

const Row = styled.div`
  display: flex;
  padding: 0;
  align-items: center;
  justify-content: space-between;
`;

const Title = styled.span`
  color: white;
  font-family: "SF-display";
  font-size: 1rem;
  font-weight: 600;
  text-align: center;
  margin: 0;
  padding-top: 6px;
  vertical-align: middle;
  display: inline-block;
`;
const Head = styled.div`
  width: 100%;
  height: 38px;
  background-color: ${COLORS.primary};
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
`;
const Icon = styled.img`
  width: 10px;
  height: 10px;
  object-fit: fill;
  cursor: pointer;
  margin: 12px 16px 0 0;
  float: right;
`;
const Name = styled.p`
  font-weight: 700;
  font-size: 0.85rem;
  margin: 0;
  padding-bottom: 3px;
`;
const Description = styled.div`
  padding: 18px 40px 25px 40px;
`;

export default ConfirmationPopup;
