import styled from "styled-components";
import React, { useState } from "react";
import Table from "react-bootstrap/Table";
import { COLORS } from "../contants/colors";
import Checkbox from "react-custom-checkbox";
import * as Icon from "react-icons/fi";
import { PAGINATION_LIMIT, QUERIES } from "../contants/allConstants";
import EditButton from "./EditButton";
import RemoveButton from "./RemoveButton";
import MoreUser from "./MoreUser";
import OwnersSelection from "./OwnersSelection";
import { useLanguageContext } from "../languageContext";

const ResponsiveTable = ({
  users,
  openDetails,
  checkedList,
  setCheckedList,
  hideCheckbox = false,
  hideStatus = false,
  isSuperisors = false,
  isTeams = false,
  hideCountry = false,
  onEditTeam,
  showTeamDetails = false,
  onRemoveOwner,
  onBlockUser,
  showMoreBtn = false,
}) => {
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [openTeamsList, setOpenTeamsList] = useState(false);
  const [data, setData] = useState([]);
  const [selectedToManage, setSelectedToManage] = React.useState(null);
  const { t } = useLanguageContext();

  const handleClick = (event, id) => {
    setAnchorEl(event.currentTarget);
    setOpen(true);
    setSelectedToManage(id);
  };

  const renderTeams = (user) => {
    var data = [];
    if (user.defaultBelonging) data = [...data, ...user.defaultBelonging];
    if (user.sportBelonging) data = [...data, ...user.sportBelonging];
    if (user.politicBelonging) data = [...data, ...user.politicBelonging];
    return (
      <div>
        {data.length < 3 ? (
          data.map((team) => (
            <p style={{ margin: "0 !important", padding: "0 !important" }}>
              {team.name}
            </p>
          ))
        ) : (
          <div>
            <p style={{ marginBottom: "0 !important" }}>
              {data[0].name}
              {" , " + data[1].name}
              {/* <br /> */}
              {/* <a
                style={{ color: "#56ca5c", fontWeight: 700 }}
                href="#"
                onClick={() => {
                  setData(data);
                  setOpenTeamsList(true);
                }}
              >
                عرض المزيد...
              </a> */}
            </p>
          </div>
        )}
      </div>
    );
  };
  return (
    <Wrapper>
      {openTeamsList && (
        <ContainerList>
          <WrapperList>
            <HeadList>
              <div style={{ textAlign: "center" }}>
                <IconList
                  src={require("../images/whiteClose.svg").default}
                  alt=""
                  onClick={() => setOpenTeamsList(false)}
                />
                <TitleList>{t("country")}</TitleList>
              </div>
            </HeadList>
            <div style={{ paddingBottom: "20px" }}>
              <OwnersSelection
                showRadioBtn={false}
                data={data}
                title={""}
                onClick={(id, event) => setOpenTeamsList(false)}
              />
            </div>
          </WrapperList>
        </ContainerList>
      )}
      <Table
        responsive={"xl"}
        paging={true}
        style={{
          borderRadius: "8px",
          marginBottom: "0",
          width: "100%",
        }}
      >
        <Thead showTeamDetails={showTeamDetails}>
          <tr>
            {!hideCheckbox && (
              <CheckboxTH>
                <Checkbox
                  icon={<Icon.FiCheck color="white" size={18} />}
                  checked={checkedList.length === users.length}
                  onChange={(value, event) => {
                    const newList = value
                      ? [...users.map((u) => u["_id"])]
                      : [];
                    setCheckedList(newList);
                  }}
                  borderColor="white"
                  style={{
                    cursor: "pointer",
                  }}
                  borderRadius={3}
                />
              </CheckboxTH>
            )}
            {isTeams && <TH showTeamDetails={showTeamDetails}>{t("id")}</TH>}

            <TH showTeamDetails={showTeamDetails}>{t("name")}</TH>
            {!hideCountry && (
              <TH showTeamDetails={showTeamDetails}>
                {isTeams ? t("country") : t("familyName")}
                {/* {isTeams ? "الفئة" : t("familyName")} */}
              </TH>
            )}
            <TH showTeamDetails={showTeamDetails}>
              {isTeams ? t("subAdmin") : t("email")}
            </TH>
            {(!isSuperisors || isTeams) && (
              <TH showTeamDetails={showTeamDetails}>
                {isTeams ? t("supervisors") : t("phone")}
              </TH>
            )}
            {isTeams && (
              <TH showTeamDetails={showTeamDetails}>{t("journalist")}</TH>
            )}
            {/* {!hideCheckbox && ( */}
            <TH showTeamDetails={showTeamDetails}>
              {isSuperisors ? t("role") : isTeams ? "" : t("country")}
            </TH>
            {/* )} */}
            {isSuperisors && (
              <TH showTeamDetails={showTeamDetails}>
                {showTeamDetails ? t("country") : t("team")}
              </TH>
            )}
            {/* {((!isSuperisors && showTeamDetails) ||
              (isSuperisors && !showTeamDetails)) && (
              <TH showTeamDetails={showTeamDetails}>
                {t("category")}
              </TH>
            )} */}
            {!hideStatus && (
              <TH showTeamDetails={showTeamDetails}>{t("status")}</TH>
            )}

            {showMoreBtn && <TH showTeamDetails={showTeamDetails}></TH>}
          </tr>
        </Thead>
        <tbody>
          {users.map((user) => (
            <TR>
              {!hideCheckbox && (
                <TD>
                  <Checkbox
                    icon={<Icon.FiCheck color={COLORS.primary} size={18} />}
                    checked={checkedList.indexOf(user["_id"]) > -1}
                    onChange={(value, event) => {
                      const result = checkedList.filter(
                        (id) => id !== user["_id"]
                      );
                      const newList = value
                        ? [...checkedList, user["_id"]]
                        : [...result];

                      setCheckedList(newList);
                    }}
                    borderColor={
                      checkedList.indexOf(user["_id"]) > -1
                        ? COLORS.primary
                        : COLORS.gray300
                    }
                    style={{
                      cursor: "pointer",
                    }}
                    borderRadius={3}
                  />
                </TD>
              )}
              {isTeams && (
                <TD
                  onClick={() => {
                    openDetails(user);
                  }}
                >
                  {user["key"]}
                </TD>
              )}
              <TD
                onClick={() => {
                  openDetails(user);
                }}
              >
                {user["name"]}
              </TD>

              {!hideCountry && (
                <TD
                  onClick={() => {
                    openDetails(user);
                  }}
                >
                  {isTeams ? user["country"] : user["familyName"]}
                  {/* {isTeams ? user["category"] : user["familyName"]} */}
                </TD>
              )}
              <TD
                onClick={() => {
                  openDetails(user);
                }}
              >
                {isTeams ? (
                  <div>
                    {user["owners"].filter((o) => o.isSubAdmin === true)
                      .length > 0
                      ? user["owners"]
                          .filter((o) => o.isSubAdmin === true)
                          .map((u) => <div> {u.name + " " + u.familyName}</div>)
                      : "---"}
                  </div>
                ) : (
                  user["email"]
                )}
              </TD>
              {(isTeams || !isSuperisors) && (
                <TD
                  onClick={() => {
                    openDetails(user);
                  }}
                  dir="ltr"
                >
                  {isTeams
                    ? user["owners"].filter((o) => o.isSupervisor === true)
                        .length > 0
                      ? user["owners"]
                          .filter((o) => o.isSupervisor === true)
                          .map((u) => <div> {u.name + " " + u.familyName}</div>)
                      : "---"
                    : user["phone"]}
                </TD>
              )}

              {isTeams && !isSuperisors && (
                <TD
                  onClick={() => {
                    openDetails(user);
                  }}
                  dir="ltr"
                >
                  {isTeams
                    ? user["owners"].filter((o) => o.isJournalist === true)
                        .length > 0
                      ? user["owners"]
                          .filter((o) => o.isJournalist === true)
                          .map((u) => <div> {u.name + " " + u.familyName}</div>)
                      : "---"
                    : user["phone"]}
                </TD>
              )}
              {/* {!hideCheckbox && ( */}
              <TD dir="ltr">
                {isTeams ? (
                  <EditButton onClick={() => onEditTeam(user)} />
                ) : isSuperisors ? (
                  user.isSupervisor ? (
                    t("supervisor")
                  ) : user.isJournalist ? (
                    t("journalist")
                  ) : (
                    t("moderator")
                  )
                ) : user.belonging === "مواطن حر" ? (
                  "مواطن حر"
                ) : user.sportBelonging ||
                  user.defaultBelonging ||
                  user.politicBelonging ? (
                  renderTeams(user)
                ) : (
                  "مواطن حر"
                )}
              </TD>
              {isSuperisors && (
                <TD
                  onClick={() => {
                    openDetails(user);
                  }}
                >
                  {showTeamDetails
                    ? user.country
                    : user.teamName
                    ? user.teamName
                    : "--"}
                </TD>
              )}

              {/* {isSuperisors && !showTeamDetails && (
                <TD
                  onClick={() => {
                    openDetails(user);
                  }}
                >
                  {showTeamDetails
                    ? user.state
                    : user.teamType
                    ? user.teamType
                    : "--"}
                </TD>
              )} */}
              {!hideStatus && (
                <CenteredTD
                  onClick={() => {
                    openDetails(user);
                  }}
                >
                  <Tag
                    onClick={() => {
                      openDetails(user);
                    }}
                    active={
                      isSuperisors
                        ? !user.isBlocked
                        : !user.isBlocked &&
                          user["status"] === "active" &&
                          Math.ceil(
                            Math.abs(
                              new Date() - new Date(user["paymentDate"])
                            ) /
                              (1000 * 60 * 60 * 24)
                          ) <= 365
                    }
                  >
                    {isSuperisors
                      ? user.isBlocked
                        ? t("blocked")
                        : t("unblocked")
                      : user.isBlocked
                      ? t("blocked")
                      : user["status"] === "active" &&
                        Math.ceil(
                          Math.abs(new Date() - new Date(user["paymentDate"])) /
                            (1000 * 60 * 60 * 24)
                        ) <= 365
                      ? t("active")
                      : t("suspended")}
                  </Tag>
                </CenteredTD>
              )}

              {showMoreBtn && (
                <TD>
                  <div style={{ width: "10px" }}>
                    <MoreUser
                      id={user["_id"]}
                      onRemove={() => {
                        onRemoveOwner(
                          selectedToManage
                            ? selectedToManage["_id"]
                            : user["_id"]
                        );
                      }}
                      isBlocked={
                        selectedToManage
                          ? selectedToManage["isBlocked"]
                          : user["isBlocked"]
                      }
                      onBlock={() => {
                        onBlockUser(
                          selectedToManage["_id"],
                          !selectedToManage["isBlocked"]
                        );
                      }}
                      showRemove={showTeamDetails}
                      handleClick={(e) => handleClick(e, user)}
                      open={open}
                      setOpen={setOpen}
                      anchorEl={anchorEl}
                      top={8}
                      left={0}
                    />
                  </div>

                  {/* <RemoveButton
                    onClick={() => console.log("remove====", user["_id"])}
                  /> */}
                </TD>
              )}
            </TR>
          ))}
        </tbody>
      </Table>
    </Wrapper>
  );
};
const Wrapper = styled.div`
  padding: 0;
  background-color: ${COLORS.background};
  margin: 13px 0;
  /* position: relative; */
  /* min-height: 520px; */
`;
const Thead = styled.thead`
  font-size: 0.92rem !important;
  color: white;
  background-color: ${(p) =>
    p.showTeamDetails ? "#c7c7c7 !important" : COLORS.primary};
  @media (${QUERIES.mobileAndDown}) {
    font-size: 0.85rem !important;
  }
`;
const TR = styled.tr`
  background-color: white;
  cursor: pointer;
  &:active {
    background-color: #fafafa;
  }
`;
const TH = styled.th`
  font-family: "SF-display";
  font-size: ${(p) => (p.showTeamDetails ? 0.9 : 0.92)}rem !important;
  font-weight: ${(p) => (p.showTeamDetails ? 500 : 600)};
  text-align: center;
  @media (${QUERIES.mobileAndDown}) {
    font-size: 0.85rem !important;
    padding: 10px !important;
  }
`;
const CheckboxTH = styled(TH)`
  width: 0;
`;
const TD = styled.td`
  font-family: "SF-display";
  font-size: 0.8rem !important;
  font-weight: 400;
  text-align: left;
  padding: 13px !important;
  position: relative;
  max-width: 160px;
  overflow-wrap: break-word;
  /* text-align: center; */

  @media (${QUERIES.mobileAndDown}) {
    font-size: 0.75rem !important;
    padding: 15px 10px !important;
  }
`;

const CenteredTD = styled(TD)`
  text-align: center;
`;

const Tag = styled.span`
  font-family: "SF-display";
  font-size: 0.75rem !important;
  font-weight: 600;
  color: ${(p) => (p.active ? COLORS.success : COLORS.error)};
  background-color: ${(p) => (p.active ? COLORS.green15 : COLORS.red15)};
  padding: 4px 18px;
  border-radius: 4px;
  white-space: nowrap;
  @media (${QUERIES.mobileAndDown}) {
    font-size: 0.7rem !important;
  }
`;

const ContainerList = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 100%;
`;
const WrapperList = styled.div`
  position: sticky;
  background-color: red;
  top: 24%;
  margin: 0 auto;
  z-index: 20000;
  background-color: white;
  border-radius: 8px;
  max-height: 75vh;
  width: 300px;
  color: ${COLORS.black27};
  font-family: "SF-display";
  font-weight: 400;
  font-size: 0.8125rem;
  box-shadow: 0 10px 30px rgba(57, 57, 57, 0.2),
    0 50px 10px 100vh rgba(57, 57, 57, 0.15);
`;
const HeadList = styled.div`
  width: 100%;
  height: 38px;
  background-color: ${COLORS.primary};
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
`;
const IconList = styled.img`
  width: 10px;
  height: 10px;
  object-fit: fill;
  cursor: pointer;
  margin: 12px 16px 0 0;
  float: left;
`;
const TitleList = styled.span`
  color: white;
  font-family: "SF-display";
  font-size: 0.92rem !important;
  font-weight: 600;
  text-align: center;
  margin: 0;
  padding-top: 6px;
  vertical-align: middle;
  display: inline-block;
`;

export default ResponsiveTable;
