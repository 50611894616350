import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Header from "../components/Header";
import Layout from "../components/Layout";
import styled from "styled-components";
import Drawer from "../components/Drawer";
import { useState } from "react";
import Miniheader from "../components/Miniheader";
import TextField from "../components/TextField";
import FileField from "../components/FileField";
import { COLORS } from "../contants/colors";
import GreenButton from "../components/GreenBtn";
import WhiteBtn from "../components/WhiteBtn";
import Dropdown from "../components/Dropdown";
import { QUERIES } from "../contants/allConstants";
import { createSupervisor } from "../actions/userActions";
import ToastContainer from "react-bootstrap/esm/ToastContainer";
import Collapse from "react-bootstrap/Collapse";
import Toast from "react-bootstrap/Toast";
import { createTeam } from "../actions/teamActions";
import { useLanguageContext } from "../languageContext";

const NewTeamScreen = ({ history }) => {
  const dispatch = useDispatch();
  const { userInfo } = useSelector((state) => state.userLogin);
  const { supervisor, error, loading } = useSelector((state) => state.team);
  const [selected, setSelected] = useState(9);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [teamName, setTeamName] = useState("");
  const [teamType, setTeamType] = useState("");
  const [message, setMessage] = useState("");
  const [toastType, setToastType] = useState("");
  const [file, setFile] = useState(null);
  const { t } = useLanguageContext();

  const onCreateTeam = () => {
    if (teamName !== "" && teamType !== "" && file !== null) {
      dispatch(
        createTeam(userInfo["token"], teamName, teamType, file, () => {
          setMessage(t("addSuccessT"));
          setToastType("success");
          setTeamName("");
          setTeamType("");
          setFile(null);
        })
      );
    } else {
      setMessage(t("fillout"));
      setToastType("danger");
    }
  };

  const onCancel = () => {
    setTeamName("");
    setTeamType("");
    setFile(null);
  };
  useEffect(() => {
    if (error) {
      setMessage(error !== "" ? error : t("mainError"));
      setToastType("danger");
    }
  }, [error]);

  useEffect(() => {
    setTimeout(() => {
      setToastType("");
    }, 8000);
  }, [toastType]);
  return (
    <Layout>
      <Header setOpenDrawer={() => setOpenDrawer(!openDrawer)} />
      <Drawer
        setOpenDrawer={() => setOpenDrawer(!openDrawer)}
        open={openDrawer}
        history={history}
        selected={selected}
        onChange={(value) => setSelected(value)}
      />
      <Body>
        <Miniheader title={"اضافة جمعية"} />
        <Wrapper>
          <Row>
            <Col>
              <TextField
                title={"اسم الجمعية"}
                hint={"الرجاء كتابة اسم الجمعية"}
                value={teamName}
                onChange={(value) => setTeamName(value)}
              />
              {/* <Dropdown
                title={"نوع الجمعية"}
                items={["حزب سياسي", "جمعية رياضية", "جمعية مدنية"]}
                value={teamType}
                onChange={(value) => setTeamType(value)}
              /> */}
              <FileField
                type={"image/png, image/jpeg, image/jpg"}
                title={"اضافة صورة الجمعية"}
                setFile={(value) => setFile(value)}
                file={file}
              />

              <Row>
                <GreenButton
                  title={t("send")}
                  onClick={() => onCreateTeam()}
                  loading={loading}
                />
                <WhiteBtn title={t("cancel")} onClick={() => onCancel()} />
              </Row>
            </Col>
          </Row>
        </Wrapper>
        {toastType !== "" && (
          <ToastContainer
            containerPosition="sticky"
            position="top-end"
            style={{
              position: "fixed !important",
              bottom: "40%",
              right: 0,
              zIndex: "2000",
              float: "right",
              textAlign: "left",
            }}
          >
            <Toast
              delay={7000}
              animation
              transition={Collapse}
              bg={toastType}
              style={{
                borderRadius: "4px",
                fontFamily: "Din",
                padding: "0 30px",
              }}
            >
              <Toast.Body className={"text-white"}>{message}</Toast.Body>
            </Toast>
          </ToastContainer>
        )}
      </Body>
    </Layout>
  );
};

const Body = styled.div`
  padding: 3vw 4vw;
  position: relative;
`;
const Wrapper = styled.div`
  background-color: white;
  padding: 20px 40px;
`;
const Row = styled.div`
  display: flex;
  justify-content: space-between;
  @media (${QUERIES.mobileAndDown}) {
    flex-direction: column-reverse;
  }
`;
const Col = styled.div`
  flex: 1;
`;
const Divider = styled.div`
  width: 1px;
  background-color: ${COLORS.gray700};
  margin: 0 20px;
`;

export default NewTeamScreen;
