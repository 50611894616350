import styled from "styled-components";
import React from "react";
import { COLORS } from "../contants/colors";

const TextField = ({
  style = { position: "relative" },
  inputStyle = { position: "relative" },
  title,
  value,
  hint,
  children,
  onChange,
}) => {
  return (
    <Wrapper>
      <Title>{title}</Title>
      <div style={style}>
        <Input
          rows="5"
          placeholder={hint}
          value={value}
          onChange={(e) => onChange(e.target.value)}
          style={inputStyle}
        />
        {children}
      </div>
    </Wrapper>
  );
};
const Wrapper = styled.div`
  color: ${COLORS.black56};
  margin-top: 10px;
  width: 100%;
`;
const Title = styled.p`
  font-family: "SF-display";
  font-size: 0.875rem;
  font-weight: 700;
  text-align: left;
  margin: 0;
  padding-bottom: 7px;
`;
const Input = styled.input`
  font-family: "IBM Plex Sans Arabic";
  width: 100%;
  border: 1px solid ${COLORS.gray100};
  border-radius: 4px;
  font-size: 0.875rem;
  font-weight: 300;
  padding: 10px;
  text-align: left;
  /* padding-left: 90px; */

  &::placeholder {
    color: ${COLORS.gray400};
  }
  &:focus {
    outline-color: ${COLORS.lightBlue};
  }
`;

export default TextField;
