import {
  QUESTION_LIST_REQUEST,
  QUESTION_LIST_SUCCESS,
  QUESTION_LIST_FAIL,
  DELETE_QUESTION_FAIL,
  DELETE_QUESTION_SUCCESS,
  QUESTION_CREATE_FAIL,
  QUESTION_CREATE_SUCCESS,
  QUESTION_CREATE_REQUEST,
  USER_LOGOUT,
} from "../contants/allConstants";

export const questionListReducer = (
  state = { questions: [], loading: false, error: false },
  action
) => {
  switch (action.type) {
    case QUESTION_LIST_REQUEST:
      return {
        ...state,
        loading: true,
        questions: state.questions,
        error: false,
      };
    case QUESTION_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        questions: action.payload,
        success: true,
        error: false,
      };
    case QUESTION_LIST_FAIL:
      return {
        ...state,
        loading: false,
        success: true,
        questions: state.questions,
      };
    case QUESTION_LIST_FAIL || DELETE_QUESTION_FAIL:
      return { ...state, loading: false, error: action.payload };
    case DELETE_QUESTION_SUCCESS:
      const new_questions = state.questions.filter(
        (q) => q["_id"] !== action.payload
      );
      return {
        ...state,
        loading: false,
        success: true,
        questions: new_questions,
        error: false,
      };
    case QUESTION_CREATE_REQUEST:
      return {
        ...state,
        loading: true,
        error: false,
      };
    case QUESTION_CREATE_SUCCESS:
      return {
        ...state,
        loading: false,
        questions: action.payload,
        success: true,
        error: false,
      };
    case QUESTION_CREATE_FAIL:
      return {
        ...state,
        loading: false,
        success: false,
        error: true,
      };
    case USER_LOGOUT:
      return {
        loading: false,
        success: false,
        error: false,
        questions: [],
      };
    default:
      return state;
  }
};
