import styled from "styled-components";
import React from "react";
import { COLORS } from "../contants/colors";

const TextArea = ({ value, title, hint, onChange }) => {
  return (
    <Wrapper>
      <Title>{title}</Title>
      <Input
        rows="4"
        placeholder={hint}
        value={value}
        onChange={(e) => onChange(e.target.value)}
      />
    </Wrapper>
  );
};
const Wrapper = styled.div`
  color: ${COLORS.black56};
  margin-top: 10px;
`;
const Title = styled.p`
  font-family: "SF-display";
  font-size: 0.875rem;
  font-weight: 700;
  text-align: left;
  margin: 0;
  padding-bottom: 5px;
`;
const Input = styled.textarea`
  font-family: "IBM Plex Sans Arabic";
  width: 100%;
  border: 1px solid ${COLORS.gray100};
  border-radius: 4px;
  font-size: 0.875rem;
  font-weight: 300;
  padding: 10px;
  resize: none;
  &::placeholder {
    color: ${COLORS.gray400};
  }
  &:focus {
    outline-color: ${COLORS.lightBlue};
  }
`;

export default TextArea;
