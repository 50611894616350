import {
  USER_LOGIN_FAIL,
  USER_LOGIN_REQUEST,
  USER_LOGIN_SUCCESS,
  USER_LOGOUT,
  MOBILE_USERS_LIST_REQUEST,
  MOBILE_USERS_LIST_SUCCESS,
  MOBILE_USERS_LIST_FAIL,
  MOBILE_USERS_CREATE_FAIL,
  MOBILE_USERS_CREATE_REQUEST,
  MOBILE_USERS_CREATE_SUCCESS,
  DELETE_USER_SUCCESS,
  DELETE_USER_FAIL,
  DELETE_USER_REQUEST,
  SET_USERS_BACK_DATA,
  SUPERVISOR_CREATE_SUCCESS,
  SUPERVISOR_CREATE_REQUEST,
  SUPERVISOR_CREATE_FAIL,
  SUPERVISORS_LIST_FAIL,
  SUPERVISORS_LIST_REQUEST,
  SUPERVISORS_LIST_SUCCESS,
  UPDATE_USER_TEAM,
  SUPERVISORS_DELETE_REQUEST,
  SUPERVISORS_DELETE_SUCCESS,
  AVAIL_SUPRVISERS_LIST_SUCCESS,
  AVAIL_SUPRVISERS_LIST_REQUEST,
  AVAIL_SUPRVISERS_LIST_FAIL,
  AVAIL_SUBADMINS_LIST_REQUEST,
  AVAIL_SUBADMINS_LIST_SUCCESS,
  AVAIL_SUBADMINS_LIST_FAIL,
  BLOCK_USER_REQUEST,
  BLOCK_USER_SUCCESS,
  BLOCK_USER_FAIL,
  UPDATE_PASSWORD_SUCCESS,
} from "../contants/allConstants";

export const userLoginReducer = (state = {}, action) => {
  switch (action.type) {
    case USER_LOGIN_REQUEST:
      return { ...state, loading: true, error: "" };
    case USER_LOGIN_SUCCESS:
      return {
        ...state,
        loading: false,
        userInfo: action.payload,
        success: true,
      };
    case UPDATE_PASSWORD_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
      };
    case UPDATE_USER_TEAM:
      return {
        ...state,
        loading: false,
        userInfo: { ...state.userInfo, team: action.payload },
        success: true,
      };
    case USER_LOGIN_FAIL:
      return { ...state, loading: false, error: action.payload };
    case USER_LOGOUT:
      return { userInfo: null };
    default:
      return state;
  }
};

export const mobileUsersListReducer = (
  state = {
    mobile_users: [],
    count: 0,
    usersBackData: [],
    supervisors: [],
    success_delete: false,
    load_block: false,
    error: false,
    success: false,
    loading: false,
  },
  action
) => {
  switch (action.type) {
    case BLOCK_USER_REQUEST:
      return {
        ...state,
        load_block: true,
        error: false,
      };
    case BLOCK_USER_SUCCESS:
      return {
        ...state,
        load_block: false,
        success: true,
      };
    case BLOCK_USER_FAIL:
      return {
        ...state,
        load_block: false,
        success: false,
        error: action.payload,
      };
    case MOBILE_USERS_LIST_REQUEST ||
      MOBILE_USERS_CREATE_REQUEST ||
      SUPERVISORS_LIST_REQUEST:
      return {
        ...state,
        loading: true,
        mobile_users: state.mobile_users,
        count: 0,
        countSupr: 0,
        success_delete: false,
      };
    case MOBILE_USERS_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        mobile_users: action.payload.results,
        count: action.payload.count,
        success: true,
        success_delete: false,
      };
    case MOBILE_USERS_CREATE_SUCCESS:
      state.mobile_users.push(action.payload);
      return {
        ...state,
        loading: false,
        success: true,
        mobile_users: state.mobile_users,
        success_delete: false,
      };
    case MOBILE_USERS_LIST_FAIL ||
      DELETE_USER_FAIL ||
      SUPERVISORS_LIST_FAIL ||
      SUPERVISORS_DELETE_REQUEST:
      return { ...state, loading: false, error: action.payload };
    case DELETE_USER_REQUEST || SUPERVISORS_DELETE_REQUEST:
      return {
        ...state,
        success_delete: false,
        mobile_users: state.mobile_users,
      };
    case SET_USERS_BACK_DATA:
      return {
        ...state,
        usersBackData: action.payload,
      };
    case DELETE_USER_SUCCESS:
      const new_users = state.mobile_users.filter(
        (user) => action.payload.indexOf(user["_id"]) === -1
      );

      return {
        ...state,
        loading: false,
        success: true,
        success_delete: true,
        mobile_users: new_users,
        count: new_users.length,
      };
    case SUPERVISORS_DELETE_SUCCESS:
      const new_supervisors = state.supervisors.filter(
        (user) => action.payload.indexOf(user["_id"]) === -1
      );
      console.log("====new_supervisors", new_supervisors);

      return {
        ...state,
        loading: false,
        success: true,
        success_delete: true,
        supervisors: new_supervisors,
        countSupr: new_supervisors.length,
      };
    case SUPERVISORS_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        supervisors: action.payload.results,
        countSupr: action.payload.count,
        success: true,
        success_delete: false,
      };
    default:
      return state;
  }
};

export const supervisorReducer = (
  state = {
    supervisor: [],
    available_suprv: [],
    load_availability: false,
    available_subadmins: [],
    error: false,
    loading: false,
    load_block: false,
  },
  action
) => {
  switch (action.type) {
    case BLOCK_USER_REQUEST:
      return {
        ...state,
        load_block: true,
        error: false,
      };
    case BLOCK_USER_SUCCESS:
      return {
        ...state,
        load_block: false,
        success: true,
        error: false,
      };
    case BLOCK_USER_FAIL:
      return {
        ...state,
        load_block: false,
        success: false,
        error: action.payload,
      };
    case AVAIL_SUBADMINS_LIST_REQUEST:
      return {
        ...state,
        load_availability: true,
        error: false,
      };
    case AVAIL_SUBADMINS_LIST_SUCCESS:
      return {
        ...state,
        load_availability: false,
        available_subadmins: action.payload,
        success: true,
      };
    case AVAIL_SUBADMINS_LIST_FAIL:
      return {
        ...state,
        load_availability: false,
        success: false,
        error: action.payload,
      };
    case AVAIL_SUPRVISERS_LIST_REQUEST:
      return {
        ...state,
        load_availability: true,
        error: false,
      };
    case AVAIL_SUPRVISERS_LIST_SUCCESS:
      return {
        ...state,
        load_availability: false,
        available_suprv: action.payload,
        success: true,
        error: false,
      };
    case AVAIL_SUPRVISERS_LIST_FAIL:
      return {
        ...state,
        load_availability: false,
        success: false,
        error: action.payload,
      };
    case SUPERVISOR_CREATE_REQUEST:
      return {
        ...state,
        loading: true,
        error: false,
      };
    case SUPERVISOR_CREATE_SUCCESS:
      return {
        ...state,
        loading: false,
        supervisor: action.payload,
        success: true,
        error: false,
      };
    case SUPERVISOR_CREATE_FAIL:
      return {
        ...state,
        loading: false,
        success: false,
        error: action.payload,
      };
    default:
      return state;
  }
};
