import styled from "styled-components";
import React, { useState, useRef, useEffect } from "react";
import { COLORS } from "../contants/colors";
import background from "../images/bgheader.svg";
import Notification from "./Notification";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";
import "moment/locale/ar";
import { openNotifications } from "../actions/notificationActions";
import MenuIcon from "@material-ui/icons/Menu";
import { QUERIES, numberMap, symbolMap } from "../contants/allConstants";
import Spinner from "react-bootstrap/Spinner";
import { useLanguageContext } from "../languageContext";

const Header = ({ openDrawer, setOpenDrawer }) => {
  const { userInfo } = useSelector((state) => state.userLogin);
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();
  const { notifications, count_notif, loading } = useSelector(
    (state) => state.notification
  );
  const { t } = useLanguageContext();

  const myRef = useRef();

  const handleClickOutside = (e) => {
    if (myRef.current) {
      if (!myRef.current.contains(e.target) && open) {
        setOpen(false);
        dispatch(openNotifications(userInfo["token"]));
      }
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  });
  return (
    <Wrapper img={background}>
      <div>
        <Menu onClick={setOpenDrawer}>
          <MenuIcon style={{ color: "white" }} fontSize="medium" />
        </Menu>
        <Logo src={require("../images/logo2.svg").default} alt="" />
      </div>
      <Icons>
        <Avatar
          src={
            userInfo["image"] !== ""
              ? userInfo["image"]
              : require("../images/avatar.svg").default
          }
          alt=""
        />
        <Bell ref={myRef}>
          <BellIcon
            src={require("../images/bell2.svg").default}
            alt=""
            onClick={() => {
              if (open) {
                dispatch(openNotifications(userInfo["token"]));
              }
              setOpen(!open);
            }}
          />
          {count_notif > 0 && (
            <Number>
              <span>{count_notif > 99 ? "+99" : count_notif}</span>
            </Number>
          )}
          {open && (
            <div>
              <Arrow />
              <Notifications>
                {loading ? (
                  <Spinner animation="border" variant="primary" />
                ) : notifications.length > 0 ? (
                  notifications.map(
                    (notif) =>
                      notif["user"] && (
                        <Notification
                          background={
                            notif["openedBy"].includes(userInfo["_id"])
                              ? "white"
                              : COLORS.gray800
                          }
                          photo={notif["user"]["image"]}
                          username={
                            notif["user"]["name"] +
                            " " +
                            notif["user"]["familyName"]
                          }
                          description={notif["description"]}
                          time={moment
                            .utc(notif["createdAt"])
                            .local()
                            .startOf("seconds")
                            .fromNow()}
                        />
                      )
                  )
                ) : (
                  <p>{t("noAlerts")}</p>
                )}
              </Notifications>
            </div>
          )}
        </Bell>
      </Icons>
    </Wrapper>
  );
};
const Wrapper = styled.div`
  grid-area: header;
  width: 100%;
  height: 60px;
  display: block;
  position: relative;
  background-color: ${COLORS.darkBlue};
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 40px;
  box-shadow: 0px 10px 60px rgba(0, 0, 0, 0.05);
  @media (${QUERIES.tabletAndDown}) {
    padding: 0 40px;
  }
  @media (${QUERIES.mobileAndDown}) {
    padding: 0 25px;
  }
`;
const Menu = styled.div`
  display: none;
  @media (${QUERIES.tabletAndDown}) {
    display: initial;
    margin-right: 20px;
    cursor: pointer;
  }
`;
const Icons = styled.div`
  display: flex;
  align-items: center;
`;
const Avatar = styled.img`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  object-fit: cover;
  margin-right: 25px;
  @media (${QUERIES.mobileAndDown}) {
    width: 38px;
    height: 38px;
    margin-right: 15px;
  }
`;
const Logo = styled.img`
  height: 50px;
  margin-top: 6px;
  @media (${QUERIES.mobileAndDown}) {
    height: 35px;
  }
`;
const Bell = styled.div`
  position: relative;
  cursor: pointer;
`;
const BellIcon = styled.img`
  width: 20px;
  @media (${QUERIES.mobileAndDown}) {
    width: 17px;
  }
`;
const Notifications = styled.div`
  position: absolute;
  top: 35px;
  right: -20px;
  z-index: 3000;
  background-color: white;
  border-radius: 8px;
  padding: 20px 20px 10px 20px;
  max-height: 60vh;
  overflow-y: auto;
  min-width: 200px;
  color: ${COLORS.black27};
  font-family: "SF-display";
  font-weight: 400;
  font-size: 0.8125rem;
  box-shadow: 0 40px 60px rgba(57, 57, 57, 0.2);
  &::-webkit-scrollbar-thumb {
    background-color: ${COLORS.primary};
    border-radius: 8px;
  }
  &::-webkit-scrollbar {
    width: 6px;
    background: ${COLORS.gray800};
    border-radius: 8px;
  }
`;
const Number = styled.div`
  color: white;
  background-color: ${COLORS.red};
  font-family: "Poppins";
  font-weight: 600;
  font-size: 9.6px;
  border-radius: 50%;
  min-width: 18.5px;
  padding: 2px 3px;
  position: absolute;
  top: 0;
  left: 10px;
  text-align: center;
  cursor: pointer;
`;

const Arrow = styled.span`
  z-index: 1;
  width: 12px;
  height: 12px;
  content: "";
  position: absolute;
  transform: rotate(-135deg);
  background: rgb(255, 255, 255);
  border-radius: 0px 0px 3px;
  top: 30px;
  left: 4px;
  border-bottom: 1px solid rgba(145, 158, 171, 0.12);
  border-right: 1px solid rgba(145, 158, 171, 0.12);
`;
export default Header;
