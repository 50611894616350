import axios from "axios";
import {
  USER_LOGIN_FAIL,
  USER_LOGIN_REQUEST,
  USER_LOGIN_SUCCESS,
  USER_LOGOUT,
  MOBILE_USERS_LIST_REQUEST,
  MOBILE_USERS_LIST_SUCCESS,
  MOBILE_USERS_LIST_FAIL,
  MOBILE_USERS_CREATE_FAIL,
  MOBILE_USERS_CREATE_REQUEST,
  MOBILE_USERS_CREATE_SUCCESS,
  PROXY,
  DELETE_USER_SUCCESS,
  DELETE_USER_FAIL,
  DELETE_USER_REQUEST,
  SET_USERS_BACK_DATA,
  SUPERVISOR_CREATE_REQUEST,
  SUPERVISOR_CREATE_SUCCESS,
  SUPERVISOR_CREATE_FAIL,
  SUPERVISORS_LIST_REQUEST,
  SUPERVISORS_LIST_SUCCESS,
  SUPERVISORS_LIST_FAIL,
  UPDATE_TEAM_SUCCESS,
  SUPERVISORS_DELETE_REQUEST,
  SUPERVISORS_DELETE_SUCCESS,
  SUPERVISORS_DELETE_FAIL,
  AVAIL_SUPRVISERS_LIST_SUCCESS,
  AVAIL_SUPRVISERS_LIST_REQUEST,
  AVAIL_SUPRVISERS_LIST_FAIL,
  AVAIL_SUBADMINS_LIST_REQUEST,
  AVAIL_SUBADMINS_LIST_SUCCESS,
  AVAIL_SUBADMINS_LIST_FAIL,
  BLOCK_USER_REQUEST,
  BLOCK_USER_SUCCESS,
  BLOCK_USER_FAIL,
  UPDATE_PASSWORD_SUCCESS,
} from "../contants/allConstants";

export const login = (email, password, isAdmin) => async (dispatch) => {
  try {
    dispatch({ type: USER_LOGIN_REQUEST });

    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const { data } = await axios.post(
      `${PROXY}/user/loginAdmin`,
      { email, password },
      config
    );
    dispatch({
      type: USER_LOGIN_SUCCESS,
      payload: data,
    });

    if (data.team) {
      dispatch({
        type: UPDATE_TEAM_SUCCESS,
        payload: data.team,
      });
    }

    localStorage.setItem("userInfoAnisso", JSON.stringify(data));
  } catch (error) {
    dispatch({
      type: USER_LOGIN_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const logout = () => async (dispatch) => {
  localStorage.removeItem("userInfoAnisso");
  dispatch({ type: USER_LOGOUT });
};

export const getMobileUsers = (token) => async (dispatch) => {
  try {
    dispatch({ type: MOBILE_USERS_LIST_REQUEST });
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    const { data } = await axios.get(`${PROXY}/user/mobile`, config);
    dispatch({
      type: MOBILE_USERS_LIST_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: MOBILE_USERS_LIST_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const searchUsers =
  (token, keyword = "", state = "", startDate = "", endDate = "") =>
  async (dispatch) => {
    try {
      dispatch({ type: MOBILE_USERS_LIST_REQUEST });
      const config = {
        headers: { Authorization: `Bearer ${token}` },
        params: {
          keyword,
          state,
          startDate,
          endDate,
          isSupervisor: false,
          isSubAdmin: false,
        },
      };
      const { data } = await axios.get(`${PROXY}/user/search`, config);
      dispatch({
        type: MOBILE_USERS_LIST_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: MOBILE_USERS_LIST_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const searchSupervisors =
  (token, keyword, state, startDate, endDate) => async (dispatch) => {
    try {
      dispatch({ type: SUPERVISORS_LIST_REQUEST });
      const config = {
        headers: { Authorization: `Bearer ${token}` },
        params: {
          keyword,
          state,
          startDate,
          endDate,
          isSupervisor: true,
          isSubAdmin: true,
        },
      };
      const { data } = await axios.get(`${PROXY}/user/searchSuprv`, config);
      dispatch({
        type: SUPERVISORS_LIST_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: SUPERVISORS_LIST_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const deleteUser = (token, usersIds) => async (dispatch) => {
  try {
    dispatch({
      type: DELETE_USER_REQUEST,
    });
    console.log("====usersIds", usersIds);
    const config = {
      headers: { Authorization: `Bearer ${token}` },
      data: {
        usersIds,
      },
    };

    const { data } = await axios.delete(`${PROXY}/user`, config);
    dispatch({
      type: DELETE_USER_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: DELETE_USER_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const setUsersBackData = (data) => (dispatch) => {
  dispatch({
    type: SET_USERS_BACK_DATA,
    payload: data,
  });
};

export const createSupervisor =
  (
    token,
    name,
    familyName,
    email,
    phone,
    address,
    country,
    role,
    files,
    callBack
  ) =>
  async (dispatch) => {
    try {
      dispatch({
        type: SUPERVISOR_CREATE_REQUEST,
      });
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
      };
      const formData = new FormData();
      formData.append("name", name);
      formData.append("familyName", familyName);
      formData.append("email", email);
      formData.append("files", files[0]);
      // formData.append("files", files[1]);

      formData.append("phone", phone);
      formData.append("address", address);
      formData.append("country", country);
      formData.append("role", role);
      // formData.append("teamName", teamName);
      // formData.append("teamType", teamType);

      const { data } = await axios.post(
        `${PROXY}/user/supervisor`,
        formData,
        config
      );

      dispatch({
        type: SUPERVISOR_CREATE_SUCCESS,
        payload: data,
      });

      callBack();
    } catch (error) {
      dispatch({
        type: SUPERVISOR_CREATE_FAIL,
        payload: error.response
          ? error.response.data.message
            ? error.response.data.message
            : error.message
          : "There is a problem, please try again",
      });
    }
  };

export const getSupervisors = (token) => async (dispatch) => {
  try {
    dispatch({ type: SUPERVISORS_LIST_REQUEST });
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    const { data } = await axios.get(`${PROXY}/user/supervisors`, config);
    dispatch({
      type: SUPERVISORS_LIST_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: SUPERVISORS_LIST_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const deleteSupervisors = (token, usersIds) => async (dispatch) => {
  try {
    dispatch({
      type: SUPERVISORS_DELETE_REQUEST,
    });
    console.log("====usersIds", usersIds);
    const config = {
      headers: { Authorization: `Bearer ${token}` },
      data: {
        usersIds,
      },
    };

    const { data } = await axios.delete(
      `${PROXY}/user/deleteSupervisors`,
      config
    );
    dispatch({
      type: SUPERVISORS_DELETE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: SUPERVISORS_DELETE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const getAvailableSuprv = (token) => async (dispatch) => {
  try {
    dispatch({ type: AVAIL_SUPRVISERS_LIST_REQUEST });
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    const { data } = await axios.get(
      `${PROXY}/user/availableSupervisors`,
      config
    );
    dispatch({
      type: AVAIL_SUPRVISERS_LIST_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: AVAIL_SUPRVISERS_LIST_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const getAvailableSubAdmins = (token, subAdmins) => async (dispatch) => {
  try {
    dispatch({ type: AVAIL_SUBADMINS_LIST_REQUEST });
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    const usersIds = JSON.stringify(subAdmins.map((u) => u["_id"]));
    const { data } = await axios.get(
      `${PROXY}/user/availableSubAdmins/${usersIds}`,
      config
    );
    dispatch({
      type: AVAIL_SUBADMINS_LIST_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: AVAIL_SUBADMINS_LIST_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const blockUser =
  (token, userId, blocked, callBack) => async (dispatch) => {
    try {
      dispatch({ type: BLOCK_USER_REQUEST });
      const config = {
        headers: { Authorization: `Bearer ${token}` },
      };
      await axios.post(`${PROXY}/user/block`, { id: userId, blocked }, config);

      dispatch({
        type: BLOCK_USER_SUCCESS,
      });
      callBack();
    } catch (error) {
      dispatch({
        type: BLOCK_USER_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const searchSugestions =
  (token, keyword, isSupervisor, isSubAdmin, state, startDate, endDate) =>
  async (dispatch) => {
    try {
      dispatch({
        type: isSupervisor
          ? AVAIL_SUPRVISERS_LIST_REQUEST
          : AVAIL_SUBADMINS_LIST_REQUEST,
      });
      const config = {
        headers: { Authorization: `Bearer ${token}` },
        params: {
          keyword,
          isSupervisor: isSupervisor,
          isSubAdmin: isSubAdmin,
          state,
          startDate,
          endDate,
        },
      };
      const { data } = await axios.get(
        `${PROXY}/user/searchSugestions`,
        config
      );
      dispatch({
        type: isSubAdmin
          ? AVAIL_SUBADMINS_LIST_SUCCESS
          : AVAIL_SUPRVISERS_LIST_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: isSupervisor
          ? AVAIL_SUPRVISERS_LIST_FAIL
          : AVAIL_SUBADMINS_LIST_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const changePassword =
  (token, oldpwd, newpwd, callBack) => async (dispatch) => {
    try {
      dispatch({ type: USER_LOGIN_REQUEST });
      const config = {
        headers: { Authorization: `Bearer ${token}` },
      };
      await axios.post(
        `${PROXY}/user/changePwdSuperv`,
        { oldpwd, newpwd },
        config
      );

      dispatch({
        type: UPDATE_PASSWORD_SUCCESS,
      });

      callBack();
    } catch (error) {
      console.log("====error", error);
      dispatch({
        type: USER_LOGIN_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };
