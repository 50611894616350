import axios from "axios";
import {
  NEWS_LIST_REQUEST,
  NEWS_LIST_SUCCESS,
  NEWS_LIST_FAIL,
  PROXY,
  DELETE_NEWS_SUCCESS,
  DELETE_NEWS_FAIL,
  NEWS_CREATE_REQUEST,
  NEWS_CREATE_SUCCESS,
  NEWS_CREATE_FAIL,
  UPDATE_NEWS_FAIL,
  UPDATE_NEWS_SUCCESS,
  ADD_COMMENT_REQUEST,
  ADD_COMMENT_SUCCESS,
  ADD_COMMENT_FAIL,
  DELETE_COMMENT_REQUEST,
  DELETE_COMMENT_SUCCESS,
  DELETE_COMMENT_FAIL,
} from "../contants/allConstants";

export const getAllNews = (token) => async (dispatch) => {
  try {
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    const { data } = await axios.get(`${PROXY}/news`, config);
    dispatch({
      type: NEWS_LIST_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: NEWS_LIST_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const deleteNews = (token, id, callBack) => async (dispatch) => {
  try {
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };

    const { data } = await axios.delete(`${PROXY}/news/${id}`, config);
    dispatch({
      type: DELETE_NEWS_SUCCESS,
      payload: data,
    });
    callBack();
  } catch (error) {
    dispatch({
      type: DELETE_NEWS_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const createNews =
  (
    token,
    title,
    description,
    media,
    liveUrl,
    minAge,
    maxAge,
    gender,
    work,
    sportBelonging,
    politicBelonging,
    defaultBelonging,
    longitude,
    latitude,
    radius,
    expired,
    notifTitle,
    callBack
  ) =>
  async (dispatch) => {
    try {
      dispatch({
        type: NEWS_CREATE_REQUEST,
      });
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
      };
      const formData = new FormData();
      formData.append("title", title);
      formData.append("description", description);
      formData.append("file", media);
      formData.append("liveUrl", liveUrl);
      formData.append("minAge", minAge);
      formData.append("maxAge", maxAge);
      formData.append("gender", gender);
      formData.append("work", work);
      formData.append("sportBelonging", JSON.stringify(sportBelonging));
      formData.append("politicBelonging", JSON.stringify(politicBelonging));
      formData.append("defaultBelonging", JSON.stringify(defaultBelonging));
      formData.append("longitude", longitude);
      formData.append("latitude", latitude);
      formData.append("radius", radius);
      formData.append("expired", expired);
      formData.append("notifTitle", notifTitle);

      const { data } = await axios.post(`${PROXY}/news/new`, formData, config);

      dispatch({
        type: NEWS_CREATE_SUCCESS,
        payload: data,
      });

      callBack();
    } catch (error) {
      dispatch({
        type: NEWS_CREATE_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const updateNews =
  (
    token,
    id,
    title,
    description,
    media,
    liveUrl,
    minAge,
    maxAge,
    gender,
    work,
    sportBelonging,
    politicBelonging,
    defaultBelonging,
    longitude,
    latitude,
    radius,
    expired,
    callBack
  ) =>
  async (dispatch) => {
    try {
      dispatch({ type: NEWS_LIST_REQUEST });
      // const chunkSize = 5 * 1024 * 1024; // 5MB (adjust based on your requirements)
      // const totalChunks = Math.ceil(media.size / chunkSize);
      // let chunkNumber = 0;
      // let start = 0;
      // let end = 0;
      // var media_arr = [];
      // for (var i = 0; i < totalChunks; i++) {
      //   const chunk = media.slice(chunkSize * i, chunkSize * i + chunkSize);
      //   media_arr.push(chunk);
      // }
      // console.log("===media_arr", media_arr.length);
      const config = {
        headers: { Authorization: `Bearer ${token}` },
      };
      const formData = new FormData();
      formData.append("title", title);
      formData.append("id", id);
      formData.append("description", description);
      formData.append("file", media);
      formData.append("liveUrl", liveUrl);
      formData.append("minAge", minAge);
      formData.append("maxAge", maxAge);
      formData.append("gender", gender);
      formData.append("work", work);
      formData.append("sportBelonging", JSON.stringify(sportBelonging));
      formData.append("politicBelonging", JSON.stringify(politicBelonging));
      formData.append("defaultBelonging", JSON.stringify(defaultBelonging));
      formData.append("longitude", longitude);
      formData.append("latitude", latitude);
      formData.append("radius", radius);
      formData.append("expired", expired);
      const { data } = await axios.post(
        `${PROXY}/news/update`,
        formData,
        config
      );

      dispatch({
        type: UPDATE_NEWS_SUCCESS,
        payload: data,
      });

      callBack();
    } catch (error) {
      dispatch({
        type: UPDATE_NEWS_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const addComment =
  (token, content, newsId, callBack) => async (dispatch) => {
    try {
      dispatch({
        type: ADD_COMMENT_REQUEST,
      });
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
          // "Content-Type": "multipart/form-data",
        },
      };
      const { data } = await axios.post(
        `${PROXY}/news/comment`,
        { content, newsId },
        config
      );

      dispatch({
        type: ADD_COMMENT_SUCCESS,
        payload: { data, newsId },
      });

      callBack();
    } catch (error) {
      dispatch({
        type: ADD_COMMENT_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const deleteComment =
  (token, id, newsId, callBack) => async (dispatch) => {
    try {
      dispatch({
        type: DELETE_COMMENT_REQUEST,
      });
      const config = {
        headers: { Authorization: `Bearer ${token}` },
      };

      const { data } = await axios.delete(
        `${PROXY}/news/comment/${id}/${newsId}`,
        config
      );
      dispatch({
        type: DELETE_COMMENT_SUCCESS,
        payload: { data, newsId },
      });
      callBack();
    } catch (error) {
      dispatch({
        type: DELETE_COMMENT_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const addReply =
  (token, content, commentId, newsId, callBack) => async (dispatch) => {
    try {
      dispatch({
        type: ADD_COMMENT_REQUEST,
      });
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      const { data } = await axios.post(
        `${PROXY}/news/reply`,
        { content, newsId, commentId },
        config
      );

      dispatch({
        type: ADD_COMMENT_SUCCESS,
        payload: { data, newsId },
      });

      callBack();
    } catch (error) {
      dispatch({
        type: ADD_COMMENT_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const getCommentsDetails = (token, newsId) => async (dispatch) => {
  try {
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    const { data } = await axios.get(
      `${PROXY}/news/comments/${newsId}`,
      config
    );
    dispatch({
      type: ADD_COMMENT_SUCCESS,
      payload: { data, newsId },
    });
  } catch (error) {
    dispatch({
      type: ADD_COMMENT_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const deleteReply =
  (token, replyId, commentId, newsId, callBack) => async (dispatch) => {
    try {
      dispatch({
        type: DELETE_COMMENT_REQUEST,
      });
      const config = {
        headers: { Authorization: `Bearer ${token}` },
      };

      const { data } = await axios.delete(
        `${PROXY}/news/reply/${replyId}/${commentId}/${newsId}`,
        config
      );
      dispatch({
        type: DELETE_COMMENT_SUCCESS,
        payload: { data, newsId },
      });
      callBack();
    } catch (error) {
      dispatch({
        type: DELETE_COMMENT_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };
