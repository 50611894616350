import styled from "styled-components";
import React from "react";
import { COLORS } from "../contants/colors";
import MorePopover from "./MorePopover";
import { useState } from "react";
import { useRef } from "react";
import moment from "moment";
import "moment/locale/ar";
import { numberMap, symbolMap } from "../contants/allConstants";
import Reply from "./Reply";
import { useLanguageContext } from "../languageContext";

const Comment = ({
  id,
  photo,
  username,
  description,
  time,
  replies,
  onDelete,
  onSendReply,
  onDeleteReply,
}) => {
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [showRespond, setShowRespond] = useState(false);
  const [reply, setReply] = useState("");
  const replyEndRef = useRef(null);
  const { t } = useLanguageContext();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    setOpen(true);
  };
  const scrollToBottom = () => {
    replyEndRef.current.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <>
      <Wrapper key={id}>
        <Row>
          <Avatar
            src={photo !== "" ? photo : require("../images/avatar.svg").default}
          />
          <div style={{ width: "100%" }}>
            <div
              style={{
                backgroundColor: `${COLORS.gray990}`,
                borderRadius: "6px",
                padding: "10px 13px",
                width: "100%",
                position: "relative",
              }}
            >
              <Title>{username}</Title>
              <Description>{description}</Description>
              <Row>
                <Time>{time}</Time>
                <Respond onClick={() => setShowRespond(!showRespond)}>
                  {t("reply")}
                </Respond>
              </Row>
              <MorePopover
                showEdit={false}
                handleClick={handleClick}
                open={open}
                setOpen={setOpen}
                anchorEl={anchorEl}
                onDelete={() => {
                  onDelete(id);
                }}
                onUpdate={() => {}}
                styles={{
                  sx: {
                    p: 1,
                    width: 200,
                    overflow: "inherit",
                    boxShadow: "rgba(0, 0, 0, 0.015) 1.95px 1.95px 5px",
                  },
                }}
              />
            </div>

            {replies.map((r, index) => {
              return typeof r === "string" || r instanceof String ? null : (
                <Reply
                  index={index}
                  id={r._id}
                  photo={r.user.image}
                  username={r.user.name + " " + r.user.familyName}
                  description={r.content}
                  time={moment
                    .utc(r["createdAt"])
                    .local()
                    .startOf("seconds")
                    .fromNow()}
                  onDelete={(id) => {
                    onDeleteReply(id);
                  }}
                  onSendReply={(reply, scrollDown) => {
                    onSendReply(reply, r._id);
                    scrollDown();
                  }}
                />
              );
            })}
          </div>
        </Row>
      </Wrapper>

      {showRespond && (
        <div
          style={{
            display: "flex",
            padding: "12px 12px 25px 12px",
            alignItems: "center",
            justifyContent: "end",
          }}
        >
          <SendIcon
            src={require("../images/send.svg").default}
            alt=""
            onClick={async () => {
              onSendReply(reply, () => scrollToBottom());
              setReply("");
            }}
          />
          <Input
            ref={replyEndRef}
            placeholder={"كتابة رد ..."}
            value={reply}
            onChange={(e) => setReply(e.target.value)}
          />
        </div>
      )}
    </>
  );
};
const SendIcon = styled.img`
  width: 25px;
  height: 25px;
  object-fit: fill;
  cursor: pointer;
  margin-right: 12px;
  margin-right: 5px;
  float: right;
`;

const Input = styled.input`
  font-family: "SF-display";
  width: 81%;
  height: 35px;
  border: 1px solid #dcdcdc;
  border-radius: 24px;
  font-size: 0.8rem;
  font-weight: 300;
  padding: 10px;
  text-align: left;

  &::placeholder {
    color: ${COLORS.gray400};
  }
  &:focus {
    outline-color: ${COLORS.lightBlue};
  }
`;
const Wrapper = styled.div`
  border-radius: 10px;
  font-family: "SF-display";
  text-align: left;
  padding: 8px 12px;
  position: relative;
  cursor: pointer;
  color: ${COLORS.black27};
  /* min-width: 340px; */
  width: 100%;
  /* margin: 20px 0; */
`;

const Row = styled.div`
  display: flex;
  padding: 0;
  /* align-items: center; */
  justify-content: space-between;
`;

const Avatar = styled.img`
  border-radius: 50%;
  height: 55px;
  min-width: 55px;
  object-fit: cover;
  margin-right: 10px;
`;
const Title = styled.p`
  font-weight: 700;
  font-size: 0.85rem;
  margin: 0;
  padding-bottom: 3px;
`;
const Description = styled.p`
  font-weight: 400;
  font-size: 0.75rem;
  margin: 0;
  padding-bottom: 3px;
`;
const Time = styled.p`
  font-weight: 400;
  font-size: 0.75rem;
  margin: 0;
  color: ${COLORS.gray500};
`;
const Respond = styled.p`
  font-weight: 500;
  font-size: 0.75rem;
  margin: 0;
  color: ${COLORS.gray550};
  /* text-decoration: underline; */
`;
export default Comment;
