import {
  NOTIFICATION_LIST_REQUEST,
  NOTIFICATION_LIST_SUCCESS,
  NOTIFICATION_LIST_FAIL,
  OPEN_NOTIFICATION_FAIL,
  OPEN_NOTIFICATION_SUCCESS,
  USER_LOGOUT,
} from "../contants/allConstants";

export const notificationListReducer = (
  state = { notifications: [], error: false, count_notif: 0, loading: false },
  action
) => {
  switch (action.type) {
    case NOTIFICATION_LIST_REQUEST:
      return {
        ...state,
        loading: true,
        notifications: state.notifications,
      };
    case NOTIFICATION_LIST_SUCCESS:
      const count_notif = action.payload.notifications.filter(
        (notif) => notif.openedBy.indexOf(action.payload.userId) === -1
      ).length;
      return {
        ...state,
        loading: false,
        notifications: action.payload.notifications,
        success: true,
        count_notif,
      };

    case NOTIFICATION_LIST_FAIL:
      return {
        ...state,
        loading: false,
        success: true,
        notifications: state.notifications,
      };
    case OPEN_NOTIFICATION_FAIL:
      return { ...state, loading: false, error: action.payload };
    case OPEN_NOTIFICATION_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        count_notif: 0,
        notifications: action.payload,
      };
    case USER_LOGOUT:
      return {
        loading: false,
        error: false,
        count_notif: 0,
        notifications: [],
      };
    default:
      return state;
  }
};
