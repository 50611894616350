import React, { useEffect, useState } from "react";
import ReactECharts from "echarts-for-react";
import * as echarts from "echarts";
import styled from "styled-components";
import { QUERIES } from "../contants/allConstants";

export default function CitiesChart({ users }) {
  const option = {
    tooltip: {
      trigger: "item",
    },
    legend: {
      top: "5%",
      left: "center",
      selectedMode: false,
    },
    series: [
      {
        name: "",
        type: "pie",
        radius: ["40%", "70%"],
        center: ["50%", "70%"],
        startAngle: 180,
        label: {
          show: true,
          formatter(param) {
            return param.name + " (" + param.percent * 2 + "%)";
          },
        },
        data: [],
      },
    ],
  };
  const [options, setOptions] = useState(option);

  useEffect(() => {
    var results = [];
    const groupBy = (x, f) =>
      x.reduce((a, b, i) => ((a[f(b, i, x)] ||= []).push(b), a), {});
    const groups = groupBy(users, (v) => v.state);

    for (const [key, value] of Object.entries(groups)) {
      results.push({ value: value.length, name: key });
    }
    results.push({
      value: users.length,
      itemStyle: {
        color: "none",
        decal: {
          symbol: "none",
        },
      },
      label: {
        show: false,
      },
    });
    setOptions({
      ...options,
      series: [{ ...options.series[0], data: results }],
    });
  }, [users]);

  return <ReactECharts option={options} />;
}
