import styled from "styled-components";
import React from "react";
import { COLORS } from "../contants/colors";
import MorePopover from "./MorePopover";
import { useState } from "react";
import { useLanguageContext } from "../languageContext";

const Alert = ({
  photo,
  title,
  subtitle,
  address,
  time,
  participants,
  disapprovers,
  onDelete,
  onOpenParticipants,
  onOpenDisapprovers,
}) => {
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const { t } = useLanguageContext();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    setOpen(true);
  };
  return (
    <Wrapper>
      <MorePopover
        onDelete={onDelete}
        showEdit={false}
        handleClick={handleClick}
        open={open}
        setOpen={setOpen}
        anchorEl={anchorEl}
      />
      <Row>
        <Avatar
          src={photo !== "" ? photo : require("../images/avatar.svg").default}
        />
        <div>
          <Title>{title}</Title>
          <Subtitle>{subtitle}</Subtitle>
        </div>
      </Row>
      <SubRow>
        <p style={{ margin: 0 }}>
          <Icon src={require("../images/pin.svg").default} alt="" />
          {address}
        </p>
        <p style={{ margin: 0 }}>
          <Icon src={require("../images/timer.svg").default} alt="" />
          {time}
        </p>
      </SubRow>
      <Divider />
      <Button onClick={onOpenParticipants}>{`${t(
        "nbrAssis"
      )}:  ${participants} `}</Button>
      <SecondaryBtn onClick={onOpenDisapprovers}>{`${t(
        "nbrNay"
      )}:  ${disapprovers} `}</SecondaryBtn>
    </Wrapper>
  );
};
const Wrapper = styled.div`
  color: ${COLORS.black56};
  background-color: white;
  border-radius: 10px;
  font-family: "SF-display";
  text-align: left;
  font-size: 0.75rem;
  padding: 10px 18px;
  box-shadow: 0 30px 60px rgba(57, 57, 57, 0.1);
  position: relative;
  border: 1px solid ${COLORS.background};
  cursor: pointer;
`;

const Button = styled.button`
  color: white;
  border-radius: 4px;
  font-family: "SF-display";
  font-size: 0.75rem;
  font-weight: 700;
  background-color: ${COLORS.primary};
  border: none;
  height: 38px;
  margin-top: 18px;
  width: 100%;

  &:focus {
    outline-color: ${COLORS.primary};
  }
`;
const SecondaryBtn = styled(Button)`
  background-color: ${COLORS.green30};
  color: ${COLORS.primary};
`;
const Divider = styled.div`
  height: 1px;
  width: 100%;
  background-color: ${COLORS.gray300};
  margin: 5px 0;
`;

const Row = styled.div`
  display: flex;
  padding-top: 20px;
  align-items: center;
`;
const SubRow = styled.div`
  display: flex;
  margin: 25px 0 10px 0;
  align-items: center;
  justify-content: space-between;
  font-weight: 500;
  font-size: 0.75rem;
  color: black;
`;
const Icon = styled.img`
  height: 12px;
  object-fit: cover;
  margin-right: 6px;
  margin-bottom: 4px;
`;
const Avatar = styled.img`
  border-radius: 50%;
  height: 60px;
  min-width: 60px;
  object-fit: cover;
  margin-right: 10px;
`;
const Title = styled.p`
  font-weight: 700;
  font-size: 0.9375rem;
  color: ${COLORS.black27};
  margin: 0;
  padding-bottom: 3px;
`;
const Subtitle = styled.p`
  font-weight: 400;
  font-size: 0.8125rem;
  color: ${COLORS.gray50};
  margin: 0;
  /* text-overflow: ellipsis; */
  /* word-break: break-all; */

  /* overflow: hidden;
  white-space: nowrap;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical; */
`;
export default Alert;
