import React, { useState, useEffect } from "react";
import ReactEcharts from "echarts-for-react";
import * as echarts from "echarts";
echarts.registerTheme("my_theme", {
  color: "#ffa927",
});

const AgeStatistics = ({ users }) => {
  const option = {
    xAxis: {
      type: "category",
      data: [
        "0-10",
        "10-20",
        "20-30",
        "30-40",
        "40-50",
        "50-60",
        "60-70",
        ">70",
      ],
    },
    yAxis: {
      type: "value",
    },
    series: [
      {
        data: [0, 0, 0, 0, 0, 0, 0, 0],
        type: "bar",
      },
    ],
  };
  const [options, setOptions] = useState(option);
  useEffect(() => {
    var usersMap = {
      "0-10": 0,
      "10-20": 0,
      "20-30": 0,
      "30-40": 0,
      "40-50": 0,
      "50-60": 0,
      "60-70": 0,
      ">70": 0,
    };
    for (var i = 0; i < users.length; i++) {
      const age = getAge(users[i].birthday);

      if (age >= 0 && age <= 10) usersMap["0-10"] += 1;
      else if (age > 10 && age <= 20) usersMap["10-20"] += 1;
      else if (age > 20 && age <= 30) usersMap["20-30"] += 1;
      else if (age > 30 && age <= 40) usersMap["30-40"] += 1;
      else if (age > 40 && age <= 50) usersMap["40-50"] += 1;
      else if (age > 50 && age <= 60) usersMap["50-60"] += 1;
      else if (age > 60 && age <= 70) usersMap["60-70"] += 1;
      else usersMap[">70"] += 1;
    }
    var values = {
      ...options,
      series: [
        {
          data: [
            usersMap["0-10"],
            usersMap["10-20"],
            usersMap["20-30"],
            usersMap["30-40"],
            usersMap["40-50"],
            usersMap["50-60"],
            usersMap["60-70"],
            usersMap[">70"],
          ],
          type: "bar",
        },
      ],
    };
    setOptions(values);
  }, [users]);
  const getAge = (dateString) => {
    var today = new Date();
    var birthDate = new Date(dateString);
    var age = today.getFullYear() - birthDate.getFullYear();
    var m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }
    return age;
  };
  return (
    <div>
      <ReactEcharts option={options} theme="my_theme" />
    </div>
  );
};

export default AgeStatistics;
