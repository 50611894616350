import styled from "styled-components";
import React, { useState } from "react";
import { Box, MenuItem, IconButton, Popover } from "@mui/material";
import { Icon } from "@iconify/react";
import { COLORS } from "../contants/colors";
import { useLanguageContext } from "../languageContext";

const MorePopover = ({
  showEdit = false,
  showDetails = false,
  canDelete = true,
  handleClick,
  open,
  setOpen,
  anchorEl,
  top = 0,
  onDelete,
  onUpdate,
  onOpenDetails,
  styles = null,
}) => {
  const { t } = useLanguageContext();

  return (
    <MoreWrapper top={top}>
      <IconButton onClick={handleClick}>
        <Box
          colorRendering={"red"}
          component={Icon}
          icon={"eva:more-horizontal-fill"}
          width={20}
          height={20}
          color={COLORS.gray500}
        />
      </IconButton>
      <Popover
        PaperProps={
          styles
            ? styles
            : {
                sx: {
                  p: 1,
                  width: 200,
                  overflow: "inherit",
                },
              }
        }
        open={open}
        anchorEl={anchorEl}
        onClose={() => setOpen(false)}
        anchorOrigin={{ vertical: "top", horizontal: "left" }}
        transformOrigin={{ vertical: "top", horizontal: "left" }}
        arrow="left-top"
        sx={{
          mt: -1,
          width: 140,
          "& .MuiMenuItem-root": {
            px: 1,
            typography: "body2",
            borderRadius: 0.75,
            fontFamily: "Din",
            "& svg": { ml: 1, width: 20, height: 20 },
          },
        }}
      >
        {canDelete && (
          <MenuItem
            onClick={(id) => {
              onDelete(id);
              setOpen(false);
            }}
          >
            <Box component={Icon} icon={"eva:trash-2-outline"} /> {t("delete")}
          </MenuItem>
        )}
        {showDetails && (
          <MenuItem
            onClick={(id) => {
              onOpenDetails(id);
              setOpen(false);
            }}
          >
            <Box component={Icon} icon={"uil:chart"} /> {t("details")}
          </MenuItem>
        )}
        {showEdit && (
          <MenuItem
            onClick={(id) => {
              onUpdate(id);
              setOpen(false);
            }}
          >
            <Box component={Icon} icon={"eva:edit-2-outline"} /> {t("edit")}
          </MenuItem>
        )}
      </Popover>
    </MoreWrapper>
  );
};

const MoreWrapper = styled.div`
  position: absolute;
  top: ${(p) => p.top}px;
  right: 10px;
`;
export default MorePopover;
