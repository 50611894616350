import styled from "styled-components";
import React, { useEffect, useState } from "react";
import { COLORS } from "../contants/colors";

const FileField = ({ title, setFile, file, type, large = false }) => {
  const [fileUrl, setFileUrl] = useState(null);
  const [fileType, setFileType] = useState("");
  useEffect(() => {
    if (typeof file === "string" && file !== "") {
      var extension = file.slice(file.lastIndexOf(".") + 1);
      setFileUrl(file);
      setFileType(extension);
    }
  }, [file]);

  const handleFileChange = (event) => {
    if (event.target.files) {
      setFile(event.target.files[0]);
      const selectedFile = event.target.files[0].name;
      var url = URL.createObjectURL(event.target.files[0]);
      var extension = selectedFile.slice(selectedFile.lastIndexOf(".") + 1);
      setFileUrl(url);
      setFileType(extension);
      event.target.value = null;
    }
  };

  return (
    <Wrapper>
      <Title>{title}</Title>
      <File class="image-upload" large={large}>
        <Image src={require("../images/upload.svg").default} alt="" />
        <Input
          accept={type}
          id={title}
          key={title}
          type="file"
          onChange={(e) => handleFileChange(e)}
        />
        {file ? (
          <Preview>
            <Close
              src={require("../images/close.svg").default}
              alt=""
              onClick={() => {
                setFileUrl(null);
                setFileType("");
                setFile("");
              }}
            />
            {fileType === "mp3" || fileType === "wav" ? (
              <Audio controls src={fileUrl}></Audio>
            ) : fileType === "png" ||
              fileType === "jpeg" ||
              fileType === "jpg" ? (
              <ImgPreview src={fileUrl} alt="" />
            ) : (
              <VideoPreview width="85%" height="85%" controls key={fileUrl}>
                <source src={fileUrl} type="video/mp4" key={fileUrl} />
              </VideoPreview>
            )}
          </Preview>
        ) : null}
      </File>
    </Wrapper>
  );
};
const Wrapper = styled.div`
  color: ${COLORS.black56};
  margin-top: 13px;
`;
const File = styled.div`
  background-color: ${COLORS.gray800};
  height: ${(p) => (p.large ? "143px" : "15vh")};
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
`;
const Preview = styled.div`
  margin: auto;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 3;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const Close = styled.img`
  width: 25px;
  height: 25px;
  object-fit: fill;
  position: absolute;
  top: 1%;
  left: 5%;
  z-index: 100;
  cursor: pointer;
`;
const Audio = styled.audio`
  width: 85%;
  height: 70%;
  background-color: #f1f3f4;
  border-radius: 4px;
  /* margin-bottom: 10%; */
  border: 1px solid #ccc;
`;

const ImgPreview = styled.img`
  width: 85%;
  height: 85%;
  object-fit: cover;
  border-radius: 4px;
`;
const VideoPreview = styled.video`
  width: 85%;
  height: 85%;
  object-fit: cover;
  vertical-align: middle;
  border-radius: 4px;
`;
const Title = styled.p`
  font-family: "SF-display";
  font-size: 0.875rem;
  font-weight: 700;
  text-align: left;
  margin: 0;
  padding-bottom: 10px;
`;
const Input = styled.input`
  width: 100%;
  height: 100%;
  cursor: pointer;
  z-index: 2;
  opacity: 0;
`;
const Image = styled.img`
  width: 43px;
  object-fit: fill;
  margin: auto;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 0;
`;

export default FileField;
