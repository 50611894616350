import {
  TEAM_LIST_REQUEST,
  TEAM_LIST_SUCCESS,
  TEAM_LIST_FAIL,
  TEAM_CREATE_REQUEST,
  TEAM_CREATE_SUCCESS,
  TEAM_CREATE_FAIL,
  USER_LOGOUT,
  SUPERVISOR_TEAM,
  UPDATE_TEAM_FAIL,
  TEAM_UPDATE_REQUEST,
  UPDATE_TEAM_SUCCESS,
  ADD_OWNER_REQUEST,
  ADD_OWNER_SUCCESS,
  ADD_OWNER_FAIL,
  DELETE_TEAM_REQUEST,
  DELETE_TEAM_SUCCESS,
  DELETE_TEAM_FAIL,
} from "../contants/allConstants";

export const teamListReducer = (
  state = {
    teams: [],
    error: false,
    supervisor_team: null,
    load_addition: false,
    success_addition: true,
    loading_delete: false,
  },
  action
) => {
  switch (action.type) {
    case ADD_OWNER_REQUEST:
      return {
        ...state,
        load_addition: true,
      };
    case ADD_OWNER_SUCCESS:
      return {
        ...state,
        load_addition: false,
      };
    case ADD_OWNER_FAIL:
      return {
        ...state,
        load_addition: false,
      };
    case TEAM_LIST_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case TEAM_LIST_SUCCESS: {
      return {
        ...state,
        loading: false,
        teams: action.payload.teams ? action.payload.teams : action.payload,
        success: true,
      };
    }
    case TEAM_LIST_FAIL:
      return {
        ...state,
        loading: false,
        error: true,
      };
    case TEAM_CREATE_REQUEST:
    case TEAM_UPDATE_REQUEST: {
      return {
        ...state,
        loading: true,
        error: false,
      };
    }
    case TEAM_CREATE_SUCCESS:
      return {
        ...state,
        loading: false,
        // teams: action.payload,
        success: true,
      };

    case TEAM_CREATE_FAIL:
    case UPDATE_TEAM_FAIL: {
      return {
        ...state,
        loading: false,
        success: false,
        error: true,
      };
    }
    case USER_LOGOUT:
      return {
        loading: false,
        success: false,
        error: false,
        questions: [],
      };
    case UPDATE_TEAM_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        // supervisor_team: action.payload, //supervisor_team
        teams: action.payload.teams ? action.payload.teams : action.payload,
        error: false,
      };
    case DELETE_TEAM_REQUEST:
      return {
        ...state,
        loading_delete: true,
        success: false,
        error: false,
      };
    case DELETE_TEAM_SUCCESS:
      return {
        ...state,
        loading_delete: false,
        teams: action.payload.teams ? action.payload.teams : action.payload,
        success: true,
      };
    case DELETE_TEAM_FAIL:
      return {
        ...state,
        loading_delete: false,
        success: false,
        error: true,
      };
    default:
      return state;
  }
};
