import React, { useEffect, useState } from "react";
import ReactECharts from "echarts-for-react";
import * as echarts from "echarts";
import { useLanguageContext } from "../languageContext";

export default function CustomPieChart({ users, field, color }) {
  // echarts.registerTheme("my_theme", {
  //   color: color,
  // });
  const option = {
    title: {
      text: "",
      subtext: "",
      left: "center",
    },
    tooltip: {
      trigger: "item",
    },
    legend: {
      orient: "vertical",
      left: "left",
    },
    series: [
      {
        name: "",
        type: "pie",
        radius: [30, 150],
        center: ["50%", "50%"],
        roseType: "area",
        itemStyle: {
          borderRadius: 8,
        },
        data: [],
        emphasis: {
          itemStyle: {
            shadowBlur: 10,
            shadowOffsetX: 0,
            shadowColor: "rgba(0, 0, 0, 0.5)",
          },
        },
      },
    ],
  };
  const [options, setOptions] = useState(option);
  const { t } = useLanguageContext();

  useEffect(() => {
    var results = [];
    const groupBy = (x, f) =>
      x.reduce((a, b, i) => ((a[f(b, i, x)] ||= []).push(b), a), {});
    console.log("=====field", field);
    const groups = groupBy(users, (v) => {
      if (field === "country") return v.country;
      else return v.gender;
    });

    for (const [key, value] of Object.entries(groups)) {
      results.push({ value: value.length, name: key });
    }

    setOptions({
      ...options,
      series: [{ ...options.series, data: results }],
    });
  }, [users, field]);

  return (
    <ReactECharts option={options} style={{ height: "300px", width: "100%" }} />
  );
}
