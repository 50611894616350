import styled from "styled-components";
import React from "react";
import { COLORS } from "../contants/colors";
import { QUERIES, numberMap, symbolMap } from "../contants/allConstants";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import ChatIcon from "@mui/icons-material/ModeCommentOutlined";
import LikeIcon from "@mui/icons-material/FavoriteBorderOutlined";
import { useEffect } from "react";
import Comment from "./Comment";
import moment from "moment";
import "moment/locale/ar";
import { useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { deleteComment } from "../actions/newsActions";
import Toast from "react-bootstrap/Toast";
import ToastContainer from "react-bootstrap/ToastContainer";
import Collapse from "react-bootstrap/Collapse";
import ReactPlayer from "react-player";
import { useLanguageContext } from "../languageContext";

const StyledTab = styled(Tabs)({
  borderBottom: "1px solid #e8e8e8",
  color: "#000",
  fontFamily: "Din !important",
  "& .MuiTabs-indicator": {
    backgroundColor: `${COLORS.orange}`,
  },
  "&.Mui-selected": {
    color: "red",
    backgroundColor: "#fff",
  },
  "&.Mui-focusVisible": {
    color: `${COLORS.orange}`,
    fontFamily: "Din",
  },
});

const NewsDetails = ({
  news,
  onClose,
  onSendComment,
  onSendReply,
  onDeleteReply,
  top = 0,
}) => {
  const [value, setValue] = React.useState(0);
  const [reaction, setReaction] = React.useState(0);
  const [comment, setComment] = React.useState("");
  const messagesEndRef = useRef(null);
  const { userInfo } = useSelector((state) => state.userLogin);
  const { error, loading } = useSelector((state) => state.news);
  const [message, setMessage] = React.useState("");
  const [toastType, setToastType] = React.useState("");
  const [groupedReactions, setGroupedReactions] = React.useState([
    [],
    [],
    [],
    [],
    [],
    [],
  ]);
  const dispatch = useDispatch();
  const { t } = useLanguageContext();

  useEffect(() => {
    let reacts = [[], [], [], [], [], []];
    for (var like of news.likes) {
      let l = like.emoji;

      switch (l) {
        case "أعجبني":
          reacts[0].push(like);
          break;
        case "أحب":
          reacts[1].push(like);
          break;
        case "حزين":
          reacts[2].push(like);
          break;
        case "مضحك":
          reacts[3].push(like);
          break;
        case "غاضب":
          reacts[4].push(like);
          break;
        default:
          reacts[5].push(like);
          break;
      }
      console.log("====reactks", l.includes("مضحك"), reacts);
    }
    setGroupedReactions(reacts);
  }, [reaction, news]);

  useEffect(() => {
    setTimeout(() => {
      setToastType("");
    }, 8000);
  }, [toastType]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const handleChangeReaction = (event, newValue) => {
    setReaction(newValue);
  };
  const scrollToBottom = () => {
    messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
  };
  const onDeleteComment = (id) => {
    dispatch(
      deleteComment(userInfo["token"], id, news["_id"], () => {
        if (error) {
          setMessage(t("mainError"));
          setToastType("danger");
        }
      })
    );
  };
  return (
    <Container>
      <Shadow top={top}>
        <Wrapper top={top}>
          <Head>
            <Icon
              onClick={() => onClose()}
              src={require("../images/whiteClose.svg").default}
              alt=""
            />
            <HTitle>{t("newsDetails")}</HTitle>
          </Head>
          <div style={{ display: "flex", justifyContent: "center" }}>
            {news["live"] && news["live"] !== "" ? (
              <LiveHolder full={!news["live"].includes("youtube")}>
                <ReactPlayer
                  width={"100%"}
                  height={"100%"}
                  url={news["live"]}
                  // height="28vh"
                />
              </LiveHolder>
            ) : news["media"].includes(".mp3") ||
              news["media"].includes(".wav") ? (
              <Audio controls src={news["media"]}></Audio>
            ) : news["media"].includes(".png") ||
              news["media"].includes(".jpeg") ||
              news["media"].includes(".jpg") ? (
              <Photo
                src={
                  news["media"] !== ""
                    ? news["media"]
                    : require("../images/placeholder.svg").default
                }
              />
            ) : (
              <VideoPreview
                width="85%"
                height="85%"
                controls
                key={news["media"]}
              >
                <source
                  src={news["media"]}
                  type="video/mp4"
                  key={news["media"]}
                />
              </VideoPreview>
            )}
          </div>
          <div style={{ padding: "0 20px" }}>
            <Title>{news["title"]}</Title>
            <Description>{news["description"]}</Description>
          </div>
          <Box sx={{ p: 3 }}>
            <StyledTab
              variant={"fullWidth"}
              value={value}
              onChange={handleChange}
              aria-label="basic tabs example"
              textColor="inherit"
              indicatorColor="inherit"
              sx={{
                fontFamily: "Din",
                ".Mui-selected": {
                  color: `${COLORS.orange}`,
                  fontFamily: "Din",
                },
                ".MuiTab-root": {
                  fontFamily: "Din",
                },
                ".MuiTab-iconWrapper": {
                  margin: "0  0 0 10px",
                },
              }}
            >
              <Tab
                label={news.comments.length + " " + t("comments")}
                icon={<ChatIcon />}
                iconPosition="start"
              />
              <Tab
                label={news.likes.length + " " + t("likes")}
                icon={<LikeIcon />}
                iconPosition="start"
              />
            </StyledTab>
          </Box>
          <div style={{ padding: value === 0 ? "0 30px" : "0" }}>
            {value === 0 ? (
              news.comments.length === 0 ? (
                <Label>{t("emptyComm")}</Label>
              ) : (
                news.comments.map((c) => (
                  <Comment
                    id={c._id}
                    photo={c.user.image}
                    username={c.user.name + " " + c.user.familyName}
                    description={c.content}
                    replies={c.replies}
                    time={moment
                      .utc(c["createdAt"])
                      .local()
                      .startOf("seconds")
                      .fromNow()}
                    onDelete={(id) => {
                      onDeleteComment(id);
                    }}
                    onSendReply={(reply, scrollDown) => {
                      onSendReply(reply, c._id);
                      scrollDown();
                    }}
                    onDeleteReply={(replyId) => {
                      onDeleteReply(replyId, c._id);
                    }}
                  />
                ))
              )
            ) : news.likes.length === 0 ? (
              <Label>{t("noLikes")}</Label>
            ) : (
              <Box sx={{ p: 5, padding: "0" }}>
                <StyledTab
                  variant={"fullWidth"}
                  value={reaction}
                  onChange={handleChangeReaction}
                  aria-label="basic tabs example"
                  textColor="inherit"
                  indicatorColor="inherit"
                  sx={{
                    fontFamily: "Din",
                    ".Mui-selected": {
                      color: `${COLORS.orange}`,
                      fontFamily: "Din",
                    },
                    ".MuiTab-root": {
                      fontFamily: "Din",
                      padding: "0",
                      textTransform: "math-auto",
                    },
                    ".MuiTab-iconWrapper": {
                      margin: "0  0 0 5px",
                    },
                  }}
                >
                  <Tab
                    label={groupedReactions[0].length + t("likes")}
                    icon={
                      <Reaction
                        src={require("../images/like3.svg").default}
                        alt=""
                      />
                    }
                    iconPosition="start"
                  />
                  <Tab
                    label={groupedReactions[1].length + " " + t("love")}
                    icon={
                      <Reaction
                        src={require("../images/love2.svg").default}
                        alt=""
                      />
                    }
                    iconPosition="start"
                  />
                  <Tab
                    label={groupedReactions[2].length + " " + t("sad")}
                    icon={
                      <Reaction
                        src={require("../images/sad2.svg").default}
                        alt=""
                      />
                    }
                    iconPosition="start"
                  />
                  <Tab
                    label={groupedReactions[3].length + " " + t("haha")}
                    icon={
                      <Reaction
                        src={require("../images/haha2.svg").default}
                        alt=""
                      />
                    }
                    iconPosition="start"
                  />
                  <Tab
                    label={groupedReactions[4].length + " " + t("angry")}
                    icon={
                      <Reaction
                        src={require("../images/angry2.svg").default}
                        alt=""
                      />
                    }
                    iconPosition="start"
                  />
                  <Tab
                    label={groupedReactions[5].length + " " + t("wow")}
                    icon={
                      <Reaction
                        src={require("../images/wow2.svg").default}
                        alt=""
                      />
                    }
                    iconPosition="start"
                  />
                </StyledTab>
              </Box>
            )}
            {value === 0 && (
              <div
                style={{
                  display: "flex",
                  padding: "12px 12px 25px 12px",
                  alignItems: "center",
                }}
              >
                <Input
                  ref={messagesEndRef}
                  placeholder={t("writeC") + "..."}
                  value={comment}
                  onChange={(e) => setComment(e.target.value)}
                />
                <SendIcon
                  src={require("../images/send.svg").default}
                  alt=""
                  onClick={async () => {
                    onSendComment(comment, () => scrollToBottom());
                    setComment("");
                  }}
                />
              </div>
            )}
            {value === 1 &&
              groupedReactions[reaction].map((l) => {
                return (
                  <UserReaction>
                    <div>
                      <AvatarReaction
                        src={
                          l.user.image !== ""
                            ? l.user.image
                            : require("../images/avatar.svg").default
                        }
                      />
                      <NameReaction>
                        {l.user.name + " " + l.user.familyName}
                      </NameReaction>
                    </div>
                  </UserReaction>
                );
              })}
          </div>
          {toastType !== "" && (
            <ToastContainer
              containerPosition="sticky"
              position="top-end"
              style={{
                position: "fixed !important",
                bottom: "40%",
                right: 0,
                zIndex: "2000",
                float: "right",
                textAlign: "left",
              }}
            >
              <Toast
                delay={7000}
                animation
                transition={Collapse}
                bg={toastType}
                style={{
                  borderRadius: "4px",
                  fontFamily: "Din",
                  padding: "0 30px",
                }}
              >
                <Toast.Body className={"text-white"}>{message}</Toast.Body>
              </Toast>
            </ToastContainer>
          )}
        </Wrapper>
      </Shadow>
    </Container>
  );
};

const AvatarReaction = styled.img`
  border-radius: 50%;
  height: 40px;
  width: 40px;
  object-fit: cover;
  margin-right: 8px;
`;
const NameReaction = styled.span`
  color: "#444445";
  font-family: "SF-display";
  font-size: 0.9rem;
  font-weight: 500;
  text-align: center;
  margin: 0;
  padding-top: 6px;
  vertical-align: middle;
  display: inline-block;
`;
const LiveHolder = styled.div`
  width: 100%;
  height: ${(p) => (p.full ? "100%" : "40vh")};
  /* padding: 1vh 0; */
  margin: 29px 0 7px 0;
  object-fit: cover;
  vertical-align: middle;
  border-radius: 4px;
`;
const UserReaction = styled.div`
  border-radius: 10px;
  font-family: "SF-display";
  text-align: left;
  padding: 12px 27px;
  position: relative;
  border-bottom: 1px solid #f5f5f5;
  cursor: pointer;
  color: #272735;
  min-width: 340px;
  width: 33vw;
  margin-top: 7px;
  @media (${QUERIES.mobileAndDown}) {
    min-width: 280px;
  }
`;
const Reaction = styled.img`
  width: 30px;
  height: 30px;
  object-fit: fill;
  cursor: pointer;
  margin: 0 5px 0 0 !important;

  /* margin: 12px 16px 0 0;
  float: right; */
`;
const SendIcon = styled.img`
  width: 35px;
  height: 35px;
  object-fit: fill;
  cursor: pointer;
  margin-left: 12px;
  float: right;
`;

const Input = styled.input`
  font-family: "SF-display";
  width: 100%;
  border: 1px solid #dcdcdc;
  border-radius: 24px;
  font-size: 0.875rem;
  font-weight: 300;
  padding: 10px;
  text-align: left;

  &::placeholder {
    color: ${COLORS.gray400};
  }
  &:focus {
    outline-color: ${COLORS.lightBlue};
  }
`;
const Label = styled.p`
  color: ${COLORS.gray400};
  font-family: "SF-display";
  font-weight: 400;
  font-size: 0.9rem;
  text-align: center;
  align-self: center;
`;
const HTitle = styled.p`
  color: white;
  font-family: "SF-display";
  font-size: 0.9rem;
  font-weight: 700;
  text-align: center;
  margin: 0;
  padding-top: 6px;
`;
const Container = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  /* height: 100%; */
  font-family: "SF-display";
`;
const Shadow = styled.div`
  background-color: ${COLORS.shadow};
  padding: 15px;
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1002;
  min-height: 100vh;
  overflow-y: auto;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: left;
  @media (${QUERIES.tabletAndDown}) {
    // height: max-content;
    height: 100%;
  }
`;
const Wrapper = styled.div`
  background-color: white;
  width: 90%;
  max-width: 800px;
  /* height: 90%; */
  border-radius: 10px;
  font-family: "SF-display";
`;

const Audio = styled.audio`
  width: 100%;
  max-width: 500px;
  /* height: 70%; */
  background-color: #f1f3f4;
  border-radius: 4px;
  margin: 29px 0 7px 0;
  border: 1px solid #ccc;
`;
const VideoPreview = styled.video`
  width: 100%;
  height: 30vh;
  margin: 29px 0 7px 0;
  object-fit: cover;
  vertical-align: middle;
  border-radius: 4px;
`;
const Photo = styled.img`
  margin: 20px;
  height: 30vh;
  width: 100%;
  max-width: 700px;
  object-fit: cover;
  border-radius: 4px;
`;
const Divider = styled.div`
  width: 1px;
  background-color: ${COLORS.gray700};
  margin: 20px 0;
  @media (${QUERIES.tabletAndDown}) {
    display: none;
  }
`;
const Square = styled.p`
  background-color: ${COLORS.gray800};
  font-family: "SF-display";
  border-radius: 4px;
  font-weight: 400;
  font-size: 0.85rem;
  color: ${COLORS.black56};
  padding: 8px 10px;
  margin-top: 0;
  white-space: nowrap;
  margin-right: 6px;
`;
const Head = styled.div`
  width: 100%;
  height: 36px;
  background-color: ${COLORS.primary};
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
`;
const Icon = styled.img`
  width: 10px;
  height: 10px;
  object-fit: fill;
  cursor: pointer;
  float: right;
  margin: 12px 16px 0 0;
`;
const Col = styled.div`
  padding: 20px 30px 50px 20px;
  width: calc((90vw - 300px));
  /* width: calc((90vw - 300px) / 2); */

  @media (${QUERIES.tabletAndDown}) {
    width: 100%;
    padding: 10px 30px 0 20px;
  }
`;
const User = styled.div`
  display: flex;
  padding: 0;
  margin-bottom: 10px;
`;
const Row = styled.div`
  display: flex;
  padding: 0;
  @media (${QUERIES.tabletAndDown}) {
    flex-direction: column;
  }
`;
const Subrow = styled(Row)`
  text-align: left;
  align-items: baseline;
  @media (${QUERIES.tabletAndDown}) {
    flex-direction: row;
  }
`;
const WrapRow = styled(Row)`
  text-align: left;
  flex-wrap: wrap;
  @media (${QUERIES.tabletAndDown}) {
    flex-direction: row;
  }
`;
const Avatar = styled.img`
  border-radius: 50%;
  height: 84px;
  min-width: 84px;
  width: 84px;
  object-fit: cover;
  margin-right: 30px;
  @media (${QUERIES.tabletAndDown}) {
    height: 64px;
    min-width: 64px;
    width: 64px;
  }
`;
const Title = styled.p`
  font-weight: 700;
  font-size: 1rem;
  color: ${COLORS.black56};
  margin: 10px 0 0 0;
  width: 100px;
  white-space: nowrap;
`;
const Description = styled.p`
  font-weight: 400;
  color: ${COLORS.black56};
  font-size: 1rem;
  margin: 15px 0 0 0;
  @media (${QUERIES.tabletAndDown}) {
    margin: 4px 0 0 0;
  }
`;
const SubTitle = styled(Title)`
  margin-top: 15px;
  min-width: 130px;
  font-size: 0.875rem;
`;
const SubDesc = styled(Description)`
  font-size: 0.875rem;
  word-wrap: break-word;
  width: max-content;
  overflow: auto;
`;
const GreenTitle = styled(Title)`
  color: ${COLORS.primary};
  margin: 5px 0 0 0;
`;
export default NewsDetails;
