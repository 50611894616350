import React from "react";
import Alert from "react-bootstrap/Alert";

const Message = ({ variant, children }) => {
  return (
    <Alert
      variant={variant}
      style={{
        margin: 0,
        width: "100%",
        textAlign: "center",
        fontSize: "0.8rem",
        opacity: 1,
      }}
    >
      {children}
    </Alert>
  );
};

Message.defaultProps = {
  variant: "info",
};

export default Message;
