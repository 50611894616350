import styled from "styled-components";
import React, { useState, useRef, useEffect } from "react";
import { LinkContainer } from "react-router-bootstrap";
import { COLORS } from "../contants/colors";
import { QUERIES } from "../contants/allConstants";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../actions/userActions";
import CloseIcon from "@mui/icons-material/Close";
import { useLanguageContext } from "../languageContext";

const Drawer = ({ open, setOpenDrawer, onChange, selected, history }) => {
  const dispatch = useDispatch();
  const { count } = useSelector((state) => state.alert);
  const { userInfo } = useSelector((state) => state.userLogin);
  const myRef = useRef();
  const layerRef = useRef();
  const { t } = useLanguageContext();

  const handleClickOutside = (e) => {
    if (layerRef.current) {
      if (layerRef.current.contains(e.target)) {
        setOpenDrawer(false);
      }
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  });
  const handleLogout = () => {
    history.push("/dashboard/login");
    dispatch(logout());
  };
  return (
    <div>
      {open && <BackgroundLayer ref={layerRef} />}
      <Wrapper open={open} ref={myRef} onClick={() => setOpenDrawer(false)}>
        <List>
          {open && (
            <Close onClick={setOpenDrawer}>
              <CloseIcon />
            </Close>
          )}
          {!userInfo["isJournalist"] && (
            <LinkContainer to="/dashboard/">
              <Item selected={selected === 0} onClick={() => onChange(0)}>
                <Image
                  src={
                    require(selected === 0
                      ? "../images/icon1act.svg"
                      : "../images/icon1.svg").default
                  }
                  alt=""
                />
                <Label>{t("home")}</Label>
              </Item>
            </LinkContainer>
          )}
          {!userInfo["isJournalist"] && (
            <LinkContainer to="/dashboard/questions">
              <Item selected={selected === 1} onClick={() => onChange(1)}>
                <Image
                  src={
                    require(selected === 1
                      ? "../images/icon4act.svg"
                      : "../images/icon4.svg").default
                  }
                  alt=""
                />
                <Label>{t("questions")}</Label>
              </Item>
            </LinkContainer>
          )}
          {!userInfo["isJournalist"] && (
            <LinkContainer to="/dashboard/alerts">
              <Item selected={selected === 2} onClick={() => onChange(2)}>
                <Image
                  src={
                    require(selected === 2
                      ? "../images/icon6act.svg"
                      : "../images/icon6.svg").default
                  }
                  alt=""
                />
                <Label>{t("alerts")}</Label>
                {count > 0 && <Number>{count > 99 ? "+99" : count}</Number>}
              </Item>
            </LinkContainer>
          )}
          {!userInfo["isJournalist"] && (
            <LinkContainer to="/dashboard/videos">
              <Item selected={selected === 11} onClick={() => onChange(11)}>
                <Image
                  src={
                    require(selected === 11
                      ? "../images/icon3act.svg"
                      : "../images/icon3.svg").default
                  }
                  alt=""
                />
                <Label>{t("videos")}</Label>
              </Item>
            </LinkContainer>
          )}
          {!userInfo["isJournalist"] && (
            <LinkContainer to="/dashboard/new-question">
              <Item selected={selected === 3} onClick={() => onChange(3)}>
                <Image
                  src={
                    require(selected === 3
                      ? "../images/icon2act.svg"
                      : "../images/icon2.svg").default
                  }
                  alt=""
                />
                <Label>{t("newQ")}</Label>
              </Item>
            </LinkContainer>
          )}
          {userInfo["isAdmin"] && (
            <LinkContainer to="/dashboard/products">
              <Item selected={selected === 4} onClick={() => onChange(4)}>
                <Image
                  src={
                    require(selected === 4
                      ? "../images/icon5act.svg"
                      : "../images/icon5.svg").default
                  }
                  alt=""
                />
                <Label>{t("newP")}</Label>
              </Item>
            </LinkContainer>
          )}
          <LinkContainer
            to={userInfo["isJournalist"] ? "/dashboard/" : "/dashboard/news"}
          >
            <Item selected={selected === 5} onClick={() => onChange(5)}>
              <Image
                src={
                  require(selected === 5
                    ? "../images/icon6act.svg"
                    : "../images/icon6.svg").default
                }
                alt=""
              />
              <Label>{t("newN")}</Label>
            </Item>
          </LinkContainer>
          {!userInfo["isAdmin"] && (
            <LinkContainer to={"/dashboard/password"}>
              <Item selected={selected === 8} onClick={() => onChange(8)}>
                <Image
                  src={
                    require(selected === 8
                      ? "../images/icon9act.svg"
                      : "../images/icon9.svg").default
                  }
                  alt=""
                />
                <Label>{t("settings")}</Label>
              </Item>
            </LinkContainer>
          )}

          {userInfo["isAdmin"] && (
            <LinkContainer to="/dashboard/supervisors">
              <Item selected={selected === 6} onClick={() => onChange(6)}>
                <Image
                  src={
                    require(selected === 6
                      ? "../images/icon9act.svg"
                      : "../images/icon9.svg").default
                  }
                  alt=""
                />
                <Label>{t("supervisors")}</Label>
              </Item>
            </LinkContainer>
          )}
          {userInfo["isAdmin"] && (
            <LinkContainer to="/dashboard/moderator">
              <Item selected={selected === 7} onClick={() => onChange(7)}>
                <Image
                  src={
                    require(selected === 7
                      ? "../images/icon8act.svg"
                      : "../images/icon8.svg").default
                  }
                  alt=""
                />
                <Label>{t("newS")}</Label>
              </Item>
            </LinkContainer>
          )}
          {!userInfo["isSupervisor"] && !userInfo["isJournalist"] && (
            <LinkContainer to="/dashboard/teams">
              <Item selected={selected === 10} onClick={() => onChange(10)}>
                <Image
                  src={
                    require(selected === 10
                      ? "../images/icon10act.svg"
                      : "../images/icon10.svg").default
                  }
                  alt=""
                />
                <Label>{userInfo["isAdmin"] ? t("teams") : t("teamS")}</Label>
              </Item>
            </LinkContainer>
          )}
          {/*userInfo["isAdmin"] && (
            <LinkContainer to="/dashboard/new-team">
              <Item selected={selected === 9} onClick={() => onChange(7)}>
                <Image
                  src={
                    require(selected === 9
                      ? "../images/icon11act.svg"
                      : "../images/icon11.svg").default
                  }
                  alt=""
                />
                <Label>{t("newT")}</Label>
              </Item>
            </LinkContainer>
          )*/}
          <LinkContainer to="/dashboard/login">
            <Item style={{ marginTop: "80px" }} onClick={handleLogout}>
              <Image src={require("../images/icon7.svg").default} alt="" />
              <Label>{t("logout")}</Label>
            </Item>
          </LinkContainer>
        </List>
      </Wrapper>
    </div>
  );
};
const Wrapper = styled.div`
  grid-area: sidebar;
  width: 100%;
  height: 100%;
  background-color: white;
  padding: 30px 0.8vw;
  transition: right 0.5s cubic-bezier(0.82, 0.085, 0.395, 0.895);
  @media (${QUERIES.tabletAndDown}) {
    left: ${(p) => (p.open ? "0" : "-300px")};
    transition: ${(p) => (p.open ? "left 0.5s ease" : "left 0.4s ease-out")};
    position: absolute;
    top: -80px;
    bottom: 0;
    height: calc(100% + 80px);
    width: max-content;
    z-index: 2001;
    padding: 25px 0.8vw;
  }
`;
const Number = styled.span`
  color: white;
  background-color: ${COLORS.red};
  font-family: "SF-display";
  font-weight: 600;
  font-size: 10px;
  border-radius: 4px;
  min-width: 8px;
  padding: 1.2px 3.3px;
  margin-right: 10px;
  min-width: 15px;
  text-align: center;
`;
const List = styled.ul`
  list-style: none;
  text-align: start;
  padding: 0;
  display: flex;
  flex-direction: column;
  align-items: start;
`;
const Item = styled.li`
  padding: 10px 15px;
  display: flex;
  justify-content: start;
  align-items: center;
  margin-bottom: 10px;
  background-color: ${(p) => (p.selected ? COLORS.green300 : "white")};
  color: ${(p) => (p.selected ? COLORS.primary : "black")};
  border-radius: 8px;
  width: max-content;
  cursor: pointer;
`;
const Image = styled.img`
  width: 21px;
  object-fit: fill;
`;
const Label = styled.span`
  font-family: "SF-display";
  font-size: 0.9rem;
  font-weight: 400;
  padding-left: 15px;
  user-select: none;
`;
const Close = styled.div`
  display: none;
  @media (${QUERIES.tabletAndDown}) {
    display: revert;
    padding: 0 15px;
    margin-bottom: 35px;
    cursor: pointer;
  }
`;
const BackgroundLayer = styled.div`
  display: none;
  @media (${QUERIES.tabletAndDown}) {
    display: revert;
    background-color: rgba(0, 0, 0, 0.27);
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 2000;
    top: 0;
    left: 0;
  }
`;

export default Drawer;
