import styled from "styled-components";
import React from "react";
import { COLORS } from "../contants/colors";
import { QUERIES } from "../contants/allConstants";

const Users = ({ data, title, onCloseUsersList, onClick }) => {
  return (
    <Container>
      <Wrapper>
        <Head>
          <div style={{ textAlign: "center" }}>
            <Icon
              src={require("../images/whiteClose.svg").default}
              alt=""
              onClick={onCloseUsersList}
            />
            <Title>{title}</Title>
          </div>
        </Head>
        {data.map((item) => (
          <User>
            <Row onClick={(e) => onClick(item, e)}>
              <Avatar
                src={
                  item.image !== ""
                    ? item.image
                    : require("../images/avatar.svg").default
                }
              />
              <div>
                <Name>{item.name + " " + item.familyName}</Name>
                {item.reason && <Description>{item.reason}</Description>}
              </div>
            </Row>
          </User>
        ))}
      </Wrapper>
    </Container>
  );
};
const Container = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 100%;
`;
const Wrapper = styled.div`
  position: sticky;
  background-color: red;
  top: 40%;
  margin: 0 auto;
  z-index: 20000;
  background-color: white;
  border-radius: 8px;
  max-height: 60vh;
  overflow-y: auto;
  min-width: 200px;
  max-width: max-content;
  color: ${COLORS.black27};
  font-family: "SF-display";
  font-weight: 400;
  font-size: 0.8125rem;
  box-shadow: 0 10px 30px rgba(57, 57, 57, 0.2),
    0 50px 10px 100vh rgba(57, 57, 57, 0.15);
`;
const User = styled.div`
  border-radius: 10px;
  font-family: "SF-display";
  text-align: left;
  padding: 12px;
  position: relative;
  border-bottom: 1px solid #f5f5f5;
  cursor: pointer;
  color: #272735;
  min-width: 340px;
  width: 33vw;
  margin-top: 7px;
  @media (${QUERIES.mobileAndDown}) {
    min-width: 280px;
  }
`;

const Row = styled.div`
  display: flex;
  padding: 0;
  align-items: center;
`;

const Avatar = styled.img`
  border-radius: 50%;
  height: 50px;
  width: 50px;
  object-fit: cover;
  margin-right: 15px;
`;
const Title = styled.span`
  color: white;
  font-family: "SF-display";
  font-size: 1rem;
  font-weight: 600;
  text-align: center;
  margin: 0;
  padding-top: 6px;
  vertical-align: middle;
  display: inline-block;
`;
const Head = styled.div`
  width: 100%;
  height: 38px;
  background-color: ${COLORS.primary};
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
`;
const Icon = styled.img`
  width: 10px;
  height: 10px;
  object-fit: fill;
  cursor: pointer;
  margin: 12px 16px 0 0;
  float: right;
`;
const Name = styled.p`
  font-weight: 700;
  font-size: 0.85rem;
  margin: 0;
  padding-bottom: 3px;
`;
const Description = styled.p`
  font-weight: 400;
  font-size: 0.75rem;
  margin: 0;
  padding-bottom: 3px;
`;

export default Users;
