import axios from "axios";
import {
  TEAM_LIST_REQUEST,
  TEAM_LIST_SUCCESS,
  TEAM_LIST_FAIL,
  PROXY,
  TEAM_CREATE_REQUEST,
  TEAM_CREATE_SUCCESS,
  TEAM_CREATE_FAIL,
  UPDATE_TEAM_SUCCESS,
  UPDATE_TEAM_FAIL,
  UPDATE_USER_TEAM,
  REMOVE_OWNER_SUCCESS,
  ADD_OWNER_REQUEST,
  ADD_OWNER_SUCCESS,
  ADD_OWNER_FAIL,
  DELETE_TEAM_REQUEST,
  DELETE_TEAM_SUCCESS,
  DELETE_TEAM_FAIL,
} from "../contants/allConstants";

export const getAllTeams = (token, supervisorId) => async (dispatch) => {
  try {
    dispatch({ type: TEAM_LIST_REQUEST });
    const config = {
      headers: { Authorization: `Bearer ${token}` },
      params: {
        supervisorId,
      },
    };
    const { data } = await axios.get(`${PROXY}/team`, config);
    dispatch({
      type: TEAM_LIST_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: TEAM_LIST_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const createTeam =
  (token, name, category, media, callBack) => async (dispatch) => {
    try {
      dispatch({
        type: TEAM_CREATE_REQUEST,
      });
      const config = {
        headers: { Authorization: `Bearer ${token}` },
      };
      const formData = new FormData();
      formData.append("name", name);
      formData.append("category", category);
      formData.append("file", media);

      const { data } = await axios.post(`${PROXY}/team/new`, formData, config);

      dispatch({
        type: TEAM_CREATE_SUCCESS,
        payload: data,
      });

      callBack();
    } catch (error) {
      dispatch({
        type: TEAM_CREATE_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const updateTeam =
  (token, id, name, category, media, callBack) => async (dispatch) => {
    try {
      dispatch({ type: TEAM_LIST_REQUEST });
      const config = {
        headers: { Authorization: `Bearer ${token}` },
      };
      const formData = new FormData();
      formData.append("name", name);
      formData.append("id", id);
      formData.append("category", category);
      formData.append("file", media);

      const { data } = await axios.post(
        `${PROXY}/team/update`,
        formData,
        config
      );

      dispatch({
        type: UPDATE_TEAM_SUCCESS,
        payload: data,
      });

      callBack();
    } catch (error) {
      dispatch({
        type: UPDATE_TEAM_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const removeOwner =
  (token, ownerId, teamId, callBack) => async (dispatch) => {
    try {
      dispatch({ type: TEAM_LIST_REQUEST });
      const config = {
        headers: { Authorization: `Bearer ${token}` },
      };
      const { data } = await axios.post(
        `${PROXY}/team/removeOwner`,
        { id: ownerId, teamId },
        config
      );

      // dispatch({
      //   type: REMOVE_OWNER_SUCCESS,
      //   payload: data,
      // });
      callBack();
    } catch (error) {
      dispatch({
        type: UPDATE_TEAM_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const addOwner =
  (
    token,
    newOwnerId,
    teamId,
    isSupervisor,
    isSubAdmin,
    isJournalist,
    callBack
  ) =>
  async (dispatch) => {
    try {
      dispatch({ type: ADD_OWNER_REQUEST });
      const config = {
        headers: { Authorization: `Bearer ${token}` },
      };
      const { data } = await axios.post(
        `${PROXY}/team/addOwner`,
        { id: newOwnerId, teamId, isSupervisor, isSubAdmin, isJournalist },
        config
      );

      dispatch({
        type: ADD_OWNER_SUCCESS,
        payload: data,
      });

      callBack();
    } catch (error) {
      dispatch({
        type: ADD_OWNER_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const deleteTeam = (token, id, callBack) => async (dispatch) => {
  try {
    dispatch({
      type: DELETE_TEAM_REQUEST,
    });
    const config = {
      headers: { Authorization: `Bearer ${token}` },
      data: {
        id,
      },
    };

    const { data } = await axios.delete(`${PROXY}/team`, config);
    dispatch({
      type: DELETE_TEAM_SUCCESS,
      payload: data,
    });
    callBack();
  } catch (error) {
    dispatch({
      type: DELETE_TEAM_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};
