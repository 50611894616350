import {
  PRODUCT_LIST_REQUEST,
  PRODUCT_LIST_SUCCESS,
  PRODUCT_LIST_FAIL,
  DELETE_PRODUCT_SUCCESS,
  DELETE_PRODUCT_FAIL,
  PRODUCT_CREATE_REQUEST,
  PRODUCT_CREATE_SUCCESS,
  PRODUCT_CREATE_FAIL,
  UPDATE_PRODUCT_SUCCESS,
  UPDATE_PRODUCT_FAIL,
  USER_LOGOUT,
} from "../contants/allConstants";

export const productListReducer = (
  state = { products: [], error: false, loading: false, success: false },
  action
) => {
  switch (action.type) {
    case PRODUCT_LIST_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case PRODUCT_LIST_SUCCESS:
    case UPDATE_PRODUCT_SUCCESS: {
      return {
        ...state,
        loading: false,
        products: action.payload,
        success: true,
      };
    }
    case PRODUCT_LIST_FAIL:
      return {
        ...state,
        loading: false,
        success: true,
      };
    case DELETE_PRODUCT_FAIL:
    case UPDATE_PRODUCT_FAIL: {
      return { ...state, loading: false, error: action.payload };
    }
    case DELETE_PRODUCT_SUCCESS:
      const new_products = state.products.filter(
        (p) => p["_id"] !== action.payload
      );
      return {
        ...state,
        loading: false,
        success: true,
        products: new_products,
      };
    case PRODUCT_CREATE_REQUEST:
      return {
        ...state,
        loading: true,
        error: false,
      };
    case PRODUCT_CREATE_SUCCESS:
      return {
        ...state,
        loading: false,
        products: action.payload,
        success: true,
      };
    case PRODUCT_CREATE_FAIL:
      return {
        ...state,
        loading: false,
        success: false,
        error: true,
      };
    case USER_LOGOUT:
      return {
        loading: false,
        success: false,
        products: [],
      };
    default:
      return state;
  }
};
