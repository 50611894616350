import styled from "styled-components";
import React from "react";
import { COLORS } from "../contants/colors";
import MorePopover from "./MorePopover";
import { useState } from "react";
import { useLanguageContext } from "../languageContext";

const Product = ({ id, photo, title, price, link, onDelete, onUpdate }) => {
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    setOpen(true);
  };
  const { t } = useLanguageContext();

  return (
    <Wrapper>
      <MorePopover
        showEdit={true}
        handleClick={handleClick}
        open={open}
        setOpen={setOpen}
        anchorEl={anchorEl}
        onDelete={() => onDelete(id)}
        onUpdate={onUpdate}
      />
      <Link href={link} target="_blank">
        <Photo
          src={
            photo !== "" ? photo : require("../images/placeholder.svg").default
          }
        />
        <Title>{title}</Title>
        <Price>{"السعر: " + price}</Price>
      </Link>
    </Wrapper>
  );
};
const Wrapper = styled.div`
  color: ${COLORS.black56};
  background-color: white;
  height: 205px;
  border-radius: 4px;
  font-family: "SF-display";
  text-align: left;
  font-size: 0.75rem;
  padding: 0 18px;
  box-shadow: 0 30px 60px rgba(57, 57, 57, 0.1);
  position: relative;
  border: 1px solid ${COLORS.background};
  cursor: pointer;
`;
const Link = styled.a`
  text-decoration: none;
  direction: ltr;

  &:hover {
    text-decoration: none;
  }
`;
const Photo = styled.img`
  border-radius: 4px;
  margin: 29px 0 7px 0;
  height: 115px;
  width: 100%;
  object-fit: cover;
`;
const Title = styled.p`
  font-weight: 600;
  color: black;
  margin: 0;
  padding-bottom: 5px;
  display: block;
  text-overflow: ellipsis;
  white-space: nowrap;
  word-wrap: break-word;
  overflow: hidden;
`;
const Price = styled.p`
  font-weight: 400;
  color: ${COLORS.gray900};
  margin: 0;
  direction: rtl;
  display: block;
  text-overflow: ellipsis;
  white-space: nowrap;
  word-wrap: break-word;
  overflow: hidden;
`;
export default Product;
