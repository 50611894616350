import axios from "axios";
import {
  ALERT_LIST_REQUEST,
  ALERT_LIST_SUCCESS,
  ALERT_LIST_FAIL,
  PROXY,
  DELETE_ALERT_SUCCESS,
  DELETE_ALERT_FAIL,
  OPEN_ALERT_FAIL,
  OPEN_ALERT_SUCCESS,
} from "../contants/allConstants";

export const getAllAlerts = (token, userId, type) => async (dispatch) => {
  try {
    dispatch({ type: ALERT_LIST_REQUEST });

    const config = {
      headers: { Authorization: `Bearer ${token}` },
      params: {
        type,
      },
    };
    const { data } = await axios.get(`${PROXY}/alert/all`, config);
    dispatch({
      type: ALERT_LIST_SUCCESS,
      payload: { alerts: data, userId },
    });
  } catch (error) {
    dispatch({
      type: ALERT_LIST_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const deleteAlert = (token, id) => async (dispatch) => {
  try {
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };

    const { data } = await axios.delete(`${PROXY}/alert/${id}`, config);
    dispatch({
      type: DELETE_ALERT_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: DELETE_ALERT_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const openAlerts = (token) => async (dispatch) => {
  try {
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    await axios.post(`${PROXY}/alert/open`, {}, config); //empty body
    dispatch({
      type: OPEN_ALERT_SUCCESS,
    });
  } catch (error) {
    dispatch({
      type: OPEN_ALERT_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const searchAlerts =
  (token, keyword = "", startDate = "", endDate = "", userId, type) =>
  async (dispatch) => {
    try {
      dispatch({ type: ALERT_LIST_REQUEST });
      const config = {
        headers: { Authorization: `Bearer ${token}` },
        params: {
          keyword,
          startDate,
          endDate,
          type,
        },
      };
      const { data } = await axios.get(`${PROXY}/alert/search`, config);

      dispatch({
        type: ALERT_LIST_SUCCESS,
        payload: { alerts: data, userId },
      });
    } catch (error) {
      console.log("====ALERT_LIST_FAIL", error);
      dispatch({
        type: ALERT_LIST_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };
