import i18next from "i18next";
import { initReactI18next } from "react-i18next";

//Import all translation files
import English from "./Translation/English.json";
import Italian from "./Translation/Italian.json";
import French from "./Translation/French.json";

const resources = {
  en: {
    translation: English,
  },
  It: {
    translation: Italian,
  },
  Fr: {
    translation: French,
  },
  it: {
    translation: Italian,
  },
  fr: {
    translation: French,
  },
};
const lang = localStorage.getItem("langAnisso")
  ? JSON.parse(localStorage.getItem("langAnisso")).lang
  : "it";

i18next.use(initReactI18next).init({
  resources,
  lng: lang || "it", //default language
});

export default i18next;
