const BREAKPOINTS = {
  mobileMax: 600,
  tabletMax: 950,
  laptopMax: 1300,
  exception: 870,
};

export const QUERIES = {
  mobileAndDown: `(max-width: ${BREAKPOINTS.mobileMax / 16}rem)`,
  tabletAndDown: `(max-width: ${BREAKPOINTS.tabletMax / 16}rem)`,
  laptopAndDown: `(max-width: ${BREAKPOINTS.laptopMax / 16}rem)`,
  exceptionAndDown: `(max-width: ${BREAKPOINTS.exception / 16}rem)`,
};

export const USER_LOGIN_REQUEST = "USER_LOGIN_REQUEST";
export const USER_LOGIN_SUCCESS = "USER_LOGIN_SUCCESS";
export const USER_LOGIN_FAIL = "USER_LOGIN_FAIL";
export const USER_LOGOUT = "USER_LOGOUT";
export const UPDATE_USER_TEAM = "UPDATE_USER_TEAM";
export const DELETE_USER_SUCCESS = "DELETE_USER_SUCCESS";
export const DELETE_USER_FAIL = "DELETE_USER_FAIL";
export const DELETE_USER_REQUEST = "DELETE_USER_REQUEST";
export const AVAIL_SUPRVISERS_LIST_REQUEST = "AVAIL_SUPRVISERS_LIST_REQUEST";
export const AVAIL_SUPRVISERS_LIST_SUCCESS = "AVAIL_SUPRVISERS_LIST_SUCCESS";
export const AVAIL_SUPRVISERS_LIST_FAIL = "AVAIL_SUPRVISERS_LIST_FAIL";
export const AVAIL_SUBADMINS_LIST_REQUEST = "AVAIL_SUBADMINS_LIST_REQUEST";
export const AVAIL_SUBADMINS_LIST_SUCCESS = "AVAIL_SUBADMINS_LIST_SUCCESS";
export const AVAIL_SUBADMINS_LIST_FAIL = "AVAIL_SUBADMINS_LIST_FAIL";
export const UPDATE_PASSWORD_SUCCESS = "UPDATE_PASSWORD_SUCCESS";

export const MOBILE_USERS_LIST_REQUEST = "MOBILE_USERS_LIST_REQUEST";
export const MOBILE_USERS_LIST_SUCCESS = "MOBILE_USERS_LIST_SUCCESS";
export const MOBILE_USERS_LIST_FAIL = "MOBILE_USERS_LIST_FAIL";
export const SET_USERS_BACK_DATA = "SET_USERS_BACK_DATA";

export const QUESTION_LIST_REQUEST = "QUESTION_LIST_REQUEST";
export const QUESTION_LIST_SUCCESS = "QUESTION_LIST_SUCCESS";
export const QUESTION_LIST_FAIL = "QUESTION_LIST_FAIL";
export const DELETE_QUESTION_SUCCESS = "DELETE_QUESTION_SUCCESS";
export const DELETE_QUESTION_FAIL = "DELETE_QUESTION_FAIL";
export const QUESTION_CREATE_REQUEST = "QUESTION_CREATE_REQUEST";
export const QUESTION_CREATE_SUCCESS = "QUESTION_CREATE_SUCCESS";
export const QUESTION_CREATE_FAIL = "QUESTION_CREATE_FAIL";

export const NEWS_LIST_REQUEST = "NEWS_LIST_REQUEST";
export const NEWS_LIST_SUCCESS = "NEWS_LIST_SUCCESS";
export const NEWS_LIST_FAIL = "NEWS_LIST_FAIL";
export const DELETE_NEWS_SUCCESS = "DELETE_NEWS_SUCCESS";
export const DELETE_NEWS_FAIL = "DELETE_NEWS_FAIL";
export const NEWS_CREATE_REQUEST = "NEWS_CREATE_REQUEST";
export const NEWS_CREATE_SUCCESS = "NEWS_CREATE_SUCCESS";
export const NEWS_CREATE_FAIL = "NEWS_CREATE_FAIL";
export const UPDATE_NEWS_SUCCESS = "UPDATE_NEWS_SUCCESS";
export const UPDATE_NEWS_FAIL = "UPDATE_NEWS_FAIL";
export const ADD_COMMENT_REQUEST = "ADD_COMMENT_REQUEST";
export const ADD_COMMENT_SUCCESS = "ADD_COMMENT_SUCCESS";
export const ADD_COMMENT_FAIL = "ADD_COMMENT_FAIL";
export const DELETE_COMMENT_REQUEST = "DELETE_COMMENT_REQUEST";
export const DELETE_COMMENT_SUCCESS = "DELETE_COMMENT_SUCCESS";
export const DELETE_COMMENT_FAIL = "DELETE_COMMENT_FAIL";

export const PRODUCT_LIST_REQUEST = "PRODUCT_LIST_REQUEST";
export const PRODUCT_LIST_SUCCESS = "PRODUCT_LIST_SUCCESS";
export const PRODUCT_LIST_FAIL = "PRODUCT_LIST_FAIL";
export const DELETE_PRODUCT_SUCCESS = "DELETE_PRODUCT_SUCCESS";
export const DELETE_PRODUCT_FAIL = "DELETE_PRODUCT_FAIL";
export const PRODUCT_CREATE_REQUEST = "PRODUCT_CREATE_REQUEST";
export const PRODUCT_CREATE_SUCCESS = "PRODUCT_CREATE_SUCCESS";
export const PRODUCT_CREATE_FAIL = "PRODUCT_CREATE_FAIL";
export const UPDATE_PRODUCT_SUCCESS = "UPDATE_PRODUCT_SUCCESS";
export const UPDATE_PRODUCT_FAIL = "UPDATE_PRODUCT_FAIL";

export const ALERT_LIST_REQUEST = "ALERT_LIST_REQUEST";
export const ALERT_LIST_SUCCESS = "ALERT_LIST_SUCCESS";
export const ALERT_LIST_FAIL = "ALERT_LIST_FAIL";
export const DELETE_ALERT_SUCCESS = "DELETE_ALERT_SUCCESS";
export const DELETE_ALERT_FAIL = "DELETE_ALERT_FAIL";
export const OPEN_ALERT_SUCCESS = "OPEN_ALERT_SUCCESS";
export const OPEN_ALERT_FAIL = "OPEN_ALERT_FAIL";

export const NOTIFICATION_LIST_REQUEST = "NOTIFICATION_LIST_REQUEST";
export const NOTIFICATION_LIST_SUCCESS = "NOTIFICATION_LIST_SUCCESS";
export const NOTIFICATION_LIST_FAIL = "NOTIFICATION_LIST_FAIL";
export const OPEN_NOTIFICATION_SUCCESS = "OPEN_NOTIFICATION_SUCCESS";
export const OPEN_NOTIFICATION_FAIL = "OPEN_NOTIFICATION_FAIL";

export const TEAM_LIST_REQUEST = "TEAM_LIST_REQUEST";
export const TEAM_LIST_SUCCESS = "TEAM_LIST_SUCCESS";
export const TEAM_LIST_FAIL = "TEAM_LIST_FAIL";
export const TEAM_CREATE_REQUEST = "TEAM_CREATE_REQUEST";
export const TEAM_CREATE_SUCCESS = "TEAM_CREATE_SUCCESS";
export const TEAM_CREATE_FAIL = "TEAM_CREATE_FAIL";
export const UPDATE_TEAM_SUCCESS = "UPDATE_TEAM_SUCCESS";
export const UPDATE_TEAM_FAIL = "UPDATE_TEAM_FAIL";
export const TEAM_UPDATE_REQUEST = "TEAM_UPDATE_REQUEST";
export const REMOVE_OWNER_SUCCESS = "REMOVE_OWNER_SUCCESS";
export const ADD_OWNER_SUCCESS = "ADD_OWNER_SUCCESS";
export const ADD_OWNER_REQUEST = "ADD_OWNER_REQUEST";
export const ADD_OWNER_FAIL = "ADD_OWNER_FAIL";

export const SUPERVISOR_CREATE_REQUEST = "SUPERVISOR_CREATE_REQUEST";
export const SUPERVISOR_CREATE_SUCCESS = "SUPERVISOR_CREATE_SUCCESS";
export const SUPERVISOR_CREATE_FAIL = "SUPERVISOR_CREATE_FAIL";

export const CLASS_LIST_REQUEST = "CLASS_LIST_REQUEST";
export const CLASS_LIST_SUCCESS = "CLASS_LIST_SUCCESS";
export const CLASS_LIST_FAIL = "CLASS_LIST_FAIL";

export const COURSE_CREATE_REQUEST = "COURSE_CREATE_REQUEST";
export const COURSE_CREATE_SUCCESS = "COURSE_CREATE_SUCCESS";
export const COURSE_CREATE_FAIL = "COURSE_CREATE_FAIL";

export const EVENTS_LIST_REQUEST = "EVENTS_LIST_REQUEST";
export const EVENTS_LIST_SUCCESS = "EVENTS_LIST_SUCCESS";
export const EVENTS_LIST_FAIL = "EVENTS_LIST_FAIL";

export const EVENT_CREATE_REQUEST = "EVENT_CREATE_REQUEST";
export const EVENT_CREATE_SUCCESS = "EVENT_CREATE_SUCCESS";
export const EVENT_CREATE_FAIL = "EVENT_CREATE_FAIL";
export const MOBILE_USERS_CREATE_REQUEST = "MOBILE_USERS_CREATE_REQUEST";
export const MOBILE_USERS_CREATE_SUCCESS = "MOBILE_USERS_CREATE_SUCCESS";
export const MOBILE_USERS_CREATE_FAIL = "MOBILE_USERS_CREATE_FAIL";

export const SUPERVISORS_LIST_REQUEST = "SUPERVISORS_LIST_REQUEST";
export const SUPERVISORS_LIST_SUCCESS = "SUPERVISORS_LIST_SUCCESS";
export const SUPERVISORS_LIST_FAIL = "SUPERVISORS_LIST_FAIL";
export const SUPERVISORS_DELETE_REQUEST = "SUPERVISORS_DELETE_REQUEST";
export const SUPERVISORS_DELETE_SUCCESS = "SUPERVISORS_DELETE_SUCCESS";
export const SUPERVISORS_DELETE_FAIL = "SUPERVISORS_DELETE_FAIL";

export const BLOCK_USER_REQUEST = "BLOCK_USER_REQUEST";
export const BLOCK_USER_SUCCESS = "BLOCK_USER_SUCCESS";
export const BLOCK_USER_FAIL = "BLOCK_USER_FAIL";

export const DELETE_TEAM_SUCCESS = "DELETE_TEAM_SUCCESS";
export const DELETE_TEAM_REQUEST = "DELETE_TEAM_REQUEST";
export const DELETE_TEAM_FAIL = "DELETE_TEAM_FAIL";

export const PROXY = "https://anisso-app-9fiq2.ondigitalocean.app/api";
export const SOCKET_URL = "https://anisso-app-9fiq2.ondigitalocean.app";
//"http://localhost:8000";
//https://seahorse-app-ta5mn.ondigitalocean.app
export const PAGINATION_LIMIT = 5;

export const RADIUS = [
  { value: "3", name: "3 " },
  { value: "6", name: "6 " },
  { value: "9", name: "9 " },
  { value: "12", name: "12 " },
  { value: "18", name: "18 " },
  { value: "30", name: "30 " },
  { value: "60", name: "60 " },
  { value: "100", name: "100 " },
  { value: "180", name: "180 " },
  { value: "300", name: "300 " },
];

export const symbolMap = {
  1: "1",
  2: "2",
  3: "3",
  4: "4",
  5: "5",
  6: "6",
  7: "7",
  8: "8",
  9: "9",
  0: "0",
};
export const numberMap = {
  "١": "1",
  "٢": "2",
  "٣": "3",
  "٤": "4",
  "٥": "5",
  "٦": "6",
  "٧": "7",
  "٨": "8",
  "٩": "9",
  "٠": "0",
};
