import styled from "styled-components";
import React, { useState } from "react";
import { CircularProgressbar } from "react-circular-progressbar";
import { COLORS } from "../contants/colors";
import MorePopover from "./MorePopover";
import { QUERIES } from "../contants/allConstants";
import { format } from "date-fns";
import moment from "moment";
import { useSelector } from "react-redux";
import Fade from "react-reveal/Reveal";
import TransitionGroup from "react-transition-group/TransitionGroup";
import { useLanguageContext } from "../languageContext";

const BigQuestion = ({ question, onDelete, onClick }) => {
  const { userInfo } = useSelector((state) => state.userLogin);
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const { t } = useLanguageContext();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    setOpen(true);
  };
  const firstAnswerPercent =
    question["answers"][0]["users"].length > 0
      ? Math.round(
          (question["answers"][0]["users"].length * 100) /
            (question["answers"][0]["users"].length +
              question["answers"][1]["users"].length)
        )
      : 0;
  const firstAnswerNbr = question["answers"][0]["users"].length; //v.1.1
  return (
    <TransitionGroup appear exit enter>
      {/* <Fade exit bottom duration={100}> */}
      <Question>
        <Body>
          <Wrapper>
            <Pie>
              <CircularProgressbar
                value={firstAnswerPercent}
                text={`%${firstAnswerPercent}`}
                strokeWidth={20}
                styles={{
                  path: {
                    stroke: COLORS.success,
                    fill: COLORS.success,
                  },
                  trail: {
                    stroke: COLORS.green30,
                  },
                  text: {
                    fill: "black",
                    fontSize: "15px",
                    fontFamily: "Montserrat",
                    fontWeight: 700,
                  },
                  background: {
                    fill: COLORS.success,
                  },
                }}
              />
            </Pie>
            <Row>
              <Answer>
                <Dot />
                <span>{question["answers"][0]["description"]}</span>
              </Answer>
              <div style={{ width: "8px" }} />
              <Answer>
                <LightDot />
                <span>{question["answers"][1]["description"]}</span>
              </Answer>
            </Row>
          </Wrapper>

          <Labels>
            <Title>{question["title"] ? question["title"] : "السؤال 1"}</Title>
            {question["subject"] && <Subtitle>{question["subject"]}</Subtitle>}
            <Description>{question["description"]}</Description>
            <Result>
              {firstAnswerNbr +
                t("answer") +
                " :" +
                question["answers"][0]["description"]}
            </Result>
            <Result>
              {question["answers"][1]["users"].length +
                t("answer") +
                ": " +
                question["answers"][1]["description"]}
            </Result>

            <Footer>
              {question["company"] && (
                <div>
                  <Strong>{t("company")}</Strong>
                  <p>{question["company"]}</p>
                </div>
              )}
              <div style={{ padding: "0 5px" }}>
                <Strong>{t("dateShare")}</Strong>
                <p>
                  {format(
                    new Date(question["createdAt"]),
                    "dd/MM/yyyy - HH:mm"
                  )}
                </p>
              </div>
              <div>
                <Strong>{t("ending")}</Strong>
                <p>
                  {new Date(question["expireAt"]) < new Date()
                    ? t("ended")
                    : ""}
                  {moment
                    .utc(question["expireAt"])
                    .local()
                    .endOf("seconds")
                    .fromNow()}
                </p>
              </div>
            </Footer>
          </Labels>
          <MorePopover
            onDelete={() => onDelete(question["_id"])}
            canDelete={userInfo.isAdmin}
            showDetails={true}
            onOpenDetails={() => onClick(question)}
            handleClick={handleClick}
            open={open}
            setOpen={setOpen}
            anchorEl={anchorEl}
            top={8}
          />
        </Body>
      </Question>
      {/* </Fade> */}
    </TransitionGroup>
  );
};
const Question = styled.div`
  background-color: white;
  border-radius: 15px;
  margin-top: 20px;
`;
const Body = styled.div`
  display: flex;
  position: relative;
  padding: 32px 20px;

  @media (${QUERIES.mobileAndDown}) {
    flex-direction: column;
  }
`;
const Footer = styled.div`
  padding-top: 20px;
  color: ${COLORS.gray30};
  font-family: "SF-display";
  font-size: 0.75rem;
  font-weight: 400;
  display: flex;
  justify-content: space-between;
  width: 90%;
`;
const Strong = styled.span`
  color: ${COLORS.gray550};
  font-size: 0.75rem;
  font-weight: 400;
  margin-right: 5px;
`;

const Wrapper = styled.div`
  width: 30vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-right: 0.67px solid ${COLORS.gray700};
  text-align: left;
  padding-right: 20px;
  @media (${QUERIES.mobileAndDown}) {
    width: 100%;
    border: none;
    padding-right: 0;
  }
`;
const Pie = styled.div`
  width: 136px;
  margin-bottom: 27px;
`;
const Answer = styled.div`
  display: flex;
  align-items: center;
  text-align: left;
  max-width: max-content;
  overflow-wrap: anywhere;
`;
const Row = styled.div`
  width: 100%;
  display: flex;
  font-family: "SF-display";
  font-size: 1rem;
  font-weight: 500;
  color: black;
  align-items: center;
  justify-content: space-between;
  text-align: left;
`;
const Dot = styled.span`
  height: 25px;
  min-width: 25px;
  background-color: ${COLORS.success};
  border-radius: 50%;
  display: inline-block;
  margin-right: 8px;
  text-align: left;
`;
const LightDot = styled(Dot)`
  background-color: ${COLORS.green30};
`;

const Labels = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  color: black;
  font-family: "SF-display";
  font-size: 1rem;
  align-items: flex-start;
  justify-content: center;
  padding-left: 20px;
  text-align: left;
  width: 100%;
  text-align: left;
  @media (${QUERIES.mobileAndDown}) {
    padding-left: 0;
    margin-top: 20px;
  }
`;

const Title = styled.span`
  font-weight: 700;
  text-align: left;
  font-size: 1.1rem;
`;
const Subtitle = styled.div`
  font-weight: 600;
  padding-top: 10px;
  text-align: left;
`;

const Description = styled.div`
  font-weight: 400;
  font-size: 0.9rem;
  /* padding-top: 13px; */
  text-align: left;
`;
const Result = styled.div`
  font-weight: 500;
  padding-top: 13px;
  text-align: left;
`;
export default BigQuestion;
