import styled from "styled-components";
import React from "react";
import { COLORS } from "../contants/colors";
import { QUERIES } from "../contants/allConstants";
import { pink } from "@mui/material/colors";
import Radio from "@mui/material/Radio";
import FormControl from "@mui/material/FormControl";
import RadioGroup from "@mui/material/RadioGroup";
import { useLanguageContext } from "../languageContext";
// eslint-disable-next-line react-hooks/rules-of-hooks

const OwnersSelection = ({
  showRadioBtn = true,
  data,
  selectedValue,
  onClick,
}) => {
  const { t } = useLanguageContext();

  return (
    <FormControl style={{ overflowY: "auto", maxHeight: "300px" }}>
      <RadioGroup
        aria-labelledby="demo-controlled-radio-buttons-group"
        name="controlled-radio-buttons-group"
        value={selectedValue}
        onChange={() => {}}
      >
        {data.map((item) => (
          <User size={showRadioBtn ? "large" : "small"}>
            <Row onClick={(e) => onClick(item, e)}>
              {showRadioBtn && (
                <Radio
                  checked={selectedValue === item["_id"]}
                  onChange={(value) => {
                    onClick(item["_id"]);
                  }}
                  value={item["_id"]}
                  sx={{
                    color: COLORS.gray50,
                    "&.Mui-checked": {
                      color: COLORS.primary,
                    },
                  }}
                />
              )}
              <Avatar
                src={
                  item.image !== ""
                    ? item.image
                    : require("../images/avatar.svg").default
                }
              />
              <div>
                <Name>
                  {item.name + " "}
                  {item.familyName ? item.familyName : ""}
                </Name>
                {item.country && item.state && (
                  <Description>{item.country + ", " + item.state}</Description>
                )}
                <Description>
                  {item.category
                    ? item.category
                    : t("join") + " : " + item.createdAt.split("T")[0]}
                </Description>
              </div>
            </Row>
          </User>
        ))}
      </RadioGroup>
    </FormControl>
  );
};

const User = styled.div`
  border-radius: 10px;
  font-family: "SF-display";
  text-align: left;
  padding: 12px;
  position: relative;
  border-bottom: 1px solid #f5f5f5;
  cursor: pointer;
  color: #272735;
  min-width: 340px;
  width: ${(p) => (p.size === "small" ? "58vw" : "78vw")};
  max-width: 650px;
  margin-top: 7px;
  max-height: 41vh;
  overflow-y: hidden;
  /* ${(p) => (p.size === "small" ? "hidden" : "scroll")}; */
  @media (${QUERIES.mobileAndDown}) {
    min-width: 280px;
  }
`;

const Row = styled.div`
  display: flex;
  padding: 0;
  align-items: center;
`;

const Avatar = styled.img`
  border-radius: 50%;
  height: 60px;
  width: 60px;
  object-fit: cover;
  margin-right: 15px;
`;

const Name = styled.p`
  font-weight: 700;
  font-size: 0.85rem;
  margin: 0;
  padding-bottom: 3px;
`;
const Description = styled.p`
  font-weight: 400;
  font-size: 0.75rem;
  margin: 0;
  padding-bottom: 3px;
`;

export default OwnersSelection;
