import styled from "styled-components";
import React, { useEffect } from "react";
import { COLORS } from "../contants/colors";
import MorePopover from "./MorePopover";
import { useState } from "react";
import { useSelector } from "react-redux";
import ReactPlayer from "react-player";
import { useLanguageContext } from "../languageContext";
import moment from "moment";

const News = ({
  id,
  media,
  title,
  live,
  description,
  creator,
  expireAt,
  onClick,
  onDelete,
  onUpdate,
}) => {
  const { t } = useLanguageContext();

  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const { userInfo } = useSelector((state) => state.userLogin);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    setOpen(true);
  };

  return (
    <Wrapper>
      <MorePopover
        showEdit={true}
        // canDelete={userInfo.isAdmin}
        handleClick={handleClick}
        open={open}
        setOpen={setOpen}
        anchorEl={anchorEl}
        onDelete={() => onDelete(id)}
        onUpdate={onUpdate}
      />
      {live !== "" ? (
        <LiveHolder>
          <ReactPlayer
            width={"100%"}
            height={"100%"}
            url={live}
            controls={true}
          />
        </LiveHolder>
      ) : media.includes(".mp3") || media.includes(".wav") ? (
        <Audio controls src={media} onClick={onClick}></Audio>
      ) : media.includes(".png") ||
        media.includes(".jpeg") ||
        media.includes(".jpg") ? (
        <Photo
          onClick={onClick}
          src={
            media !== "" ? media : require("../images/placeholder.svg").default
          }
        />
      ) : (
        <VideoPreview
          width="85%"
          height="85%"
          controls
          key={media}
          onClick={onClick}
        >
          <source src={media} type="video/mp4" key={media} />
        </VideoPreview>
      )}
      <Title onClick={onClick}>{title}</Title>
      <Description onClick={onClick}>{description}</Description>
      {creator && (
        <Strong>
          {t("publishedFrom") +
            " : " +
            creator["name"] +
            " " +
            creator["familyName"]}
        </Strong>
      )}
      {/* <Strong>
        {t("ending")}
        <span>
          {new Date(expireAt) < new Date() ? t("ended") : ""}
          {moment.utc(expireAt).local().endOf("seconds").fromNow()}
        </span>
      </Strong> */}
    </Wrapper>
  );
};
const LiveHolder = styled.div`
  width: 100%;
  height: 115px;
  margin: 29px 0 7px 0;
  object-fit: cover;
  vertical-align: middle;
  border-radius: 4px;
`;
const Wrapper = styled.div`
  color: ${COLORS.black56};
  background-color: white;
  max-height: 300px;
  border-radius: 4px;
  font-family: "SF-display";
  text-align: left;
  font-size: 0.75rem;
  padding: 0 18px 13px 18px;
  box-shadow: 0 30px 60px rgba(57, 57, 57, 0.1);
  position: relative;
  border: 1px solid ${COLORS.background};
  cursor: pointer;
  color: black;
`;

const Audio = styled.audio`
  width: 100%;
  /* height: 70%; */
  background-color: #f1f3f4;
  border-radius: 4px;
  margin: 29px 0 7px 0;
  border: 1px solid #ccc;
`;

const VideoPreview = styled.video`
  width: 100%;
  height: 115px;
  margin: 29px 0 7px 0;
  object-fit: cover;
  vertical-align: middle;
  border-radius: 4px;
`;
const Photo = styled.img`
  border-radius: 4px;
  margin: 29px 0 7px 0;
  height: 115px;
  width: 100%;
  object-fit: cover;
`;
const Title = styled.p`
  font-weight: 700;
  margin: 0;
  padding-bottom: 5px;
`;
const Description = styled.p`
  font-weight: 400;
  margin: 0;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  direction: rtl;
`;
const Strong = styled.div`
  color: ${COLORS.gray30};
  font-size: 0.65rem;
  font-weight: 400;
  margin-right: 5px;
  margin-top: 5px;
`;
export default News;
