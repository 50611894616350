import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import { login } from "../actions/userActions";
import background from "../images/bglogin.svg";
import Message from "../components/Message";
import Spinner from "react-bootstrap/Spinner";
import { COLORS } from "../contants/colors";
import { QUERIES } from "../contants/allConstants";
import emailjs from "emailjs-com";
import { init } from "emailjs-com";
import { set } from "lodash";
import { useLanguageContext } from "../languageContext";
init("user_2ZbpogSGRhtGvYaOZCco7");

const SupportScreen = ({ history }) => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [emailSent, setEmailSent] = useState(false);
  const [error, setError] = useState("");
  const [loader, setLoader] = useState(false);
  const { t } = useLanguageContext();

  const submit = () => {
    if (!isValidEmail(email)) {
      setError("Please enter a valid email");
    } else if (name && email && message) {
      setLoader(true);

      const serviceId = "service_peu5ign";
      const templateId = "template_jnmlw53";
      const userId = "user_2ZbpogSGRhtGvYaOZCco7";
      const templateParams = {
        name,
        email,
        message,
      };

      emailjs
        .send(serviceId, templateId, templateParams, userId)
        .then((response) => {
          setName("");
          setEmail("");
          setMessage("");
          setEmailSent(true);
          setLoader(false);
        })
        .then((error) => {
          console.log(error);
          setLoader(false);
        });
    } else {
      setError("Please fill in all fields.");
    }
  };
  const isValidEmail = (email) => {
    const regex =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return regex.test(String(email).toLowerCase());
  };

  return (
    <Wrapper img={background}>
      <Container>
        <Form>
          <Icon src={require("../images/greenlogo.svg").default} alt="" />
          <Subtitle>
            {
              "Please don't hesitate to contact us about any question you might be interested in."
            }
          </Subtitle>

          <InputWrapper>
            <Label>{"Name"}</Label>
            <Input
              value={name}
              type="Name"
              onChange={(e) => {
                setName(e.target.value);
                setEmailSent(false);
                setError("");
              }}
            />
          </InputWrapper>
          <InputWrapper>
            <Label>{"Email"}</Label>
            <Input
              value={email}
              type="email"
              onChange={(e) => {
                setEmail(e.target.value);
                setEmailSent(false);
                setError("");
              }}
            />
          </InputWrapper>
          <InputWrapper>
            <Label>{"Your Message"}</Label>
            <TextArea
              value={message}
              onChange={(e) => {
                setMessage(e.target.value);
                setEmailSent(false);
                setError("");
              }}
            />
          </InputWrapper>
          <Button type="submit" onClick={submit}>
            {loader ? <Spinner animation="border" variant="light" /> : "Submit"}
          </Button>
          {emailSent && (
            <span style={{ marginTop: "20px", textAlign: "center" }}>
              Thank you for your message, we will be in touch in no time!
            </span>
          )}
          {error && <Error variant="danger">{error}</Error>}
        </Form>
      </Container>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  background-image: url(${(props) => props.img});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Rady";
`;
const Container = styled.div`
  width: 90vw;
  height: 90vh;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0px 30px 60px 4px rgba(57, 57, 57, 0.3);
  display: flex;
  padding: 25px;
  justify-content: space-evenly;
  @media (${QUERIES.tabletAndDown}) {
    flex-direction: column;
    align-items: center;
    /* justify-content: space-between; */
    padding: 30px 25px 40px 25px;
  }
`;
const Icon = styled.img`
  width: 161px;
  object-fit: fill;
  margin: 20px;
`;
const Image = styled.img`
  width: 50%;
  object-fit: fill;
  @media (${QUERIES.tabletAndDown}) {
    height: 40%;
    width: 100%;
    margin-bottom: 10px;
  }
`;

const Form = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  max-width: 400px;
`;

const Button = styled.button`
  background-color: ${COLORS.success};
  border-radius: 8px;
  font-size: 0.975rem;
  color: white;
  font-weight: 700;
  width: 100%;
  height: 48px;
  border: none;
  /* margin-top: 25px; */
  &:hover {
    outline: none;
    background-color: ${COLORS.success02};
  }
  &:focus {
    outline: none;
  }
  @media (${QUERIES.mobileAndDown}) {
    margin-top: 15px;
  }
`;

const InputWrapper = styled.div`
  border: 1px solid ${COLORS.gray20};
  border-radius: 4px;
  padding: 10px 12px;
  width: 100%;
  margin-bottom: 30px;
  position: relative;
  @media (${QUERIES.mobileAndDown}) {
    margin-bottom: 20px;
  }
`;
const Subtitle = styled.p`
  font-size: 0.9rem;
  color: ${COLORS.black56};
  font-weight: 400;
  margin: 0 0 14px 0;
  text-align: center;
`;
const Error = styled.p`
  color: #e11919;
  margin-top: 20px;
`;

const Label = styled.p`
  font-size: 0.6875rem;
  color: ${COLORS.black56};
  font-weight: 400;
  margin: 0 0 4px 0;
`;
const Input = styled.input`
  font-size: 0.875rem;
  color: ${COLORS.black56};
  font-weight: 700;
  border: none;
  width: 100%;

  &:focus {
    border: none;
    outline: none;
  }
`;
const TextArea = styled.textarea`
  font-size: 0.875rem;
  color: ${COLORS.black56};
  font-weight: 700;
  border: none;
  width: 100%;
  height: 100px;

  &:focus {
    border: none;
    outline: none;
  }
`;
export default SupportScreen;
