import styled from "styled-components";
import React from "react";
import { COLORS } from "../contants/colors";
import { QUERIES } from "../contants/allConstants";

const WhiteBtn = ({
  disabled = false,
  title,
  onClick,
  size = "big",
  bold = true,
}) => {
  return (
    <Button disabled={disabled} onClick={onClick} size={size} bold={bold}>
      {title}
    </Button>
  );
};
const Button = styled.button`
  color: ${COLORS.black56};
  border-radius: 4px;
  font-family: "SF-display";
  font-size: ${(p) => (p.size === "small" ? "0.92rem" : "1rem")};
  font-weight: ${(p) => (p.bold ? 700 : 400)};
  background-color: white;
  border: none;
  height: ${(p) => (p.size === "small" ? 32 : 36)}px;
  margin-top: 16px;
  border: 1px solid ${COLORS.gray100};
  width: ${(p) => (p.size === "small" ? 44 : 48)}%;
  &:focus {
    outline-color: ${COLORS.primary};
  }
  &:disabled {
    background-color: ${COLORS.gray200};
    border: 1px solid ${COLORS.gray200};
  }
  @media (${QUERIES.mobileAndDown}) {
    width: 100%;
  }
`;

export default WhiteBtn;
