import styled from "styled-components";
import React, { useState, useRef } from "react";
import { COLORS } from "../contants/colors";
import { Box, MenuItem, IconButton, Popover } from "@mui/material";
import { Icon } from "@iconify/react";
import DeleteButton from "./DeleteButton";
import CustomDropdown from "./CustomDropdown";
import TransitionGroup from "react-transition-group/TransitionGroup";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import Fade from "react-reveal/Reveal";
import { useLanguageContext } from "../languageContext";

const Search = ({
  border = false,
  color = "white",
  hint,
  showDelete,
  selectedState,
  onDelete,
  onChange,
  onSearch,
  states,
  onChangeState,
  onChangeEndDate,
  onChangeStartDate,
  onClearFilters,
}) => {
  const [openFilters, setOpenFilters] = useState(false);
  const [inProp, setInProp] = useState(false);
  const [start, setStart] = useState("");
  const [end, setEnd] = useState("");
  const { t } = useLanguageContext();

  return (
    <Container>
      <Wrapper>
        <div style={{ position: "relative", width: "100%" }}>
          <Loop
            src={require("../images/loop.svg").default}
            alt=""
            onClick={onSearch}
          />
          <Input
            border={border}
            color={color}
            rows="5"
            placeholder={hint}
            onChange={(e) => onChange(e.target.value)}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                onSearch();
              }
            }}
          />
        </div>

        <Button
          onClick={() => {
            setInProp(true);
            setOpenFilters(!openFilters);
          }}
        >
          <Box component={Icon} icon={"eva:funnel-outline"} />
          {t("filters")}
        </Button>
        {showDelete && <DeleteButton onClick={() => onDelete()} />}
      </Wrapper>
      {openFilters && (
        <TransitionGroup appear exit enter>
          <Fade collapse center duration={100}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <Filters border={border}>
                <Clear
                  onClick={() => {
                    setEnd("");
                    setStart("");
                    onClearFilters();
                  }}
                >
                  <Box component={Icon} icon={"eva:close-circle-fill"} />
                  <span> {t("reset")}</span>
                </Clear>
                <Components>
                  <div style={{ width: "40%" }}>
                    <Title>
                      {states.length > 0
                        ? t("joinedSince")
                        : t("publishedFrom")}
                    </Title>
                    <DatePicker
                      className="search-date"
                      onChange={(v) => {
                        setStart(v);
                        onChangeStartDate(v);
                      }}
                      slotProps={{
                        textField: {
                          size: "small",
                          error: false,
                          width: "100% !important",
                        },
                      }}
                      value={start || null}
                      format="DD-MM-YYYY"
                    />
                  </div>
                  <div style={{ width: "40%", margin: "0 10px" }}>
                    <Title>
                      {states.length > 0
                        ? t("joinedUntil")
                        : t("publishedUntil")}
                    </Title>
                    <DatePicker
                      className="search-date"
                      onChange={(v) => {
                        setEnd(v);
                        onChangeEndDate(v);
                      }}
                      value={end || null}
                      slotProps={{
                        textField: {
                          size: "small",
                          error: false,
                          width: "100% !important",
                        },
                      }}
                      format="DD-MM-YYYY"
                    />
                  </div>

                  {states.length > 0 && (
                    <div style={{ width: "35%" }}>
                      <CustomDropdown
                        title={t("state") + selectedState}
                        data={states}
                        onChange={(v) => {
                          onChangeState(v);
                        }}
                        value={selectedState}
                      />
                    </div>
                  )}
                </Components>
              </Filters>
            </LocalizationProvider>
          </Fade>
        </TransitionGroup>
      )}
    </Container>
  );
};
const Title = styled.p`
  font-family: "SF-display";
  font-size: 0.8rem;
  font-weight: 700;
  text-align: left;
  margin: 0;
  padding-bottom: 10px;
`;
const Container = styled.div`
  width: 100%;
  font-family: "SF-display";
`;
const Filters = styled.div`
  width: 100%;
  background-color: white;
  padding: 10px;
  border-radius: 6px;
  border: ${(p) => (p.border ? "1px solid #e7e7e9" : "none")};
`;
const Components = styled.div`
  width: 100%;
  background-color: white;
  padding: 0 10px;
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  margin-bottom: 5px;
  border-radius: 6px;
`;
const Wrapper = styled.div`
  color: ${COLORS.black56};
  width: 100%;
  margin-bottom: 5px;
  display: flex;
  /* padding: ${(p) => (p.color === "white" ? 0 : "30px 0 0 0")}; */
`;
const Loop = styled.img`
  width: 19px;
  height: 19px;
  object-fit: fill;
  cursor: pointer;
  float: left;
  margin: 12px 14px;
  position: absolute;
  top: 0;
  left: 0;
`;
const Clear = styled.div`
  font-size: 0.75rem;
  font-weight: 400;
  cursor: pointer;
  padding: 10px;
`;
const Input = styled.input`
  width: 100%;
  height: 38px;
  border: ${(p) => (p.border ? "1px solid #e7e7e9" : "none")};
  border-radius: 6px;
  font-size: 0.8rem;
  font-weight: 400;
  padding: 10px;
  text-align: left;
  padding-left: 43px;
  background-color: ${(p) => (p.color === "white" ? "white" : "#f6f6f6")};

  &::placeholder {
    color: ${COLORS.gray30};
  }
  &:focus {
    outline-color: ${COLORS.lightBlue};
  }
`;
const Button = styled.button`
  border-radius: 6px;
  background-color: white;
  border: 1.5px solid #e7e7e9;
  /* height: 36px; */
  width: 102px;
  color: #0d0c22;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px 11px;
  margin-left: 10px;
  font-size: 0.82rem;
  &:focus {
    outline-color: ${COLORS.primary};
  }
`;
export default Search;
