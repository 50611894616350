import styled from "styled-components";
import React from "react";
import { COLORS } from "../contants/colors";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { useLanguageContext } from "../languageContext";

const CustomDropdown = ({ title, data, style, value, onChange }) => {
  const { t } = useLanguageContext();

  return (
    <Wrapper style={{ ...style }}>
      <Title>{title}</Title>
      <Select
        size="small"
        key={value}
        value={value}
        onChange={(e) => onChange(e.target.value)}
        displayEmpty
        inputProps={{
          "aria-label": "Without label",
        }}
        style={{ height: 42 }}
      >
        <MenuItem value="">{t("all")}</MenuItem>
        {data.map((state) => (
          <MenuItem value={state}>{state}</MenuItem>
        ))}
      </Select>
    </Wrapper>
  );
};
const Wrapper = styled.div`
  color: ${COLORS.black56};
  margin-top: 13px;
  width: 100%;
`;
const Title = styled.p`
  font-family: "SF-display";
  font-size: 0.8rem;
  font-weight: 700;
  text-align: left;
  margin: 0;
  padding-bottom: 4.5px;
`;

export default CustomDropdown;
