import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Header from "../components/Header";
import Layout from "../components/Layout";
import styled from "styled-components";
import Drawer from "../components/Drawer";
import { useState } from "react";
import Accordion from "react-bootstrap/Accordion";
import BigQuestion from "../components/BigQuestion";
import SmallQuestion from "../components/SmallQuestion";
import {
  deleteQuestion,
  getQuestions,
  searchQuestion,
} from "../actions/questionActions";
import Search from "../components/Search";
import { QUERIES } from "../contants/allConstants";
import { COLORS } from "../contants/colors";
import Miniheader from "../components/Miniheader";
import Nav from "react-bootstrap/Nav";
import ResponsiveTable from "../components/ResponsiveTable";
import UserDetails from "../components/UserDetails";
import Pagination from "react-responsive-pagination";
import "bootstrap/dist/css/bootstrap.min.css";
import AgeStatistics from "../components/AgeStatistics";
import CustomPieChart from "../components/CustomPieChart";
import CitiesChart from "../components/CitiesChart";
import BelongChart from "../components/BelongChart";
import ToastContainer from "react-bootstrap/esm/ToastContainer";
import Collapse from "react-bootstrap/Collapse";
import Toast from "react-bootstrap/Toast";
import { useLanguageContext } from "../languageContext";
import moment from "moment";
import fr from "moment/locale/fr";
import it from "moment/locale/it";

const QuestionsScreen = ({ history }) => {
  const dispatch = useDispatch();
  const { questions } = useSelector((state) => state.question);
  const { userInfo } = useSelector((state) => state.userLogin);
  const [selected, setSelected] = useState(1);
  const [keyword, setKeyword] = useState("");
  const [openDrawer, setOpenDrawer] = useState(false);
  const [selectedQues, setSelectedQues] = useState(null);
  const [selectedAnswer, setSelectedAnsw] = useState(null);
  const [selectedUser, setSelectedUser] = useState(null);
  const [active, setActive] = useState(1);
  const [limit, setLimit] = useState(3);
  const [users, setUsers] = useState([]);
  const [next, setNext] = useState(1);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [message, setMessage] = useState("");
  const [toastType, setToastType] = useState("");
  const { t } = useLanguageContext();

  useEffect(() => {
    const lang = JSON.parse(localStorage.getItem("langAnisso")).lang;
    if (lang === "fr") {
      moment.locale("fr", fr);
    } else if (lang === "it") {
      moment.locale("it", it);
    } else moment.locale("en");
    dispatch(getQuestions(userInfo["token"]));
  }, []);

  useEffect(() => {
    if (selectedAnswer) {
      pageClick(1, selectedAnswer?.users);
    }
  }, [selectedAnswer]);

  const onSearchQuestions = () => {
    dispatch(searchQuestion(userInfo["token"], keyword, startDate, endDate));
  };
  const onDeleteQuestion = (id) => {
    dispatch(deleteQuestion(userInfo["token"], id));
  };

  const pageClick = async (pageNumber, users) => {
    pageNumber = pageNumber <= 0 ? 1 : pageNumber;
    const payload = users.slice((pageNumber - 1) * limit, pageNumber * limit);
    setUsers(payload);
    setActive(pageNumber);
    if (pageNumber >= next + 6) setNext(next + 1);
    if (pageNumber !== 1 && pageNumber <= next) setNext(next - 1);
  };

  useEffect(() => {
    if (keyword !== "" || startDate !== "" || endDate !== "") {
      onSearchQuestions();
    }
    if (keyword === "" && startDate === "" && endDate === "") {
      dispatch(getQuestions(userInfo["token"]));
      setActive(1);
    }
  }, [keyword, startDate, endDate]);

  return (
    <>
      {selectedUser && (
        <UserDetails
          user={selectedUser}
          onClose={() => setSelectedUser(null)}
        />
      )}

      <Layout>
        <Header setOpenDrawer={() => setOpenDrawer(!openDrawer)} />
        <Drawer
          setOpenDrawer={() => setOpenDrawer(!openDrawer)}
          open={openDrawer}
          history={history}
          selected={selected}
          onChange={(value) => setSelected(value)}
        />
        {selectedQues ? (
          <DetailsBody>
            <Miniheader
              title={t("questionDetails")}
              arrow={true}
              goBack={() => setSelectedQues(null)}
            />
            <Wrapper>
              <Subtitle>{selectedQues["description"]}</Subtitle>
              {selectedQues.media === "" ? null : selectedQues.media.includes(
                  ".mp3"
                ) || selectedQues.media.includes(".wav") ? (
                <Audio controls src={selectedQues.media}></Audio>
              ) : selectedQues.media.includes(".png") ||
                selectedQues.media.includes(".jpeg") ||
                selectedQues.media.includes(".jpg") ? (
                <Photo
                  src={
                    selectedQues.media !== ""
                      ? selectedQues.media
                      : require("../images/placeholder.svg").default
                  }
                />
              ) : (
                <VideoPreview
                  width="85%"
                  height="85%"
                  controls
                  key={selectedQues.media}
                >
                  <source
                    src={selectedQues.media}
                    type="video/mp4"
                    key={selectedQues.media}
                  />
                </VideoPreview>
              )}
              <Nav fill variant="tabs" defaultActiveKey="link-1">
                <Nav.Item>
                  <Nav.Link
                    eventKey="link-2"
                    onClick={() => setSelectedAnsw(selectedQues["answers"][1])}
                  >
                    <Result>
                      <p>
                        {selectedQues["answers"][1]["users"].length}
                        {t("answer")}'
                      </p>
                      <strong>
                        {selectedQues["answers"][1]["description"]}
                      </strong>
                      <p>'</p>
                    </Result>
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link
                    eventKey="link-1"
                    onClick={() => setSelectedAnsw(selectedQues["answers"][0])}
                  >
                    <Result>
                      <p>
                        {selectedQues["answers"][0]["users"].length}
                        {t("answer")}'
                      </p>
                      <strong>
                        {selectedQues["answers"][0]["description"]}
                      </strong>
                      <p>'</p>
                    </Result>
                  </Nav.Link>
                </Nav.Item>
              </Nav>
              {selectedAnswer["users"].length > 0 ? (
                <StatisticsBody>
                  {/* {userInfo.isAdmin && (
                    <div>
                      <ResponsiveTable
                        users={users}
                        openDetails={(user) => setSelectedUser(user)}
                        checkedList={[]}
                        hideCheckbox={true}
                      />
                      <Pagination
                        current={active}
                        total={Math.ceil(
                          Math.abs(selectedAnswer?.users.length / limit)
                        )}
                        onPageChange={(page) => {
                          pageClick(page, selectedAnswer?.users);
                        }}
                      />
                    </div>
                  )} */}
                  <div style={{ marginTop: "35px" }}>
                    <Accordion>
                      <Accordion.Item
                        eventKey={selectedAnswer.description + "0"}
                      >
                        <Accordion.Header>{t("dependAge")}</Accordion.Header>
                        <Accordion.Body>
                          <AgeStatistics users={selectedAnswer?.users} />
                        </Accordion.Body>
                      </Accordion.Item>
                      <Accordion.Item
                        eventKey={selectedAnswer.description + "1"}
                      >
                        <Accordion.Header>
                          {t("dependCountry")}
                        </Accordion.Header>
                        <Accordion.Body>
                          <CustomPieChart
                            users={selectedAnswer?.users}
                            field={"country"}
                            color={"#f77847"}
                          />
                        </Accordion.Body>
                      </Accordion.Item>
                      <Accordion.Item
                        eventKey={selectedAnswer.description + "2"}
                      >
                        <Accordion.Header>{t("dependState")}</Accordion.Header>
                        <Accordion.Body>
                          <CitiesChart users={selectedAnswer?.users} />
                        </Accordion.Body>
                      </Accordion.Item>
                      {/* <Accordion.Item
                        eventKey={selectedAnswer.description + "3"}
                      >
                        <Accordion.Header>
                          {"الإجابة حسب الانتماء"}
                        </Accordion.Header>
                        <Accordion.Body>
                          <BelongChart users={selectedAnswer?.users} />
                        </Accordion.Body>
                      </Accordion.Item> */}
                      <Accordion.Item
                        eventKey={selectedAnswer.description + "4"}
                      >
                        <Accordion.Header>{t("dependGender")}</Accordion.Header>
                        <Accordion.Body>
                          <CustomPieChart
                            users={selectedAnswer?.users}
                            field={"gender"}
                            color={"#f77847"}
                          />
                        </Accordion.Body>
                      </Accordion.Item>
                    </Accordion>
                  </div>
                </StatisticsBody>
              ) : (
                <EmptyAnswers>{t("noAnswers")}</EmptyAnswers>
              )}
            </Wrapper>
          </DetailsBody>
        ) : (
          <Body>
            <Search
              hint={t("search")}
              showDelete={false}
              states={[]}
              onSearch={() => {
                if (keyword !== "") {
                  onSearchQuestions();
                } else if (startDate !== "" || endDate !== "") {
                  onSearchQuestions();
                }
              }}
              onChange={(value) => {
                setKeyword(value);
              }}
              onChangeEndDate={(value) => {
                if (!isNaN(new Date(value).getDate())) {
                  if (new Date(value) <= new Date(startDate)) {
                    setMessage(t("startGt"));
                    setToastType("danger");
                  } else setEndDate(new Date(value));
                }
              }}
              onChangeStartDate={(value) => {
                if (!isNaN(new Date(value).getDate())) {
                  if (new Date(value) >= new Date(endDate)) {
                    setMessage(t("startLt"));
                    setToastType("danger");
                  } else setStartDate(new Date(value));
                }
              }}
              onClearFilters={() => {
                setStartDate("");
                setEndDate("");
              }}
            />
            {questions.length === 0 && <Empty>{t("emptyQ")}</Empty>}

            {questions[0] && (
              <BigQuestion
                question={questions[0]}
                onDelete={(id) => onDeleteQuestion(id)}
                onClick={() => {
                  setSelectedQues(questions[0]);
                  if (questions[0].answers[0])
                    setSelectedAnsw(questions[0].answers[0]);
                }}
              />
            )}
            <Grid>
              {questions.map((question, index) => {
                return index !== 0 ? (
                  <SmallQuestion
                    index={index + 1}
                    question={question}
                    onDelete={(id) => onDeleteQuestion(id)}
                    onClick={() => {
                      setSelectedQues(question);
                      if (question.answers[0])
                        setSelectedAnsw(question.answers[0]);
                    }}
                  />
                ) : null;
              })}
            </Grid>
            {toastType !== "" && (
              <ToastContainer
                containerPosition="sticky"
                position="top-end"
                style={{
                  position: "fixed !important",
                  bottom: "40%",
                  right: 0,
                  zIndex: "2000",
                  float: "right",
                  textAlign: "left",
                }}
              >
                <Toast
                  delay={7000}
                  animation
                  transition={Collapse}
                  bg={toastType}
                  style={{
                    borderRadius: "4px",
                    fontFamily: "Din",
                    padding: "0 30px",
                  }}
                >
                  <Toast.Body className={"text-white"}>{message}</Toast.Body>
                </Toast>
              </ToastContainer>
            )}
          </Body>
        )}
      </Layout>
    </>
  );
};

const Body = styled.div`
  padding: 3vw 40px;
`;
const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  justify-content: end;
  grid-gap: 20px;
  margin-top: 25px;
`;
const Empty = styled.p`
  color: ${COLORS.gray400};
  font-family: "SF-display";
  font-weight: 400;
  font-size: 0.9rem;
  text-align: center;
  align-self: center;
  margin-top: 20vh;
`;
const EmptyAnswers = styled.p`
  color: ${COLORS.gray400};
  font-family: "SF-display";
  font-weight: 400;
  font-size: 0.9rem;
  text-align: center;
  align-self: center;
  padding: 30px 0;
  background-color: #3472670e;
`;
const DetailsBody = styled.div`
  padding: 3vw 4vw;
  position: relative;
  overflow-x: hidden;
`;
const Wrapper = styled.div`
  background-color: white;
  padding: 20px 40px;
  font-family: "SF-display";
  font-size: 1rem;
  @media (${QUERIES.mobileAndDown}) {
    padding: 10px;
  }
`;

const Subtitle = styled.div`
  font-weight: 500;
  padding-top: 13px;
  text-align: center;
  margin-bottom: 15px;
`;
const Result = styled.div`
  display: flex;
  font-weight: 400;
  padding-top: 13px;
  text-align: left;
  max-width: 27vw;
  word-wrap: break-word;
  @media (${QUERIES.mobileAndDown}) {
    max-width: 70vw;
  }
`;
const Row = styled.div`
  display: flex;
  justify-content: space-evenly;
  padding: 0;
  @media (${QUERIES.mobileAndDown}) {
    flex-direction: column;
  }
`;
const StatisticsBody = styled.div`
  padding: 20px;
  background-color: #3472670e;
`;
const Audio = styled.audio`
  width: 100%;
  /* height: 70%; */
  background-color: #f1f3f4;
  border-radius: 4px;
  margin: 29px 0 7px 0;
  border: 1px solid #ccc;
`;
const VideoPreview = styled.video`
  width: 100%;
  height: 25vh;
  margin: 29px 0 7px 0;
  object-fit: cover;
  vertical-align: middle;
  border-radius: 4px;
`;
const Photo = styled.img`
  border-radius: 4px;
  margin: 29px 0 7px 0;
  height: 25vh;
  width: 100%;
  object-fit: cover;
`;
export default QuestionsScreen;
