import styled from "styled-components";
import React, { useRef } from "react";
import { Box, MenuItem, IconButton, Popover } from "@mui/material";
import { Icon } from "@iconify/react";
import { COLORS } from "../contants/colors";
import { useLanguageContext } from "../languageContext";

const MoreUser = ({
  showRemove = false,
  isBlocked,
  handleClick,
  open,
  setOpen,
  anchorEl,
  top = 0,
  left = 10,
  onRemove,
  onBlock,
}) => {
  const { t } = useLanguageContext();

  return (
    <MoreWrapper top={top} left={left}>
      <IconButton onClick={handleClick}>
        <Box
          colorRendering={"red"}
          component={Icon}
          icon={"eva:more-horizontal-fill"}
          width={20}
          height={20}
          color={COLORS.gray500}
        />
      </IconButton>
      <Popover
        PaperProps={{
          sx: {
            p: 1,
            width: showRemove ? 200 : 150,
            overflow: "inherit",
            boxShadow:
              "0px 5px 5px -3px rgb(120 115 115 / 20%), 0px 8px 10px 1px rgb(163 159 159 / 14%), 0px 3px 14px 2px rgb(181 178 178 / 12%) !important",
          },
        }}
        open={open}
        anchorEl={anchorEl}
        onClose={() => setOpen(false)}
        anchorOrigin={{ vertical: "top", horizontal: "left" }}
        transformOrigin={{ vertical: "top", horizontal: "left" }}
        arrow="left-top"
        sx={{
          mt: -1,
          "& .MuiMenuItem-root": {
            px: 1,
            typography: "body2",
            borderRadius: 0.75,
            fontFamily: "Din",
            paddingBottom: "15px",
            "& svg": { ml: 1, width: 20, height: 20 },
          },
        }}
      >
        {showRemove && (
          <MenuItem
            onClick={() => {
              onRemove();
              setOpen(false);
            }}
          >
            <Box
              component={Icon}
              icon={"eva:person-remove-outline"}
              style={{ marginRight: "10px" }}
            />
            {t("detachFrom")}
          </MenuItem>
        )}
        <MenuItem
          onClick={() => {
            onBlock();
            setOpen(false);
          }}
        >
          <Box
            component={Icon}
            icon={isBlocked ? "eva:person-done-outline" : "eva:slash-outline"}
            style={{ marginRight: "10px" }}
          />
          {isBlocked ? t("unblock") : t("block")}
        </MenuItem>
      </Popover>
    </MoreWrapper>
  );
};

const MoreWrapper = styled.div`
  position: absolute;
  top: ${(p) => p.top}px;
  left: ${(p) => p.left}px;
`;
export default MoreUser;
