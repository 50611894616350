import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import { login } from "../actions/userActions";
import background from "../images/bglogin.svg";
import Message from "../components/Message";
import Spinner from "react-bootstrap/Spinner";
import { COLORS } from "../contants/colors";
import { QUERIES } from "../contants/allConstants";
import Alert from "react-bootstrap/Alert";
import { useLanguageContext } from "../languageContext";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import moment from "moment";
import fr from "moment/locale/fr";

const LoginScreen = ({ history }) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [hide, setHide] = useState(true);
  const [lang, setLang] = useState("1");

  const dispatch = useDispatch();

  const userLogin = useSelector((state) => state.userLogin);
  const { loading, error, userInfo } = userLogin;
  const { t, onClickLanguageChange } = useLanguageContext();

  useEffect(() => {
    const savedLang = JSON.parse(localStorage.getItem("langAnisso"));
    if (!savedLang) {
      localStorage.setItem("langAnisso", JSON.stringify({ lang: "it" }));
    }
    const lang = savedLang ? savedLang.lang : "it";
    console.log("=====login lang", lang);
    if (lang === "fr") setLang("2");
    else if (lang === "en") {
      setLang("0");
    } else {
      setLang("1");
    }
  }, []);

  useEffect(() => {
    moment.locale("en");
    if (userInfo) {
      if (!userInfo.isBlocked) history.push("/dashboard/"); //to redirect if the user is loggedin
    }
  }, [history, userInfo]);

  const submitHandler = (e) => {
    e.preventDefault();
    dispatch(login(email, password));
  };

  return (
    <Wrapper>
      <Container>
        <Image src={require("../images/team.svg").default} alt="" />
        <div style={{ position: "absolute", top: "10vh", right: "7vw" }}>
          <Select
            size="small"
            key={lang}
            value={lang}
            onChange={(e) => {
              setLang(e.target.value);
              const language =
                e.target.value === "0"
                  ? "en"
                  : e.target.value === "1"
                  ? "It"
                  : "Fr";
              // moment.locale(language.toLocaleLowerCase);
              console.log(
                "=====onClickLanguageChange ",
                language.toLocaleLowerCase()
              );
              localStorage.setItem(
                "langAnisso",
                JSON.stringify({ lang: language.toLocaleLowerCase() })
              );

              // console.log("====moment", moment.locale());
              onClickLanguageChange(language);
            }}
            displayEmpty
            inputProps={{
              "aria-label": "Without label",
            }}
            sx={{
              svg: { display: "none" },
              boxShadow: "none",
              ".MuiOutlinedInput-notchedOutline": { border: 0 },
              "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                {
                  border: 0,
                  overflow: "visible",
                },
              "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                {
                  border: 0,
                  overflow: "visible",
                },
              "&.outlined.MuiInputBase-input": {
                overflow: "visible",
              },
            }}
            style={{
              height: 42,
              width: "50px",
              padding: "0",
              overflow: "visible",
            }}
          >
            <MenuItem value="0">
              <Flag src={require("../images/uk.svg").default} alt="" />
            </MenuItem>
            <MenuItem value="1">
              <Flag src={require("../images/italy.svg").default} alt="" />
            </MenuItem>
            <MenuItem value="2">
              <Flag src={require("../images/fr.svg").default} alt="" />
            </MenuItem>
          </Select>
        </div>
        <Form>
          <Icon src={require("../images/greenlogo.svg").default} alt="" />
          <InputWrapper>
            <Label>{t("email")}</Label>
            <Input type="email" onChange={(e) => setEmail(e.target.value)} />
          </InputWrapper>
          <InputWrapper>
            <Label>{t("password")}</Label>
            <Eye
              src={
                require(hide ? "../images/eye-off.svg" : "../images/eye-on.svg")
                  .default
              }
              alt=""
              onClick={() => setHide(!hide)}
            />
            <Input
              type={hide ? "password" : "text"}
              onChange={(e) => setPassword(e.target.value)}
            />
          </InputWrapper>
          {error && <Message variant="danger">{error}</Message>}
          {userInfo
            ? userInfo.isBlocked && (
                <Message variant="danger">{t("blockedError")}</Message>
              )
            : null}

          <Button onClick={submitHandler}>
            {loading ? (
              <Spinner animation="border" variant="light" />
            ) : (
              t("login")
            )}
          </Button>
        </Form>
      </Container>
    </Wrapper>
  );
};
const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  background-color: ${COLORS.primary};
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Rady";
`;
const Container = styled.div`
  position: "relative";
  width: 90vw;
  height: 85vh;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0px 30px 60px 4px rgba(57, 57, 57, 0.3);
  display: flex;
  padding: 25px;
  justify-content: space-evenly;
  @media (${QUERIES.tabletAndDown}) {
    flex-direction: column;
    align-items: center;
    /* justify-content: space-between; */
    padding: 10px 25px 40px 25px;
  }
`;
const Image = styled.img`
  width: 50%;
  object-fit: fill;
  /* @media (${QUERIES.tabletAndDown}) {
    height: 40%;
    width: 100%;
    margin-bottom: 10px;
  } */
  @media (${QUERIES.tabletAndDown}) {
    display: none;
  }
`;

const Icon = styled.img`
  width: 40vw;
  max-width: 180px;
  object-fit: fill;
  margin-bottom: 25px;
`;
const Flag = styled.img`
  /* width: 40px; */
  object-fit: fill;
  @media (${QUERIES.mobileAndDown}) {
    width: 30px;
  }
`;
const Eye = styled.img`
  width: 19px;
  object-fit: fill;
  cursor: pointer;
  position: absolute;
  top: 20px;
  right: 40px;
`;
const Form = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  max-width: 400px;
`;

const Button = styled.button`
  background-color: #f77847;
  border-radius: 8px;
  font-size: 0.975rem;
  color: white;
  font-weight: 700;
  width: 100%;
  height: 48px;
  border: none;
  margin-top: 25px;
  &:hover {
    outline: none;
    background-color: #f6936c;
  }
  &:focus {
    outline: none;
  }
  @media (${QUERIES.mobileAndDown}) {
    margin-top: 15px;
  }
`;

const InputWrapper = styled.div`
  border: 1px solid ${COLORS.gray20};
  border-radius: 4px;
  padding: 10px 12px;
  width: 100%;
  margin-bottom: 30px;
  position: relative;
  @media (${QUERIES.mobileAndDown}) {
    margin-bottom: 20px;
  }
`;
const Label = styled.p`
  font-size: 0.6875rem;
  color: ${COLORS.black56};
  font-weight: 400;
  /* text-align: right; */
  margin: 0 0 4px 0;
`;
const Input = styled.input`
  font-size: 0.875rem;
  color: ${COLORS.black56};
  font-weight: 700;
  border: none;
  /* text-align: right; */
  width: 100%;

  &:focus {
    border: none;
    outline: none;
  }
`;
export default LoginScreen;
