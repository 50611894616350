import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Header from "../components/Header";
import Layout from "../components/Layout";
import styled from "styled-components";
import Drawer from "../components/Drawer";
import { useState } from "react";
import Miniheader from "../components/Miniheader";
import TextField from "../components/TextField";
import FileField from "../components/FileField";
import { COLORS } from "../contants/colors";
import GreenButton from "../components/GreenBtn";
import WhiteBtn from "../components/WhiteBtn";
import Dropdown from "../components/Dropdown";
import { QUERIES } from "../contants/allConstants";
import PhoneInput from "react-phone-input-2";
import ar from "react-phone-input-2/lang/ar.json";
import "react-phone-input-2/lib/style.css";
import { changePassword, createSupervisor } from "../actions/userActions";
import ToastContainer from "react-bootstrap/esm/ToastContainer";
import Collapse from "react-bootstrap/Collapse";
import Toast from "react-bootstrap/Toast";
import { updateTeam } from "../actions/teamActions";
import { useLanguageContext } from "../languageContext";

const ChangePwdScreen = ({ history }) => {
  const dispatch = useDispatch();
  const { userInfo, error, loading } = useSelector((state) => state.userLogin);
  const [selected, setSelected] = useState(8);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [password, setPassword] = useState("");
  const [oldpwd, setOldPwd] = useState("");
  const [confirmPwd, setConfrim] = useState("");
  const [message, setMessage] = useState("");
  const [toastType, setToastType] = useState("");
  const [hide, setHide] = useState(true);
  const [hide2, setHide2] = useState(true);
  const [hide3, setHide3] = useState(true);
  const { t } = useLanguageContext();

  const onUpdatePassword = () => {
    try {
      console.log(
        "====pwd",
        password,
        "===",
        password.length,
        "===",
        password.length < 8 || !password.match(/[|\\/~^:,;?!&%$@*+#]/)
      );
      if (oldpwd !== "" && password !== "" && confirmPwd !== "") {
        if (password !== confirmPwd) {
          setMessage(t("nonConfirmPwd"));
          setToastType("danger");
        } else if (
          password.length < 8 ||
          !password.match(/[|\\/~^:,;?!&%$@*+#]/)
        ) {
          setMessage(t("invalidPwd"));
          setToastType("danger");
        } else {
          dispatch(
            changePassword(userInfo["token"], oldpwd, password, () => {
              setMessage(t("editSuccessTeam"));
              setToastType("success");
              setPassword("");
              setOldPwd("");
              setConfrim("");
            })
          );
        }
      } else {
        setMessage(t("fillout"));
        setToastType("danger");
      }
    } catch (error) {
      console.log("====error", error);
    }
  };

  useEffect(() => {
    if (error) {
      setMessage(error !== "" ? error : t("mainError"));
      setToastType("danger");
    }
  }, [error]);

  const onCancel = () => {
    setPassword("");
    setOldPwd("");
    setConfrim("");
  };
  useEffect(() => {
    setTimeout(() => {
      setToastType("");
    }, 8000);
  }, [toastType]);

  return (
    <Layout>
      <Header setOpenDrawer={() => setOpenDrawer(!openDrawer)} />
      <Drawer
        setOpenDrawer={() => setOpenDrawer(!openDrawer)}
        open={openDrawer}
        history={history}
        selected={selected}
        onChange={(value) => setSelected(value)}
      />
      <Body>
        <Miniheader title={t("changepwd")} />
        <Wrapper>
          <Col>
            <InputWrapper>
              <Label>{t("currentpwd")}</Label>
              <Eye
                src={
                  require(hide
                    ? "../images/eye-off.svg"
                    : "../images/eye-on.svg").default
                }
                alt=""
                onClick={() => setHide(!hide)}
              />
              <Input
                type={hide ? "password" : "text"}
                value={oldpwd}
                onChange={(e) => setOldPwd(e.target.value)}
              />
            </InputWrapper>
          </Col>

          <Col>
            <InputWrapper>
              <Label>{t("newpwd")}</Label>
              <Eye
                src={
                  require(hide2
                    ? "../images/eye-off.svg"
                    : "../images/eye-on.svg").default
                }
                alt=""
                onClick={() => setHide2(!hide2)}
              />
              <Input
                type={hide2 ? "password" : "text"}
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </InputWrapper>
          </Col>
          <Col>
            <InputWrapper>
              <Label>{t("confirmpwd")}</Label>
              <Eye
                src={
                  require(hide3
                    ? "../images/eye-off.svg"
                    : "../images/eye-on.svg").default
                }
                alt=""
                onClick={() => setHide3(!hide3)}
              />
              <Input
                type={hide3 ? "password" : "text"}
                value={confirmPwd}
                onChange={(e) => setConfrim(e.target.value)}
              />
            </InputWrapper>
          </Col>
          <Row>
            <GreenButton
              title={t("edit")}
              onClick={() => onUpdatePassword()}
              loading={loading}
            />
            <WhiteBtn title={t("cancel")} onClick={() => onCancel()} />
          </Row>
        </Wrapper>
        {toastType !== "" && (
          <ToastContainer
            containerPosition="sticky"
            position="top-end"
            style={{
              position: "fixed !important",
              bottom: "40%",
              right: 0,
              zIndex: "2000",
              float: "right",
              textAlign: "left",
            }}
          >
            <Toast
              delay={7000}
              animation
              transition={Collapse}
              bg={toastType}
              style={{
                borderRadius: "4px",
                fontFamily: "Din",
                padding: "0 30px",
              }}
            >
              <Toast.Body className={"text-white"}>{message}</Toast.Body>
            </Toast>
          </ToastContainer>
        )}
      </Body>
    </Layout>
  );
};

const Body = styled.div`
  padding: 3vw 4vw;
  position: relative;
`;
const Wrapper = styled.div`
  background-color: white;
  /* padding: 20px 40px; */
  padding: 30px 15vw 30px 15vw;
`;
const Row = styled.div`
  display: flex;
  margin-top: 20px;
  justify-content: space-between;
  @media (${QUERIES.mobileAndDown}) {
    flex-direction: column-reverse;
  }
`;
const Col = styled.div`
  margin-bottom: 20px;
  flex: 1;
`;

const Eye = styled.img`
  width: 19px;
  object-fit: fill;
  cursor: pointer;
  position: absolute;
  top: 20px;
  right: 40px;
`;

const InputWrapper = styled.div`
  border: 1px solid ${COLORS.gray20};
  border-radius: 4px;
  padding: 10px 12px;
  width: 100%;
  margin-bottom: 30px;
  position: relative;
  font-family: "Rady";
  @media (${QUERIES.mobileAndDown}) {
    margin-bottom: 20px;
  }
`;
const Label = styled.p`
  font-size: 0.6875rem;
  color: ${COLORS.black56};
  font-weight: 400;
  text-align: left;
  margin: 0 0 4px 0;
`;
const Input = styled.input`
  font-size: 0.875rem;
  color: ${COLORS.black56};
  font-weight: 700;
  border: none;
  text-align: left;
  width: 100%;

  &:focus {
    border: none;
    outline: none;
  }
`;

export default ChangePwdScreen;
