import styled from "styled-components";
import React from "react";
import { COLORS } from "../contants/colors";
import { QUERIES } from "../contants/allConstants";
import { Icon } from "@iconify/react";
import { Box } from "@mui/material";

const RemoveButton = ({ onClick }) => {
  return (
    <Button onClick={onClick}>
      <Box
        component={Icon}
        icon={"eva:person-remove-outline"}
        // boxSizing={30}
        width={30}
        height={20}
      />
    </Button>
  );
};
const Button = styled.button`
  border-radius: 6px;
  background-color: #fdece0;
  border: none;
  height: 36px;
  width: 60px;
  color: #f8a262;
  &:focus {
    outline-color: ${COLORS.primary};
  }
  @media (${QUERIES.mobileAndDown}) {
    width: 100%;
  }
`;

export default RemoveButton;
