import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Header from "../components/Header";
import Layout from "../components/Layout";
import styled from "styled-components";
import Drawer from "../components/Drawer";
import { useState } from "react";
import Miniheader from "../components/Miniheader";
import TextArea from "../components/TextArea";
import TextField from "../components/TextField";
import FileField from "../components/FileField";
import LocationField from "../components/LocationField";
import { COLORS } from "../contants/colors";
import Dropdown from "../components/Dropdown";
import WorkDropdown from "../components/WorkDropdown";
import MultiDropdown from "../components/MultiDropdown";
import GreenButton from "../components/GreenBtn";
import WhiteBtn from "../components/WhiteBtn";
import {
  AGE,
  EXPIRATION,
  GENDER,
  QUERIES,
  RADIUS,
  USER_LOGIN_FAIL,
} from "../contants/allConstants";
import Toast from "react-bootstrap/Toast";
import ToastContainer from "react-bootstrap/ToastContainer";
import Collapse from "react-bootstrap/Collapse";
import { createQuestion } from "../actions/questionActions";
import SmallDropdown from "../components/SmallDropdown";
import * as Icon from "react-icons/fi";
import Checkbox from "react-custom-checkbox";
import { getAllTeams } from "../actions/teamActions";
import ConfirmationPopup from "../components/ConfirmationPopup";
import { useLanguageContext } from "../languageContext";
const INITIAL_PLACE = { lat: 45.4683666, lng: 9.1795032 };

const NewQuestionScreen = ({ history }) => {
  const dispatch = useDispatch();
  const { userInfo } = useSelector((state) => state.userLogin);
  const { questions, error, loading } = useSelector((state) => state.question);
  const { teams } = useSelector((state) => state.team);
  const [selected, setSelected] = useState(3);
  const [place, setPlace] = useState(INITIAL_PLACE);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [showConfirmation, setShowConfirm] = useState(false);
  const [file, setFile] = useState("");
  const [message, setMessage] = useState("");
  const [toastType, setToastType] = useState("");
  const [description, setDescription] = useState("");
  const [title, setTitle] = useState("");
  const [subject, setSubject] = useState("");
  const [company, setCompany] = useState("");
  const [answers, setAnswers] = useState([]);
  const [sportBelonging, setSportBelonging] = useState([]); //selected ones
  const [defaultBelonging, setDefaultBelonging] = useState([]);
  const [politicsBelonging, setPoliticsBelonging] = useState([]);
  const [sportArray, setSportArray] = useState([]);
  const [defaultArray, setDefaultArray] = useState([]);
  const [politicsArray, setPoliticsArray] = useState([]);
  const [work, setWork] = useState("");
  const [age, setAge] = useState("");
  const [gender, setGender] = useState("");
  const [minAge, setMinAge] = useState("");
  const [maxAge, setMaxAge] = useState("");
  const [includeLocation, setIncludeLocation] = useState(false);
  const [radius, setRadius] = useState("3");
  const [expired, setExpired] = useState("1");
  const { t } = useLanguageContext();
  const EXPIRATION = [
    { value: "1", name: t("1hour") },
    { value: "3", name: t("3hours") },
    { value: "6", name: t("6hours") },
    { value: "10", name: t("10hours") },
    { value: "24", name: t("1day") },
    { value: "168", name: t("1week") },
    { value: "336", name: t("2weeks") },
    { value: "730", name: t("1month") },
  ];
  useEffect(() => {
    dispatch(
      getAllTeams(
        userInfo["token"],
        userInfo["isSupervisor"] || userInfo["isSubAdmin"]
          ? userInfo["_id"]
          : null
      )
    );
  }, []);

  useEffect(() => {
    if (file.size) {
      if (file.size > 100e6) {
        setFile("");
        setMessage(t("largeFile"));
        setToastType("danger");
      }
    }
  }, [file]);

  useEffect(() => {
    if (error) {
      setMessage(t("mainError"));
      setToastType("danger");
    }
  }, [error]);

  useEffect(() => {
    setTimeout(() => {
      setToastType("");
    }, 8000);
  }, [toastType]);

  useEffect(() => {
    if (userInfo.isSupervisor) {
      if (userInfo.team.category.includes("مدنية"))
        setDefaultBelonging([userInfo.team.name]);
      else if (userInfo.team.category.includes("رياضية"))
        setSportBelonging([userInfo.team.name]);
      else setPoliticsBelonging([userInfo.team.name]);
    } else if (teams) {
      //ekhtiyari
      if (teams.length > 0) {
        const defaultTeam = teams.filter(({ _id }) => _id.includes("مدنية"));
        const sportTeam = teams.filter(({ _id }) => _id.includes("رياضية"));
        const politicTeam = teams.filter(({ _id }) => _id.includes("سياسي"));
        if (defaultTeam.length > 0) {
          if (defaultTeam[0].data) {
            const _defaultData = defaultTeam[0].data.map((team) => team.name);
            setDefaultArray(_defaultData);
          }
        }
        if (sportTeam.length > 0) {
          if (sportTeam[0].data) {
            const _sportTeam = sportTeam[0].data.map((team) => team.name);
            setSportArray(_sportTeam);
          }
        }
        if (politicTeam.length > 0) {
          if (politicTeam[0].data) {
            const _politictData = politicTeam[0].data.map((team) => team.name);
            setPoliticsArray(_politictData);
          }
        }
      }
    }
  }, [teams]);

  const onCreateQuestion = () => {
    setShowConfirm(false);
    dispatch(
      createQuestion(
        userInfo["token"],
        title,
        subject,
        company,
        description,
        answers,
        minAge, //
        maxAge, //
        gender, //
        work, //
        sportBelonging, //
        politicsBelonging, //
        defaultBelonging, //
        includeLocation ? place.lng : "", //
        includeLocation ? place.lat : "", //
        includeLocation ? radius : "", //
        expired, //
        file,
        t("newQuestion"),
        () => {
          setMessage(t("addSuccessQ"));
          setToastType("success");
          setDescription("");
          setFile("");
          setAnswers([]);
          setMinAge("");
          setMaxAge("");
          setAge("");
          setWork("");
          setGender("");
          setTitle("");
          setSubject("");
          setCompany("");
          setPoliticsBelonging([]);
          setDefaultBelonging([]);
          setSportBelonging([]);
          setIncludeLocation(false);
          // setPlace(INITIAL_PLACE);
        }
      )
    );
  };
  const AGE = [
    { label: t("indeterminate"), minAge: "", maxAge: "" },
    { label: "5-10", minAge: "5", maxAge: "10" },
    { label: "10-20", minAge: "10", maxAge: "20" },
    { label: "20-30", minAge: "20", maxAge: "30" },
    { label: "30-40", minAge: "30", maxAge: "40" },
    { label: t("greater") + "40", minAge: "40", maxAge: "1000" },
  ];
  const GENDER = ["", t("female"), t("male")];
  return (
    <Layout openDrawer={openDrawer}>
      <Header setOpenDrawer={() => setOpenDrawer(!openDrawer)} />
      <Drawer
        setOpenDrawer={() => setOpenDrawer(!openDrawer)}
        open={openDrawer}
        history={history}
        selected={selected}
        onChange={(value) => setSelected(value)}
      />
      <Body>
        {showConfirmation && (
          <ConfirmationPopup
            title={t("confirmQ")}
            description={t("sureQ")}
            loading={loading}
            onConfirm={() => onCreateQuestion()}
            onCancel={() => setShowConfirm(false)}
          />
        )}
        <Miniheader title={t("addQ")} />
        <Wrapper>
          <Row>
            <Col>
              <TextField
                title={t("title")}
                hint={t("enterTitle")}
                value={title}
                onChange={(value) => setTitle(value)}
              />
              <Row>
                <TextField
                  title={t("subject")}
                  hint={t("enterSubject")}
                  value={subject}
                  onChange={(value) => setSubject(value)}
                />
                <div style={{ width: "10px" }} />

                <TextField
                  title={t("from")}
                  hint={t("enterFrom")}
                  value={company}
                  onChange={(value) => setCompany(value)}
                />
              </Row>
              <TextArea
                title={t("question")}
                hint={t("questionEnter")}
                value={description}
                onChange={(value) => setDescription(value)}
              />
              <TextField
                title={t("option1")}
                hint={t("opEnter1")}
                value={answers[0] ?? ""}
                onChange={(value) => setAnswers([value, answers[1]])}
              />
              <TextField
                title={t("option2")}
                hint={t("opEnter2")}
                value={answers[1] ?? ""}
                onChange={(value) => setAnswers([answers[0], value])}
              />
              <FileField
                type={"image/png, image/jpeg, image/jpg, audio/mp3, video/*"}
                title={t("file")}
                setFile={(value) => setFile(value)}
                file={file}
              />
            </Col>
            <Divider />
            <Col>
              <LocationField
                title={t("distance")}
                place={place}
                setPlace={setPlace}
                isChecked={includeLocation}
                setCheckbox={() => setIncludeLocation(!includeLocation)}
                radius={radius}
              >
                <div style={{ marginRight: "8px" }} />
                <SmallDropdown
                  value={radius}
                  items={RADIUS}
                  prefix={t("km")}
                  onChange={(value) => setRadius(value)}
                />
              </LocationField>
              {(userInfo.isAdmin || userInfo.isSubAdmin) && (
                <Row>
                  {/* {sportArray.length > 0 && ( */}
                  <MultiDropdown
                    title={t("country")}
                    items={defaultArray}
                    selectedTeams={defaultBelonging}
                    onChange={(value) => setDefaultBelonging(value)}
                  />
                  {/* )} */}

                  {/* {defaultArray.length > 0 && (
                    <MultiDropdown
                      style={{ marginLeft: "15px" }}
                      title={"جمعيات مدنية"}
                      items={defaultArray}
                      selectedTeams={defaultBelonging}
                      onChange={(value) => setDefaultBelonging(value)}
                    />
                  )}
                  {politicsArray.length > 0 && (
                    <MultiDropdown
                      title={"جمعيات سياسية"}
                      items={politicsArray}
                      selectedTeams={politicsBelonging}
                      onChange={(value) => setPoliticsBelonging(value)}
                    />
                  )} */}
                </Row>
              )}

              <Row>
                {/* <WorkDropdown
                  title={"المهنة"}
                  style={{ marginLeft: "15px" }}
                  value={work}
                  onChange={(value) => setWork(value)}
                /> */}
                <Dropdown
                  title={t("age")}
                  items={AGE}
                  value={age}
                  onChange={(value) => {
                    setAge(value);
                    const selected = AGE.find((item) => item.label === value);
                    setMinAge(selected.minAge);
                    setMaxAge(selected.maxAge);
                  }}
                />
                <div style={{ width: "10px" }} />
                <Dropdown
                  title={t("gender")}
                  items={GENDER}
                  value={gender}
                  onChange={(value) => {
                    setGender(value);
                  }}
                />
              </Row>
              <Title>
                {t("autoDelete")}
                <div style={{ marginRight: "8px" }} />

                <SmallDropdown
                  value={expired}
                  items={EXPIRATION}
                  onChange={(value) => setExpired(value)}
                />
              </Title>

              <Row>
                <GreenButton
                  title={t("send")}
                  onClick={() => {
                    const valid_answers =
                      answers.filter((a) => a !== "").length === 2;
                    if (
                      description !== "" &&
                      title !== "" &&
                      subject !== "" &&
                      company !== "" &&
                      file !== "" &&
                      valid_answers
                    ) {
                      setShowConfirm(true);
                    } else {
                      setMessage(t("fillout"));
                      setToastType("danger");
                    }
                  }}
                  loading={loading}
                />
                <WhiteBtn
                  disabled={loading}
                  title={t("cancel")}
                  onClick={() => {
                    setDescription("");
                    setFile("");
                  }}
                />
              </Row>
            </Col>
          </Row>
        </Wrapper>
        {toastType !== "" && (
          <ToastContainer
            containerPosition="sticky"
            position="top-end"
            style={{
              position: "fixed !important",
              bottom: "40%",
              right: 0,
              zIndex: "2000",
              float: "right",
              textAlign: "left",
            }}
          >
            <Toast
              delay={7000}
              animation
              transition={Collapse}
              bg={toastType}
              style={{
                borderRadius: "4px",
                fontFamily: "Din",
                padding: "0 30px",
              }}
            >
              <Toast.Body className={"text-white"}>{message}</Toast.Body>
            </Toast>
          </ToastContainer>
        )}
      </Body>
    </Layout>
  );
};

const Body = styled.div`
  padding: 2vw;
  position: relative;
  @media (${QUERIES.tabletMax}) {
    padding: 2vw 2vw;
  }
`;
const Wrapper = styled.div`
  background-color: white;
  padding: 6px 30px 20px 30px;
`;
const Row = styled.div`
  display: flex;
  justify-content: space-between;
  @media (${QUERIES.exceptionAndDown}) {
    flex-direction: column;
  }
`;
const Col = styled.div`
  flex: 1;
  min-width: 100px;
`;
const Divider = styled.div`
  width: 1px;
  background-color: ${COLORS.gray700};
  margin: 0 20px;
`;
const Title = styled.p`
  font-family: "SF-display";
  font-size: 0.875rem;
  font-weight: 700;
  text-align: left;
  margin: 18px 0 0 0;
  padding-bottom: 4px;
  display: flex;
  align-items: center;
`;
export default NewQuestionScreen;
