import styled from "styled-components";
import React from "react";
import { COLORS } from "../contants/colors";
import MorePopover from "./MorePopover";
import { useState } from "react";
import { useLanguageContext } from "../languageContext";

const Reply = ({ index, id, photo, username, description, time, onDelete }) => {
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const { t } = useLanguageContext();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    setOpen(true);
  };

  return (
    <Wrapper key={id} index={index}>
      <Row>
        <Image
          src={
            require(index === 0
              ? "../images/vector.svg"
              : "../images/vector3.svg").default
          }
          alt=""
          index={index}
        />
        <Avatar
          src={photo !== "" ? photo : require("../images/avatar.svg").default}
        />
        <div
          style={{
            backgroundColor: `${COLORS.gray990}`,
            borderRadius: "6px",
            padding: "8px 10px",
            width: "100%",
            position: "relative",
          }}
        >
          <Title>{username}</Title>
          <Description>{description}</Description>
          <Time>{time}</Time>
          <MorePopover
            showEdit={false}
            handleClick={handleClick}
            open={open}
            setOpen={setOpen}
            anchorEl={anchorEl}
            onDelete={() => {
              onDelete(id);
            }}
            onUpdate={() => {}}
            styles={{
              sx: {
                p: 1,
                width: 200,
                overflow: "inherit",
                boxShadow: "rgba(0, 0, 0, 0.015) 1.95px 1.95px 5px",
              },
            }}
          />
        </div>
      </Row>
    </Wrapper>
  );
};

const Image = styled.img`
  height: ${(p) => (p.index === 0 ? "30px" : "100px")};
  object-fit: fill;
  position: absolute;
  top: ${(p) => (p.index === 0 ? "4px" : "-80px")};
  right: ${(p) => (p.index === 0 ? "0" : "-0.5px")};
  padding-right: 7px;
`;

const Wrapper = styled.div`
  border-radius: 10px;
  font-family: "SF-display";
  text-align: left;
  padding: ${(p) => (p.index === 0 ? "17px 30px 10px 0" : "0 30px 10px 0")};
  position: relative;
  cursor: pointer;
  color: ${COLORS.black27};
  display: flex;
  flex-direction: column;
  align-items: end;
  position: relative;
`;

const Row = styled.div`
  display: flex;
  padding: 0;
  justify-content: space-between;
  width: 100%;
`;

const Avatar = styled.img`
  border-radius: 50%;
  height: 35px;
  min-width: 35px;
  object-fit: cover;
  margin-right: 7px;
`;
const Title = styled.p`
  font-weight: 500;
  font-size: 0.75rem;
  margin: 0;
  padding-bottom: 3px;
  color: #4e4d58;
`;
const Description = styled.p`
  font-weight: 400;
  font-size: 0.7rem;
  margin: 0;
  padding-bottom: 3px;
  color: #5b5a64;
`;
const Time = styled.p`
  font-weight: 400;
  font-size: 0.65rem;
  margin: 0;
  color: ${COLORS.gray500};
`;
export default Reply;
