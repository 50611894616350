import styled from "styled-components";
import React from "react";
import { COLORS } from "../contants/colors";
import { QUERIES } from "../contants/allConstants";
import { Icon } from "@iconify/react";
import { Box } from "@mui/material";

const AddButton = ({ disabled = false, title, onClick }) => {
  return (
    <Button disabled={disabled} onClick={onClick}>
      <Title>{title}</Title>
      <Box component={Icon} icon={"eva:plus-outline"} width={20} height={18} />
    </Button>
  );
};
const Button = styled.button`
  color: #414040;
  border-radius: 4px;
  font-family: "SF-display";
  font-size: 1rem;
  font-weight: 500;
  background-color: white;
  border: none;
  height: 36px;
  margin-top: 16px;
  border: 1px solid #414040;
  padding: 0 20px;
  &:focus {
    outline-color: ${COLORS.primary};
  }
  &:disabled {
    background-color: ${COLORS.gray200};
    border: 1px solid ${COLORS.gray200};
  }
  @media (${QUERIES.mobileAndDown}) {
    width: 100%;
  }
`;

const Title = styled.span`
  margin-right: 10px;
`;

export default AddButton;
