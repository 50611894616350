import axios from "axios";
import {
  QUESTION_LIST_REQUEST,
  QUESTION_LIST_SUCCESS,
  QUESTION_LIST_FAIL,
  PROXY,
  DELETE_QUESTION_SUCCESS,
  DELETE_QUESTION_FAIL,
  QUESTION_CREATE_REQUEST,
  QUESTION_CREATE_SUCCESS,
  QUESTION_CREATE_FAIL,
} from "../contants/allConstants";

export const getQuestions = (token) => async (dispatch) => {
  try {
    dispatch({ type: QUESTION_LIST_REQUEST });
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    const { data } = await axios.get(`${PROXY}/question`, config);
    dispatch({
      type: QUESTION_LIST_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: QUESTION_LIST_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const searchQuestion =
  (token, keyword, startDate, endDate) => async (dispatch) => {
    try {
      const config = {
        headers: { Authorization: `Bearer ${token}` },
        params: {
          keyword,
          startDate,
          endDate,
        },
      };
      const { data } = await axios.get(`${PROXY}/question/search`, config);
      dispatch({
        type: QUESTION_LIST_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: QUESTION_LIST_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const deleteQuestion = (token, id) => async (dispatch) => {
  try {
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };

    const { data } = await axios.delete(`${PROXY}/question/${id}`, config);
    dispatch({
      type: DELETE_QUESTION_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: DELETE_QUESTION_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const createQuestion =
  (
    token,
    title,
    subject,
    company,
    description,
    answers,
    minAge,
    maxAge,
    gender,
    work,
    sportBelonging,
    politicBelonging,
    defaultBelonging,
    longitude,
    latitude,
    radius,
    expired,
    media,
    notifTitle,
    callBack
  ) =>
  async (dispatch) => {
    try {
      dispatch({
        type: QUESTION_CREATE_REQUEST,
      });
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
      };
      const formData = new FormData();
      formData.append("title", title);
      formData.append("subject", subject);
      formData.append("company", company);
      formData.append("description", description);
      formData.append("answers", JSON.stringify(answers));
      formData.append("minAge", minAge);
      formData.append("maxAge", maxAge);
      formData.append("gender", gender);
      formData.append("work", work);
      formData.append("sportBelonging", JSON.stringify(sportBelonging));
      formData.append("politicBelonging", JSON.stringify(politicBelonging));
      formData.append("defaultBelonging", JSON.stringify(defaultBelonging));
      formData.append("longitude", longitude);
      formData.append("latitude", latitude);
      formData.append("radius", radius);
      formData.append("expired", expired);
      formData.append("file", media);
      formData.append("notifTitle", notifTitle);

      const { data } = await axios.post(
        `${PROXY}/question/new`,
        formData,
        config
      );

      dispatch({
        type: QUESTION_CREATE_SUCCESS,
        payload: data,
      });

      callBack();
    } catch (error) {
      dispatch({
        type: QUESTION_CREATE_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };
