import styled from "styled-components";
import React, { Fragment, useEffect, useState } from "react";
import { COLORS } from "../contants/colors";
// import {
//   withGoogleMap,
//   GoogleMap,
//   withScriptjs,
//   Marker,
//   Circle,
// } from "react-google-maps";
import Autocomplete from "react-google-autocomplete";
import {
  MapContainer,
  Marker,
  Popup,
  TileLayer,
  useMap,
  CircleMarker,
  Circle,
} from "react-leaflet";
import { OpenStreetMapProvider, GeoSearchControl } from "leaflet-geosearch";
import L from "leaflet";
import "leaflet/dist/leaflet.css";
import "leaflet-geosearch/dist/geosearch.css";
import * as Icon from "react-icons/fi";
import Checkbox from "react-custom-checkbox";
import { QUERIES } from "../contants/allConstants";
import { useLanguageContext } from "../languageContext";

// const google_TOKEN = "AIzaSyA6u7Br4zYy82uky5gzl01K26TqLrXMjb8";

const Search = (props) => {
  const map = useMap();
  const { t } = useLanguageContext();
  useEffect(() => {
    const searchControl = new GeoSearchControl({
      provider: new OpenStreetMapProvider(),
      style: "bar",
      searchLabel: t("enterAdd"),
      showMarker: false,
    });
    map.addControl(searchControl);
    var group = L.featureGroup();
    map.on("geosearch/showlocation", (result) => {
      if (map.hasLayer(group)) {
        map.removeLayer(group);
      }
      console.log("===showlocation", result["location"]);
      props.setPlace({
        lat: result["location"]["y"],
        lng: result["location"]["x"],
      });
    });
    map.eachLayer(function (layer) {
      if (!!layer.toGeoJSON) {
        console.log("======eachLayer");
        map.removeLayer(layer);
      }
    });
    //3000 = 3 KM
    var circle = L.circle(
      [props.place.lat, props.place.lng],
      props.radius * 1000,
      {
        color: COLORS.primary,
        showMarker: false,
      }
    ).addTo(group);
    map.addLayer(group);
    circle.on("mousedown", function (event) {
      console.log("======mousedown", circle._latlng);

      //L.DomEvent.stop(event);
      map.dragging.disable();
      let { lat: circleStartingLat, lng: circleStartingLng } = circle._latlng;
      let { lat: mouseStartingLat, lng: mouseStartingLng } = event.latlng;
      // props.setPlace({
      //   lat: circle._latlng["lat"],
      //   lng: circle._latlng["lng"],
      // });
      map.on("mousemove", (event) => {
        console.log("======mousemove");

        let { lat: mouseNewLat, lng: mouseNewLng } = event.latlng;
        let latDifference = mouseStartingLat - mouseNewLat;
        let lngDifference = mouseStartingLng - mouseNewLng;

        let center = [
          circleStartingLat - latDifference,
          circleStartingLng - lngDifference,
        ];
        props.setPlace({
          lat: center[0],
          lng: center[1],
        });
        circle.setLatLng(center);
      });
    });

    map.on("mouseup", () => {
      console.log("======mouseup");

      map.dragging.enable();
      map.removeEventListener("mousemove");
    });
    return () => map.removeControl(searchControl);
  }, [props]);

  return null; // don't want anything to show up from this comp
};
const LocationField = ({
  place,
  title,
  setPlace,
  isChecked,
  setCheckbox,
  radius,
  children,
}) => {
  return (
    <Wrapper>
      <Title>
        <Checkbox
          icon={<Icon.FiCheck color="white" size={18} />}
          checked={isChecked}
          onChange={() => setCheckbox()}
          col
          borderColor={isChecked ? COLORS.primary : COLORS.gray100}
          style={{
            cursor: "pointer",
            backgroundColor: isChecked ? COLORS.primary : "white",
            margin: "8px 8px 0 0",
          }}
          borderRadius={3}
        />
        {title}
        {children}
      </Title>

      <Map>
        <MapContainer
          key={place["lat"]}
          center={place}
          zoom={10}
          scrollWheelZoom={false}
          animate={true}
        >
          <Search
            provider={new OpenStreetMapProvider()}
            place={place}
            setPlace={setPlace}
            radius={radius}
          />
          <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
        </MapContainer>
      </Map>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  color: ${COLORS.black56};
  margin: 13px 0 5px 0;
`;
const Map = styled.div`
  width: 100%;
  height: 250px;
  margin-top: 8px;
  border-radius: 4px;
  @media (${QUERIES.mobileAndDown}) {
    width: 75vw;
  }
`;
const Title = styled.p`
  font-family: "SF-display";
  font-size: 0.875rem;
  font-weight: 700;
  text-align: left;
  margin: 0;
  /* padding-bottom: 4px; */
  display: flex;
  align-items: center;
`;
const Input = styled.input`
  font-family: "IBM Plex Sans Arabic";
  width: 100%;
  border: 1px solid ${COLORS.gray100};
  border-radius: 4px;
  font-size: 0.875rem;
  font-weight: 300;
  padding: 10px;
  &::placeholder {
    color: ${COLORS.gray400};
  }
  &:focus {
    outline-color: ${COLORS.lightBlue};
  }
`;

export default LocationField;
