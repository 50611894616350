import React, { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import Header from "../components/Header";
import Layout from "../components/Layout";
import styled from "styled-components";
import Drawer from "../components/Drawer";
import { useState } from "react";
import Alert from "../components/Alert";
import Video from "../components/Video";
import {
  deleteAlert,
  getAllAlerts,
  openAlerts,
  searchAlerts,
} from "../actions/alertActions";
import { format } from "date-fns";
import Users from "../components/Users";
import UserDetails from "../components/UserDetails";
import { COLORS } from "../contants/colors";
import Spinner from "react-bootstrap/Spinner";
import Search from "../components/Search";
import ToastContainer from "react-bootstrap/esm/ToastContainer";
import Collapse from "react-bootstrap/Collapse";
import Toast from "react-bootstrap/Toast";
import { useLanguageContext } from "../languageContext";

const VideosScreen = ({ history }) => {
  const [selected, setSelected] = useState(11);
  const [selectedParticipants, setSelectedParticipants] = useState([]);
  const [selectedDisapprovers, setSelectedDisapprovers] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [yPosition, setYPosition] = useState(0);
  const [openDrawer, setOpenDrawer] = useState(false);
  const { userInfo } = useSelector((state) => state.userLogin);
  const { alerts, count, loading, error } = useSelector((state) => state.alert);
  const [keyword, setKeyword] = useState("");
  const [message, setMessage] = useState("");
  const [toastType, setToastType] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const dispatch = useDispatch();
  const { t } = useLanguageContext();

  const onSearchAlerts = () => {
    dispatch(
      searchAlerts(
        userInfo["token"],
        keyword,
        startDate,
        endDate,
        userInfo["_id"],
        "video"
      )
    );
  };
  useEffect(() => {
    if (userInfo) {
      dispatch(openAlerts(userInfo["token"]));
      dispatch(getAllAlerts(userInfo["token"], userInfo["_id"], "video"));
    }
  }, []);
  useEffect(() => {
    dispatch(openAlerts(userInfo["token"]));
  }, [count]);

  const onDeleteAlert = (id) => {
    dispatch(deleteAlert(userInfo["token"], id));
  };

  useEffect(() => {
    if (error) {
      setMessage(t("mainError"));
      setToastType("danger");
    }
  }, [error]);

  useEffect(() => {
    setTimeout(() => {
      setToastType("");
    }, 8000);
  }, [toastType]);

  useEffect(() => {
    if (keyword !== "" || startDate !== "" || endDate !== "") {
      onSearchAlerts();
    }
    if (keyword === "" && startDate === "" && endDate === "") {
      dispatch(getAllAlerts(userInfo["token"], userInfo["_id"], "video"));
    }
  }, [keyword, startDate, endDate]);

  return (
    <div style={{ position: "relative" }}>
      {selectedUser && (
        <UserDetails
          user={selectedUser}
          onClose={() => setSelectedUser(null)}
          top={yPosition}
          hideNestedData={true}
        />
      )}
      <Layout>
        <Header setOpenDrawer={() => setOpenDrawer(!openDrawer)} />
        <Drawer
          setOpenDrawer={() => setOpenDrawer(!openDrawer)}
          open={openDrawer}
          history={history}
          selected={selected}
          onChange={(value) => setSelected(value)}
        />
        <Body>
          {(selectedParticipants.length || selectedDisapprovers.length > 0) && (
            <Users
              data={
                selectedParticipants.length > 0
                  ? selectedParticipants
                  : selectedDisapprovers
              }
              title={
                selectedParticipants.length > 0 ? t("helpers") : t("naysayers")
              }
              onCloseUsersList={() => {
                setSelectedParticipants([]);
                setSelectedDisapprovers([]);
              }}
              onClick={(user, event) => {
                // window.pageYOffset
                setYPosition(window.pageYOffset);
                setSelectedUser(user);
                setSelectedParticipants([]);
                setSelectedDisapprovers([]);
              }}
            />
          )}
          <Search
            states={[]}
            hint={t("search")}
            onSearch={() => {
              if (keyword !== "") {
                onSearchAlerts();
              } else if (startDate !== "" || endDate !== "") {
                onSearchAlerts();
              }
            }}
            onChange={(value) => {
              setKeyword(value);
            }}
            onChangeEndDate={(value) => {
              if (!isNaN(new Date(value).getDate())) {
                if (new Date(value) <= new Date(startDate)) {
                  setMessage(t("startGt"));
                  setToastType("danger");
                } else setEndDate(new Date(value));
              }
            }}
            onChangeStartDate={(value) => {
              if (!isNaN(new Date(value).getDate())) {
                if (new Date(value) >= new Date(endDate)) {
                  setMessage(t("startLt"));
                  setToastType("danger");
                } else setStartDate(new Date(value));
              }
            }}
            onClearFilters={() => {
              onSearchAlerts("", "", "");
              setStartDate("");
              setEndDate("");
            }}
          />
          {!loading
            ? alerts.length === 0 && <Empty>{t("noAlerts")}</Empty>
            : null}

          <Grid>
            {loading ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "end",
                  alignItems: "center",
                  height: "100vh",
                }}
              >
                <Spinner animation="border" variant="primary" size="xl" />
              </div>
            ) : (
              alerts.map((alert) =>
                alert["sender"] ? (
                  alert["description"] ? (
                    <Alert
                      photo={alert["sender"]["image"]}
                      title={alert["sender"]["name"] + t("help")}
                      subtitle={
                        alert["description"].toLowerCase().includes("health") ||
                        alert["description"]
                          .toLowerCase()
                          .includes("sanitaria") ||
                        alert["description"].toLowerCase().includes("médicale")
                          ? alert["sender"]["name"] + " " + t("healthIssue")
                          : alert["sender"]["name"] + " " + t("securityIssue")
                      }
                      address={alert["county"]}
                      time={format(
                        new Date(alert["createdAt"]),
                        "HH:mm - dd/MM/yyyy"
                      )}
                      participants={alert["participants"].length}
                      disapprovers={alert["disapprovers"].length}
                      onDelete={() => onDeleteAlert(alert["_id"])}
                      onOpenParticipants={() => {
                        setSelectedParticipants(alert["participants"]);
                      }}
                      onOpenDisapprovers={() => {
                        setSelectedDisapprovers(alert["disapprovers"]);
                      }}
                    />
                  ) : (
                    <Video
                      photo={alert["sender"]["image"]}
                      username={
                        alert["sender"]["name"] +
                        " " +
                        alert["sender"]["familyName"]
                      }
                      address={alert["county"]}
                      time={format(
                        new Date(alert["createdAt"]),
                        "HH:mm - dd/MM/yyyy"
                      )}
                      media={alert["link"]}
                      onDelete={() => onDeleteAlert(alert["_id"])}
                    />
                  )
                ) : null
              )
            )}
          </Grid>
          {toastType !== "" && (
            <ToastContainer
              containerPosition="sticky"
              position="top-end"
              style={{
                position: "fixed !important",
                bottom: "40%",
                right: 0,
                zIndex: "2000",
                float: "right",
                textAlign: "left",
              }}
            >
              <Toast
                delay={7000}
                animation
                transition={Collapse}
                bg={toastType}
                style={{
                  borderRadius: "4px",
                  fontFamily: "Din",
                  padding: "0 30px",
                }}
              >
                <Toast.Body className={"text-white"}>{message}</Toast.Body>
              </Toast>
            </ToastContainer>
          )}
        </Body>
      </Layout>
    </div>
  );
};

const Body = styled.div`
  padding: 3vw 28px;
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(333px, 1fr));
  grid-row-gap: 17px;
  grid-column-gap: 24px;
  margin-top: 20px;
`;
const Empty = styled.p`
  color: ${COLORS.gray400};
  font-family: "SF-display";
  font-weight: 400;
  font-size: 0.9rem;
  text-align: center;
  align-self: center;
  margin-top: 30vh;
`;
export default VideosScreen;
