import styled from "styled-components";
import React from "react";
import { QUERIES } from "../contants/allConstants";

const Layout = ({ children, openDrawer }) => {
  return <Wrapper>{children}</Wrapper>;
};
const Wrapper = styled.div`
  min-height: 100vh;
  width: 100%;
  display: grid;
  grid-template-areas:
    "header header"
    "sidebar body";
  grid-template-columns: 1fr 4fr;
  grid-template-rows: 60px calc(max(100vh, 100%) - 60px);

  @media (${QUERIES.tabletAndDown}) {
    grid-template-columns: 1px auto;
    position: relative;
    overflow-x: hidden;
  }
`;

export default Layout;
