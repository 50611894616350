import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Header from "../components/Header";
import Layout from "../components/Layout";
import styled from "styled-components";
import Drawer from "../components/Drawer";
import { useState } from "react";
import Miniheader from "../components/Miniheader";
import TextField from "../components/TextField";
import FileField from "../components/FileField";
import { COLORS } from "../contants/colors";
import GreenButton from "../components/GreenBtn";
import WhiteBtn from "../components/WhiteBtn";
import Dropdown from "../components/Dropdown";
import { QUERIES } from "../contants/allConstants";
import PhoneInput from "react-phone-input-2";
import fr from "react-phone-input-2/lang/fr.json";
import it from "react-phone-input-2/lang/it.json";
import "react-phone-input-2/lib/style.css";
import { createSupervisor } from "../actions/userActions";
import ToastContainer from "react-bootstrap/esm/ToastContainer";
import Collapse from "react-bootstrap/Collapse";
import Toast from "react-bootstrap/Toast";
import countries from "../contants/countries.json";
import subdivisions from "../contants/subdivisions.json";
import { useLanguageContext } from "../languageContext";

const ModeratorScreen = ({ history }) => {
  const dispatch = useDispatch();
  const { userInfo } = useSelector((state) => state.userLogin);
  const { supervisor, error, loading } = useSelector(
    (state) => state.supervisor
  );
  const [selected, setSelected] = useState(7);
  const [code, setCode] = useState("39");
  const [openDrawer, setOpenDrawer] = useState(false);
  const [name, setName] = useState("");
  const [familyName, setFamilyName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [address, setAddress] = useState("");
  const [country, setCountry] = useState("");
  const [role, setRole] = useState("مسير");
  const [states, setStates] = useState([]);
  const [files, setFiles] = useState([]);
  const [message, setMessage] = useState("");
  const [toastType, setToastType] = useState("");
  const [localization, setLocalization] = useState(fr);
  const [lang, setLang] = useState("fr");

  const { t } = useLanguageContext();

  const onCreateSupervisor = () => {
    if (
      name !== "" &&
      familyName !== "" &&
      email !== "" &&
      phone !== "" &&
      // address.name !== "" &&
      phone !== "" &&
      address.name !== "" &&
      role !== "" &&
      country.name !== ""
    ) {
      dispatch(
        createSupervisor(
          userInfo["token"],
          name,
          familyName,
          email,
          "+" + code + phone,
          address.name,
          country.name,
          role === "مسير" ? "isSupervisor" : "isSubAdmin",
          files,
          () => {
            setMessage(t("successSuprv"));
            setToastType("success");
            setName("");
            setFamilyName("");
            setFiles([]);
            setCountry("");
            setPhone("");
            setAddress("");
            setRole("isSupervisor");
            setEmail("");
          }
        )
      );
    } else {
      setMessage(t("fillout"));
      setToastType("danger");
    }
  };

  const onCancel = () => {
    setName("");
    setFamilyName("");
    setFiles([]);
    setCountry("");
    setPhone("");
    setAddress("");
    setRole("isSupervisor");
    setEmail("");
  };
  useEffect(() => {
    if (error) {
      setMessage(error !== "" ? t(error) : t("mainError"));
      setToastType("danger");
    }
  }, [error]);

  useEffect(() => {
    setTimeout(() => {
      setToastType("");
    }, 8000);
  }, [toastType]);

  useEffect(() => {
    const lang = JSON.parse(localStorage.getItem("langAnisso")).lang;
    if (lang === "fr") {
      setLocalization(fr);
      setLang("fr");
    } else if (lang === "en") {
      setLocalization("en");
      setLang("us");
    } else {
      setLocalization(it);
      setLang("it");
    }
  }, []);
  return (
    <Layout>
      <Header setOpenDrawer={() => setOpenDrawer(!openDrawer)} />
      <Drawer
        setOpenDrawer={() => setOpenDrawer(!openDrawer)}
        open={openDrawer}
        history={history}
        selected={selected}
        onChange={(value) => setSelected(value)}
      />
      <Body>
        <Miniheader title={t("addSuprv")} />
        <Wrapper>
          <Row>
            <Col>
              <TextField
                title={t("name")}
                hint={""}
                value={name}
                onChange={(value) => setName(value)}
              />
              <TextField
                title={t("familyName")}
                hint={""}
                value={familyName}
                onChange={(value) => setFamilyName(value)}
              />
              <TextField
                title={t("email")}
                hint={""}
                value={email}
                onChange={(value) => setEmail(value)}
              />
              <TextField
                title={t("phone")}
                hint={""}
                value={phone}
                onChange={(value) => setPhone(value)}
                style={{ position: "relative" }}
                inputStyle={{ paddingLeft: "70px" }}
              >
                <PhoneInput
                  key={lang}
                  excludeCountries={"il"}
                  inputStyle={{
                    textAlign: "left",
                    width: code.length === 1 ? "20px" : code.length * 14 + "px",
                    padding: "0",
                    border: "none",
                    pointerEvents: "none",
                  }}
                  containerStyle={{
                    width: "30px",
                    border: "none",
                    display: "flex",
                    position: "absolute",
                    top: "5px",
                    left: "10px",
                  }}
                  buttonStyle={{
                    background: "transparent",
                    border: "none",
                    position: "relative",
                  }}
                  localization={localization}
                  country={lang}
                  value={code}
                  disableCountryGuess
                  onChange={(value) => {
                    setCode(value);
                  }}
                />
              </TextField>
            </Col>
            <Divider />
            <Col>
              {/* <TextField
                title={t("country")}
                hint={"الرجاء كتابة بلد الاقامة"}
                value={country}
                onChange={(value) => setCountry(value)}
              /> */}
              <Dropdown
                title={t("country")}
                items={countries}
                value={country}
                onChange={(value) => {
                  setCountry(value);
                  var selectedStates = subdivisions.filter((item) => {
                    return item.country.includes(value.alpha2.toUpperCase());
                  });
                  setStates(selectedStates);
                }}
              />
              {/*  <Dropdown
                title={t("state")}
                items={states}
                value={
                  address !== "" ? address : states[0] ? states[0].name : ""
                }
                onChange={(value) => {
                  setAddress(value);
                }}
              />
              <TextField
                title={t("state")}
                hint={"الرجاء كتابة العنوان"}
                value={address}
                onChange={(value) => setAddress(value)}
              /> */}
              {/* <TextField
                title={"اسم الجمعية"}
                hint={"الرجاء كتابة اسم الجمعية"}
                value={teamName}
                onChange={(value) => setTeamName(value)}
              /> */}

              {/* <Dropdown
                title={"نوع المسير"}
                items={["مسير", "مشرف فرعي"]}
                value={role}
                onChange={(value) => setRole(value)}
              /> */}
              <FileField
                type={"image/png, image/jpeg, image/jpg"}
                title={t("suprvImg")}
                setFile={(value) => setFiles([value, files[1]])}
                file={files[0]}
              />
              {/* <FileField
                type={"image/png, image/jpeg, image/jpg"}
                title={"اضافة صورة الجمعية"}
                setFile={(value) => setFiles([files[0], value])}
                file={files[1]}
              /> */}
              <Row>
                <GreenButton
                  title={t("send")}
                  onClick={() => onCreateSupervisor()}
                  loading={loading}
                />
                <WhiteBtn title={t("cancel")} onClick={() => onCancel()} />
              </Row>
            </Col>
          </Row>
        </Wrapper>
        {toastType !== "" && (
          <ToastContainer
            containerPosition="sticky"
            position="top-end"
            style={{
              position: "fixed !important",
              bottom: "40%",
              right: 0,
              zIndex: "2000",
              float: "right",
              textAlign: "left",
            }}
          >
            <Toast
              delay={7000}
              animation
              transition={Collapse}
              bg={toastType}
              style={{
                borderRadius: "4px",
                fontFamily: "Din",
                padding: "0 30px",
              }}
            >
              <Toast.Body className={"text-white"}>{message}</Toast.Body>
            </Toast>
          </ToastContainer>
        )}
      </Body>
    </Layout>
  );
};

const Body = styled.div`
  padding: 3vw 4vw;
  position: relative;
`;
const Wrapper = styled.div`
  background-color: white;
  padding: 20px 40px;
`;
const Row = styled.div`
  display: flex;
  justify-content: space-between;
  @media (${QUERIES.mobileAndDown}) {
    flex-direction: column-reverse;
  }
`;
const Col = styled.div`
  flex: 1;
`;
const Divider = styled.div`
  width: 1px;
  background-color: ${COLORS.gray700};
  margin: 0 20px;
`;

export default ModeratorScreen;
