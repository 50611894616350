import styled from "styled-components";
import React from "react";
import { COLORS } from "../contants/colors";
import { QUERIES } from "../contants/allConstants";
import { useLanguageContext } from "../languageContext";

const UserDetails = ({
  user,
  onClose,
  top = 0,
  isSupervisor = false,
  hideNestedData = false,
}) => {
  const { t } = useLanguageContext();

  const getAge = (dateString) => {
    var today = new Date();
    var birthDate = new Date(dateString);
    var age = today.getFullYear() - birthDate.getFullYear();
    var m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }
    return age;
  };
  return (
    <Container>
      <Shadow top={top}>
        <Wrapper top={top}>
          <Head>
            <Icon
              onClick={() => onClose()}
              src={require("../images/whiteClose.svg").default}
              alt=""
            />
          </Head>
          <Row style={{ overflowY: "auto", height: "93%" }}>
            <Col>
              <User>
                <Avatar
                  src={
                    user["image"] !== ""
                      ? user["image"]
                      : require("../images/avatar.svg").default
                  }
                />
                <div>
                  <Title>{user["name"] + " " + user["familyName"]}</Title>
                  {user["birthday"] && (
                    <Description>
                      {user["gender"] && (
                        <span>{t(user["gender"]) + " | "}</span>
                      )}
                      {getAge(user["birthday"]) + " " + t("yearsOld")}
                    </Description>
                  )}
                </div>
              </User>
              <div>
                {user.nationality && (
                  <Subrow>
                    <SubTitle>{t("nationality")}</SubTitle>
                    <SubDesc>{user.nationality}</SubDesc>
                  </Subrow>
                )}
                {/* {user.cin && user.cin !== "" && (
                  <Subrow>
                    <SubTitle>{" بطاقة التعريف "}</SubTitle>
                    <SubDesc>{user.cin}</SubDesc>
                  </Subrow>
                )} */}
                <Subrow>
                  <SubTitle>{t("country")}</SubTitle>
                  <SubDesc>{user.country}</SubDesc>
                </Subrow>
                {user.state && (
                  <Subrow>
                    <SubTitle>{t("state")}</SubTitle>
                    <SubDesc>{user.state}</SubDesc>
                  </Subrow>
                )}
                {/* {user.city && (
                  <Subrow>
                    <SubTitle>{"المدينة"}</SubTitle>
                    <SubDesc>{user.city}</SubDesc>
                  </Subrow>
                )} */}
                {/* {user.address &&
                  user.address !== "" &&
                  user.address !== "undefined" && (
                    <Subrow>
                      <SubTitle>{"العنوان"}</SubTitle>
                      <SubDesc>{user.address}</SubDesc>
                    </Subrow>
                  )} */}
                {/* {user.postal && user.postal !== "" && (
                  <Subrow>
                    <SubTitle>{"الترقيم البريدي"}</SubTitle>
                    <SubDesc>{user.postal}</SubDesc>
                  </Subrow>
                )} */}
                <Subrow>
                  <SubTitle>{t("phone")}</SubTitle>
                  <SubDesc dir="ltr">{user.phone}</SubDesc>
                </Subrow>
                <Subrow>
                  <SubTitle>{t("email")}</SubTitle>
                  <SubDesc>{user.email}</SubDesc>
                </Subrow>
              </div>
            </Col>
            <Divider />
            <Col>
              <div>
                <GreenTitle>{t("join")}</GreenTitle>
                <p>{user.createdAt.split("T")[0]}</p>
              </div>
              {/*user.job !== "" && (
                <div>
                  <GreenTitle>{"المهنة"}</GreenTitle>
                  <SubTitle>{"تصنيف المهنة"}</SubTitle>
                  <SubDesc>
                    {user.job + ", " + user.private_sector_type}
                  </SubDesc>
                </div>
              )*/}
              {/* {!isSupervisor && user.belonging && !hideNestedData && (
                <div>
                  <GreenTitle>{"الانتماء"}</GreenTitle>
                  {user.belonging === "انتماء إلى جمعيات" ? (
                    <div>
                      {user.defaultBelonging
                        ? user.defaultBelonging.length > 0 && (
                            <Subrow>
                              <div style={{ maxWidth: "30vw" }}>
                                <SubTitle>{"مجتمع مدني"}</SubTitle>
                                <WrapRow>
                                  {user.defaultBelonging.map((item) => (
                                    <Square>{item.name}</Square>
                                  ))}
                                </WrapRow>
                              </div>
                            </Subrow>
                          )
                        : null}
                      {user.politicBelonging
                        ? user.politicBelonging.length > 0 && (
                            <Subrow>
                              <div>
                                <SubTitle>{"احزاب سياسية"}</SubTitle>
                                <WrapRow>
                                  {user.politicBelonging.map((item) => (
                                    <Square>{item.name}</Square>
                                  ))}
                                </WrapRow>
                              </div>
                            </Subrow>
                          )
                        : null}
                      {user.sportBelonging
                        ? user.sportBelonging.length > 0 && (
                            <Subrow>
                              <div>
                                <SubTitle>{"جمعيات رياضية"}</SubTitle>
                                <WrapRow>
                                  {user.sportBelonging.map((item) => (
                                    <Square>{item.name}</Square>
                                  ))}
                                </WrapRow>
                              </div>
                            </Subrow>
                          )
                        : null}
                    </div>
                  ) : (
                    <p>{"مواطن حر"}</p>
                  )}
                </div>
              )} */}
              {isSupervisor && user.teamName ? (
                <div>
                  <GreenTitle>{t("teamName")}</GreenTitle>
                  <p>{user.teamName}</p>
                  {/* <GreenTitle>{"فئة الجمعية"}</GreenTitle>
                  <p>{user.teamType}</p> */}
                </div>
              ) : null}
              {user.emergency_list.length > 0 &&
                !hideNestedData &&
                !isSupervisor && (
                  <div>
                    <GreenTitle>{t("emergency")}</GreenTitle>
                    <div style={{ height: "10px" }} />
                    <Subrow>
                      <div>
                        <WrapRow>
                          {user.emergency_list.map(
                            (item) =>
                              item.phone && (
                                <Square dir="ltr">
                                  {item.phone + " " + item.name}
                                </Square>
                              )
                          )}
                        </WrapRow>
                      </div>
                    </Subrow>
                  </div>
                )}
            </Col>
            {/* <Divider />
           {user.blood !== "" || user.transport === "نعم" ? (
              <div style={{ width: "250px", padding: "20px 30px 50px 0" }}>
                <GreenTitle>{"معلومات اضافية"}</GreenTitle>
                <Subrow>
                  <div>
                    {user.blood !== "" && <SubTitle>{"فصيلة الدم "}</SubTitle>}
                    <SubTitle>{"لديه وسيلة نقل"}</SubTitle>
                    {user.transport === "نعم" && (
                      <SubTitle>{"عدد المقاعد"}</SubTitle>
                    )}
                  </div>
                  <div>
                    {user.blood !== "" && <SubDesc>{user.blood}</SubDesc>}
                    <SubDesc>{user.transport}</SubDesc>
                    {user.transport === "نعم" && (
                      <SubDesc>{user.chairs}</SubDesc>
                    )}
                  </div>
                </Subrow>
              </div>
            ) : null}*/}
          </Row>
        </Wrapper>
      </Shadow>
    </Container>
  );
};
const Container = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 100%;
`;
const Shadow = styled.div`
  background-color: ${COLORS.shadow};
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 3000;
  height: 100vh;
  overflow-y: auto;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: left;
  @media (${QUERIES.tabletAndDown}) {
    // height: max-content;
    height: 100%;
  }
`;
const Wrapper = styled.div`
  background-color: white;
  width: 90%;
  /* height: 90%; */
  border-radius: 10px;
  font-family: "SF-display";
`;
const Divider = styled.div`
  width: 1px;
  background-color: ${COLORS.gray700};
  margin: 20px 0;
  @media (${QUERIES.tabletAndDown}) {
    display: none;
  }
`;
const Square = styled.p`
  background-color: ${COLORS.gray800};
  font-family: "SF-display";
  border-radius: 4px;
  font-weight: 400;
  font-size: 0.85rem;
  color: ${COLORS.black56};
  padding: 8px 10px;
  margin-top: 0;
  white-space: nowrap;
  margin-right: 6px;
`;
const Head = styled.div`
  width: 100%;
  height: 36px;
  background-color: ${COLORS.primary};
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
`;
const Icon = styled.img`
  width: 10px;
  height: 10px;
  object-fit: fill;
  cursor: pointer;
  float: right;
  margin: 12px 16px 0 0;
`;
const Col = styled.div`
  padding: 20px 30px 50px 20px;
  width: calc((90vw - 300px));
  /* width: calc((90vw - 300px) / 2); */

  @media (${QUERIES.tabletAndDown}) {
    width: 100%;
    padding: 10px 30px 0 20px;
  }
`;
const User = styled.div`
  display: flex;
  padding: 0;
  margin-bottom: 10px;
`;
const Row = styled.div`
  display: flex;
  padding: 0;
  @media (${QUERIES.tabletAndDown}) {
    flex-direction: column;
  }
`;
const Subrow = styled(Row)`
  text-align: left;
  align-items: baseline;
  @media (${QUERIES.tabletAndDown}) {
    flex-direction: row;
  }
`;
const WrapRow = styled(Row)`
  text-align: left;
  flex-wrap: wrap;
  @media (${QUERIES.tabletAndDown}) {
    flex-direction: row;
  }
`;
const Avatar = styled.img`
  border-radius: 50%;
  height: 84px;
  min-width: 84px;
  width: 84px;
  object-fit: cover;
  margin-right: 30px;
  @media (${QUERIES.tabletAndDown}) {
    height: 64px;
    min-width: 64px;
    width: 64px;
  }
`;
const Title = styled.p`
  font-weight: 700;
  font-size: 1rem;
  color: ${COLORS.black56};
  margin: 10px 0 0 0;
  width: 100px;
  white-space: nowrap;
`;
const Description = styled.p`
  font-weight: 400;
  color: ${COLORS.black56};
  font-size: 1rem;
  margin: 15px 0 0 0;
  @media (${QUERIES.tabletAndDown}) {
    margin: 4px 0 0 0;
  }
`;
const SubTitle = styled(Title)`
  margin-top: 15px;
  min-width: 130px;
  font-size: 0.875rem;
`;
const SubDesc = styled(Description)`
  font-size: 0.875rem;
  word-wrap: break-word;
  width: max-content;
  overflow: auto;
`;
const GreenTitle = styled(Title)`
  color: ${COLORS.primary};
  margin: 5px 0 0 0;
`;
export default UserDetails;
