import { createStore, applyMiddleware, combineReducers } from "redux";
import thunk from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";
import {
  mobileUsersListReducer,
  supervisorReducer,
  userLoginReducer,
} from "./reducers/userReducers";
import { questionListReducer } from "./reducers/questionReducers";
import { newsListReducer } from "./reducers/newsReducers";
import { alertListReducer } from "./reducers/alertReducers";
import { notificationListReducer } from "./reducers/notificationReducers";
import { teamListReducer } from "./reducers/teamReducers";
import { productListReducer } from "./reducers/productReducers";
import storage from "redux-persist/lib/storage";
import { persistReducer, persistStore } from "redux-persist";

const persistConfig = {
  key: "root",
  storage,
};

const reducer = combineReducers({
  userLogin: userLoginReducer,
  mobileUsers: mobileUsersListReducer,
  supervisor: supervisorReducer,
  question: questionListReducer,
  news: newsListReducer,
  alert: alertListReducer,
  notification: notificationListReducer,
  team: teamListReducer,
  product: productListReducer,
});
const middleware = [thunk];

const userInfoFromStorage = localStorage.getItem("userInfoAnisso")
  ? JSON.parse(localStorage.getItem("userInfoAnisso"))
  : null;
console.log("======store userInfoFromStorage", userInfoFromStorage);

const initialState = {
  userLogin: { userInfo: userInfoFromStorage },
};
const store = createStore(
  reducer,
  initialState,
  composeWithDevTools(applyMiddleware(...middleware))
);

export default store;
