import styled from "styled-components";
import React from "react";
import { COLORS } from "../contants/colors";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { useLanguageContext } from "../languageContext";

const WorkDropdown = ({ title, style, value, onChange }) => {
  const { t } = useLanguageContext();

  return (
    <Wrapper style={{ ...style }}>
      <Title>{title}</Title>
      <Select
        size="small"
        value={value}
        onChange={(e) => onChange(e.target.value)}
        displayEmpty
        inputProps={{ "aria-label": "Without label" }}
      >
        <MenuItem value={""}>{"غير محددة"}</MenuItem>
        <MenuItem value={"معطل عن العمل"}>{"معطل عن العمل"}</MenuItem>
        <MenuItem value={"وظيفة عمومية"}>{"وظيفة عمومية"}</MenuItem>
        <MenuItem value={"مؤسسة عمومية"}>{"مؤسسة عمومية"}</MenuItem>
        <MenuItem value={"قطاع خاص"}>{"قطاع خاص"}</MenuItem>
      </Select>
    </Wrapper>
  );
};
const Wrapper = styled.div`
  color: ${COLORS.black56};
  margin-top: 13px;
  width: 100%;
`;
const Title = styled.p`
  font-family: "SF-display";
  font-size: 0.875rem;
  font-weight: 700;
  text-align: left;
  margin: 0;
  padding-bottom: 10px;
`;

export default WorkDropdown;
