import styled from "styled-components";
import React from "react";
import { COLORS } from "../contants/colors";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";

const Dropdown = ({ title, items, value, onChange, styles = {} }) => {
  return (
    <Wrapper>
      <Title>{title}</Title>
      <Select
        size="small"
        value={value}
        onChange={(e) => onChange(e.target.value)}
        displayEmpty
        inputProps={{ "aria-label": "Without label" }}
        style={{ ...styles }}
      >
        {/* <MenuItem value={""}>{"غير محدد"}</MenuItem> */}
        {items.map((item) => (
          <MenuItem value={item.label ? item.label : item}>
            {item.label ? item.label : item.name ? item.name : item}
          </MenuItem>
        ))}
      </Select>
    </Wrapper>
  );
};
const Wrapper = styled.div`
  color: ${COLORS.black56};
  margin-top: 13px;
  width: 100%;
`;
const Title = styled.p`
  font-family: "SF-display";
  font-size: 0.875rem;
  font-weight: 700;
  text-align: left;
  margin: 0;
  padding-bottom: 10px;
`;

export default Dropdown;
