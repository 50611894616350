import styled from "styled-components";
import React from "react";
import { COLORS } from "../contants/colors";

const Miniheader = ({ large = false, title, arrow = false, goBack }) => {
  return (
    <Wrapper large>
      {arrow && (
        <Icon
          src={require("../images/return.svg").default}
          alt=""
          onClick={() => goBack()}
        />
      )}
      <Title>{title}</Title>
    </Wrapper>
  );
};
const Wrapper = styled.div`
  width: 100%;
  height: ${(p) => (p.large ? "45px" : "36px")};
  background-color: ${COLORS.primary};
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  position: relative;
`;
const Title = styled.p`
  color: white;
  font-family: "SF-display";
  font-size: 0.9rem;
  font-weight: 700;
  text-align: center;
  margin: 0;
  padding-top: 6px;
`;
const Icon = styled.img`
  width: 20px;
  height: 10px;
  object-fit: fill;
  cursor: pointer;
  position: absolute;
  top: 15px;
  right: 10px;
  transform: rotateY(180deg);
`;
export default Miniheader;
