import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Header from "../components/Header";
import Layout from "../components/Layout";
import styled from "styled-components";
import Drawer from "../components/Drawer";
import { useState } from "react";
import Miniheader from "../components/Miniheader";
import TextField from "../components/TextField";
import FileField from "../components/FileField";
import { COLORS } from "../contants/colors";
import GreenButton from "../components/GreenBtn";
import WhiteBtn from "../components/WhiteBtn";
import { QUERIES } from "../contants/allConstants";
import Product from "../components/Product";
import {
  createProduct,
  deletePRODUCT,
  getAllProducts,
  updateProduct,
} from "../actions/productActions";
import Toast from "react-bootstrap/Toast";
import ToastContainer from "react-bootstrap/ToastContainer";
import Collapse from "react-bootstrap/Collapse";
import { useLanguageContext } from "../languageContext";

const ProductsScreen = ({ history }) => {
  const [selected, setSelected] = useState(4);
  const [openDrawer, setOpenDrawer] = useState(false);
  const dispatch = useDispatch();
  const { products, error, loading } = useSelector((state) => state.product);
  const { userInfo } = useSelector((state) => state.userLogin);
  const [productId, setId] = useState("");
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [link, setLink] = useState("");
  const [file, setFile] = useState("");
  const [message, setMessage] = useState("");
  const [toastType, setToastType] = useState("");
  const { t } = useLanguageContext();

  useEffect(() => {
    dispatch(getAllProducts(userInfo["token"]));
  }, []);

  useEffect(() => {
    if (error) {
      setMessage(t("mainError"));
      setToastType("danger");
    }
  }, [error]);

  useEffect(() => {
    setTimeout(() => {
      setToastType("");
    }, 8000);
  }, [toastType]);

  const onDeleteProduct = (id) => {
    dispatch(
      deletePRODUCT(userInfo["token"], id, () => {
        resetFields();
      })
    );
  };

  const onCreateProduct = () => {
    if (title !== "" && description !== "" && link !== "" && file !== "") {
      dispatch(
        createProduct(userInfo["token"], title, description, link, file, () => {
          setMessage(t("successAddP"));
          setToastType("success");
          resetFields();
        })
      );
    } else {
      setMessage(t("fillout"));
      setToastType("danger");
    }
  };

  const onUpdateProduct = () => {
    if (title !== "" && description !== "" && link !== "" && file !== "") {
      dispatch(
        updateProduct(
          userInfo["token"],
          productId,
          title,
          description,
          link,
          file,
          () => {
            setMessage(t("successEditP"));
            setToastType("success");
            resetFields();
          }
        )
      );
    } else {
      setMessage(t("fillout"));
      setToastType("danger");
    }
  };

  const resetFields = () => {
    setTitle("");
    setDescription("");
    setFile("");
    setLink("");
    setId("");
  };
  return (
    <Layout>
      <Header setOpenDrawer={() => setOpenDrawer(!openDrawer)} />
      <Drawer
        setOpenDrawer={() => setOpenDrawer(!openDrawer)}
        open={openDrawer}
        history={history}
        selected={selected}
        onChange={(value) => setSelected(value)}
      />
      <Body>
        <Miniheader title={t("addProduct")} />
        <Wrapper>
          <Row>
            <Col>
              <Grid>
                {products.length === 0 ? (
                  <Empty>{t("emptyProducts")}</Empty>
                ) : (
                  products.map((item) => (
                    <Product
                      id={item["_id"]}
                      photo={item["image"]}
                      title={item["title"]}
                      price={item["description"]}
                      link={item["link"]}
                      onDelete={(id) => onDeleteProduct(id)}
                      onUpdate={() => {
                        setId(item["_id"]);
                        setTitle(item["title"]);
                        setLink(item["link"]);
                        setDescription(item["description"]);
                        setFile(item["image"]);
                      }}
                    />
                  ))
                )}
              </Grid>
            </Col>
            <Divider />
            <Col>
              <TextField
                title={t("product")}
                hint={""}
                value={title}
                onChange={(value) => setTitle(value)}
              />
              <TextField
                title={t("price")}
                hint={""}
                value={description}
                onChange={(value) => setDescription(value)}
              />
              <TextField
                title={t("url")}
                hint={""}
                value={link}
                onChange={(value) => setLink(value)}
              />
              <FileField
                type={"image/png, image/jpeg, image/jpg"}
                title={t("productImg")}
                setFile={(value) => setFile(value)}
                file={file}
              />
              <Row>
                <GreenButton
                  title={t("send")}
                  onClick={() =>
                    productId !== "" ? onUpdateProduct() : onCreateProduct()
                  }
                  loading={loading}
                />
                <WhiteBtn title={t("cancel")} onClick={() => resetFields()} />
              </Row>
            </Col>
          </Row>
        </Wrapper>
        {toastType !== "" && (
          <ToastContainer
            containerPosition="sticky"
            position="top-end"
            style={{
              position: "fixed !important",
              bottom: "40%",
              right: 0,
              zIndex: "2000",
              float: "right",
              textAlign: "left",
            }}
          >
            <Toast
              delay={7000}
              animation
              transition={Collapse}
              bg={toastType}
              style={{
                borderRadius: "4px",
                fontFamily: "Din",
                padding: "0 30px",
              }}
            >
              <Toast.Body className={"text-white"}>{message}</Toast.Body>
            </Toast>
          </ToastContainer>
        )}
      </Body>
    </Layout>
  );
};

const Body = styled.div`
  padding: 3vw 4vw;
  position: relative;
`;
const Wrapper = styled.div`
  background-color: white;
  padding: 20px 40px;
`;
const Row = styled.div`
  display: flex;
  justify-content: space-between;
  @media (${QUERIES.mobileAndDown}) {
    flex-direction: column-reverse;
  }
`;
const Col = styled.div`
  flex: 1;
`;
const Divider = styled.div`
  width: 1px;
  background-color: ${COLORS.gray700};
  margin: 0 20px;
`;
const Empty = styled.p`
  color: ${COLORS.gray400};
  font-family: "SF-display";
  font-weight: 400;
  font-size: 0.8125rem;
  text-align: center;
  align-self: center;
`;
const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(130px, 1fr));
  grid-gap: 12px;
  height: 63vh;
  overflow: auto;
  padding: 30px 20px;
  scroll-behavior: smooth;
  &::-webkit-scrollbar-thumb {
    background-color: ${COLORS.primary};
    border-radius: 6px;
  }
  &::-webkit-scrollbar {
    width: 8px;
    background: ${COLORS.gray800};
    border-radius: 6px;
  }
`;
export default ProductsScreen;
