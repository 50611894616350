import styled from "styled-components";
import React from "react";
import { COLORS } from "../contants/colors";
import { useLanguageContext } from "../languageContext";

const Notification = ({ photo, username, description, time, background }) => {
  const { t } = useLanguageContext();

  return (
    <Wrapper background={background}>
      <Row>
        <Avatar
          src={photo !== "" ? photo : require("../images/avatar.svg").default}
        />
        <div>
          <Title>{username}</Title>
          <Description>{t("answeredQ") + description}</Description>
          <Time>{time}</Time>
        </div>
      </Row>
    </Wrapper>
  );
};
const Wrapper = styled.div`
  background-color: ${(p) => p.background};
  border-radius: 10px;
  font-family: "SF-display";
  text-align: left;
  padding: 12px;
  position: relative;
  border-radius: 6px;
  border: 1px solid ${COLORS.background};
  cursor: pointer;
  color: ${COLORS.black27};
  min-width: 340px;
  width: 33vw;
  margin-bottom: 7px;
`;

const Row = styled.div`
  display: flex;
  padding: 0;
  align-items: center;
`;

const Avatar = styled.img`
  border-radius: 50%;
  height: 65px;
  min-width: 65px;
  object-fit: cover;
  margin-right: 15px;
`;
const Title = styled.p`
  font-weight: 700;
  font-size: 0.85rem;
  margin: 0;
  padding-bottom: 3px;
`;
const Description = styled.p`
  font-weight: 400;
  font-size: 0.75rem;
  margin: 0;
  padding-bottom: 3px;
`;
const Time = styled.p`
  font-weight: 700;
  font-size: 0.75rem;
  margin: 0;
  color: ${COLORS.primary};
`;
export default Notification;
