import {
  NEWS_LIST_REQUEST,
  NEWS_LIST_SUCCESS,
  NEWS_LIST_FAIL,
  DELETE_NEWS_SUCCESS,
  DELETE_NEWS_FAIL,
  NEWS_CREATE_REQUEST,
  NEWS_CREATE_SUCCESS,
  NEWS_CREATE_FAIL,
  UPDATE_NEWS_SUCCESS,
  UPDATE_NEWS_FAIL,
  USER_LOGOUT,
  ADD_COMMENT_SUCCESS,
  DELETE_COMMENT_FAIL,
  DELETE_COMMENT_SUCCESS,
  DELETE_COMMENT_REQUEST,
} from "../contants/allConstants";

export const newsListReducer = (
  state = { news: [], error: false, loading: false },
  action
) => {
  switch (action.type) {
    case NEWS_LIST_REQUEST:
    case DELETE_COMMENT_REQUEST: {
      return {
        ...state,
        loading: true,
        error: false,
      };
    }
    case NEWS_LIST_SUCCESS:
    case UPDATE_NEWS_SUCCESS: {
      return {
        ...state,
        loading: false,
        news: action.payload,
        success: true,
        error: false,
      };
    }
    case ADD_COMMENT_SUCCESS:
    case DELETE_COMMENT_SUCCESS: {
      let newNews = state.news;
      let index = newNews.findIndex((n) => n["_id"] === action.payload.newsId);
      newNews[index]["comments"] = action.payload.data;
      return {
        ...state,
        loading: false,
        news: newNews,
        success: true,
        error: false,
      };
    }
    case NEWS_LIST_FAIL:
      return {
        ...state,
        loading: false,
        success: true,
      };
    case DELETE_NEWS_FAIL:
    case UPDATE_NEWS_FAIL:
    case DELETE_COMMENT_FAIL: {
      return { ...state, loading: false, error: action.payload };
    }
    case DELETE_NEWS_SUCCESS:
      const new_questions = state.news.filter(
        (q) => q["_id"] !== action.payload
      );
      return {
        ...state,
        loading: false,
        success: true,
        news: new_questions,
        error: false,
      };
    case NEWS_CREATE_REQUEST:
      return {
        ...state,
        loading: true,
        error: false,
      };
    case NEWS_CREATE_SUCCESS:
      return {
        ...state,
        loading: false,
        news: action.payload,
        success: true,
        error: false,
      };
    case NEWS_CREATE_FAIL:
      return {
        ...state,
        loading: false,
        success: false,
        error: true,
      };
    case USER_LOGOUT:
      return {
        loading: false,
        error: false,
        success: false,
        news: [],
      };
    default:
      return state;
  }
};
