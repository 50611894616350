import styled from "styled-components";
import React from "react";
import { COLORS } from "../contants/colors";
import ListItemText from "@mui/material/ListItemText";
import Select from "@mui/material/Select";
import Checkbox from "@mui/material/Checkbox";
import MenuItem from "@mui/material/MenuItem";
import OutlinedInput from "@mui/material/OutlinedInput";

const MultiDropdown = ({ title, items, style, selectedTeams, onChange }) => {
  return (
    <Wrapper style={{ ...style }}>
      <Title>{title}</Title>
      <Select
        size="small"
        labelId="custom-multiple-checkbox-label"
        id="custom-multiple-checkbox"
        multiple
        value={selectedTeams}
        onChange={(e) => {
          onChange(
            typeof e.target.value === "string"
              ? e.target.value.split(",")
              : e.target.value
          );
        }}
        input={<OutlinedInput label="Tag" />}
        renderValue={(selected) => selected.join(", ")}
      >
        {items.map((item) => (
          <MenuItem key={item} value={item}>
            <Checkbox checked={selectedTeams.indexOf(item) > -1} />
            <ListItemText primary={item} />
          </MenuItem>
        ))}
      </Select>
    </Wrapper>
  );
};
const Wrapper = styled.div`
  color: ${COLORS.black56};
  margin-top: 13px;
  width: 100%;
`;
const Title = styled.p`
  font-family: "SF-display";
  font-size: 0.875rem;
  font-weight: 700;
  text-align: left;
  margin: 0;
  padding-bottom: 10px;
`;

export default MultiDropdown;
