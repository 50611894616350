import styled from "styled-components";
import React from "react";
import { COLORS } from "../contants/colors";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";

const SmallDropdown = ({ title, items, value, prefix = "", onChange }) => {
  return (
    <Wrapper>
      <Title>{title}</Title>
      <Select
        id="small-dropdown"
        variant="standard"
        value={value}
        onChange={(e) => onChange(e.target.value)}
        displayEmpty
        inputProps={{ "aria-label": "Without label" }}
      >
        {items.map(({ value, name }) => (
          <MenuItem value={value}>{name + prefix}</MenuItem>
        ))}
      </Select>
    </Wrapper>
  );
};
const Wrapper = styled.div`
  color: ${COLORS.black56};
  width: max-content;
  margin-right: 10px;
`;
const Title = styled.p`
  font-family: "SF-display";
  font-size: 0.875rem;
  font-weight: 700;
  text-align: left;
  margin: 0;
  /* padding-bottom: 10px; */
`;

export default SmallDropdown;
