import styled from "styled-components";
import React, { useState } from "react";
import ProgressBar from "react-bootstrap/ProgressBar";
import { COLORS } from "../contants/colors";
import MorePopover from "./MorePopover";
import { format } from "date-fns";
import moment from "moment";
import { useSelector } from "react-redux";
import { useLanguageContext } from "../languageContext";

const SmallQuestion = ({ index, question, onDelete, onClick }) => {
  const { userInfo } = useSelector((state) => state.userLogin);
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    setOpen(true);
  };
  const { t } = useLanguageContext();

  const firstAnswerPercent = question["answers"][0]
    ? question["answers"][0]["users"].length
    : 0; //v.1.1
  return (
    <Question>
      <Wrapper>
        <style type="text/css">
          {`
        .bg-custom {
          background-color: ${COLORS.success};
          border-radius: 6px;
        }
        .progress{
          width: 100%;
          height: 12px;
          border-radius:6px;
          margin-top:20px;
          background-color: ${COLORS.gray200};
        }
        `}
        </style>
        <Labels>
          <Title>
            {question["title"]
              ? question["title"]
              : `${t("question")} ${index}`}
          </Title>
          {question["subject"] && <Subtitle>{question["subject"]}</Subtitle>}
          <Subtitle>{question["description"]}</Subtitle>
          <ProgressBar
            variant="custom"
            now={firstAnswerPercent}
            label={""}
            visuallyHidden
          />
          <Row>
            <Result>
              {firstAnswerPercent +
                "  " +
                question["answers"][0]["description"]}
            </Result>
            <Result>
              {question["answers"][1]["users"].length +
                " " +
                question["answers"][1]["description"]}
            </Result>
          </Row>
        </Labels>
        <MorePopover
          showDetails={true}
          canDelete={userInfo.isAdmin}
          onOpenDetails={() => onClick(question)}
          handleClick={handleClick}
          open={open}
          setOpen={setOpen}
          anchorEl={anchorEl}
          top={8}
          onDelete={() => onDelete(question["_id"])}
        />
      </Wrapper>
      <Footer>
        {question["company"] && (
          <div>
            <Strong>{t("company")}</Strong>
            <p>{question["company"]}</p>
          </div>
        )}
        <div>
          <Strong>{t("publishedDate")}</Strong>
          <p>{format(new Date(question["createdAt"]), "dd/MM/yyyy - HH:mm")}</p>
        </div>
        <div>
          <Strong>{t("ending")}</Strong>
          <p>
            {new Date(question["expireAt"]) < new Date() ? t("ended") : ""}
            {moment
              .utc(question["expireAt"])
              .local()
              .endOf("seconds")
              .fromNow()}
          </p>
        </div>
      </Footer>
    </Question>
  );
};
const Question = styled.div`
  background-color: white;
  border-radius: 15px;
`;
const Footer = styled.div`
  color: ${COLORS.gray30};
  font-family: "SF-display";
  font-size: 0.71rem;
  font-weight: 400;
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 0 22px 13px 22px;
`;
const Strong = styled.span`
  color: ${COLORS.gray550};
  font-size: 0.71rem;
  font-weight: 400;
  margin-right: 2px;
`;
const Wrapper = styled.div`
  background-color: white;
  border-radius: 15px;
  padding: 32px 22px 16px 22px;
  display: flex;
  position: relative;
`;

const Row = styled.div`
  width: 90%;
  display: flex;
  font-family: "SF-display";
  font-size: 1rem;
  font-weight: 500;
  color: black;
  align-items: center;
  justify-content: space-between;
`;

const Labels = styled.div`
  width: 95%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  color: black;
  font-family: "SF-display";
  font-size: 1rem;
  align-items: flex-start;
  justify-content: center;
`;

const Title = styled.span`
  font-weight: 700;
  text-align: left;
`;
const Subtitle = styled.div`
  font-weight: 400;
  padding-top: 8px;
  text-align: left;
  font-size: 0.88rem;
`;
const Result = styled.div`
  font-weight: 500;
  padding-top: 13px;
  text-align: left;
  font-size: 0.875rem;
`;
export default SmallQuestion;
